@import '../../styles/main.scss';

.zn-bio-professional-involvement {
    &__body {
        @include Body-Medium();
        color: $darker-grey;

        p {
            padding-bottom: $spacing-3;
        }
    }

    @media print {
        margin-top: 0.24in;
    }
}
