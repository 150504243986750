@import '../../styles/main.scss';

.zn-heading-accent {
    $width: toRem(80px);
    width: $width;
    height: toRem(4px);
    margin-top: $spacing-4;

    &--medium {
        margin-top: $spacing-3;

        @media #{$bp-s} {
            margin-top: $spacing-4;
        }
    }

    &--grey {
        background-color: $light-grey;
    }

    &--blue {
        background-color: $torys-blue;
    }

    &--teal {
        background-color: $teal;
    }

    &--orange {
        background-color: $orange;
    }

    &--navy {
        background-color: $dark-blue;
    }
    &--white {
        background-color: $white;
    }

    &__animation {
        width: 0;
        animation-name: heading-accent-slide;
        animation-play-state: paused;
        animation-fill-mode: forwards;
        animation-duration: 500ms;
        animation-delay: 350ms;

        @keyframes heading-accent-slide {
            from {
                width: 0;
            }
            to {
                width: $width;
            }
        }
    }

    @media print {
        margin-top: 0.12in;
        height: 0.03125in;
        width: 0.8in;
    }
}
