@import '../../styles/main.scss';

.zn-project-map-search-bar-filter {

    &__input {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border: none;
        border-bottom: 1px solid $darker-grey;
        width: 100%;
        padding: $spacing-2 toRem(10px);

        &:focus-within {
            border-color: $torys-blue;

            .zn-representative-work__search-button {
                color: $torys-blue;
            }
        }

        @media #{$bp-m} {
            &--disabled {
                border-color: $medium-grey;
            }

        }
    }

    &__form {
        width: 100%;
    }

    &__form-input {
        @include Body-Medium();
        width: 100%;
        background-color: transparent;
        border: none;
        padding: 0 0 0 $spacing-5;
        color: $darker-grey;
        width: 100%;
        font-size: toRem(22px);
        border-radius: 0;
        -webkit-appearance: none;
        -webkit-border-radius: 0;

        &:focus-visible {
            outline: none;
        }

        @media #{$bp-m} {
            font-size: toRem(18px);

            &--disabled {
                color: $medium-grey;
            }

        }
    }

    &__search-button { 
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        padding: 0 0 0 toRem(10px);
        
        svg {
            width: 19px;
            height: 19px;
            color: $torys-blue;
        }

        &--disabled {
            svg {
                color: $medium-grey;
            }
        }
    }


    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-right: $spacing-3;
    margin-bottom: $spacing-3;
    position: relative;
    width: 100%;

    &--active {
        height: 100%;
        margin-right: 0;
        position: absolute;
        background-color: $white;
        z-index: 3;
    }

    @media #{$bp-m} {
        width: auto;
        margin-bottom: 0;
        margin-top: $spacing-3;

        &--active {
            height: auto;
            margin-right: $spacing-3;
            position: relative;
            width: auto;
            background-color: transparent;
        }
    }

    &__button {
        border: none;
        background-color: transparent;
        display: flex;
        flex-direction: row;
        align-items: center; 
        padding-left: $spacing-3;

        @media #{$bp-m} {
            border: 1px solid $darker-grey;
            background-color: transparent;
            border-radius: 100px;
            padding: $spacing-2 $spacing-3;
            color: $darker-grey;
    
            &--active {
                background-color: $torys-blue-transparent;
                color: $black;
                border-color: $torys-blue;
                border-width: 2px;
                margin: -1px;
            }

            &--disabled {
                border-color: $medium-grey;
                color: $medium-grey;
            }
        }
    }

    &__button:hover {
        @media #{$bp-m} {
            background-color: $torys-blue-transparent;
            color: $black;
            border-color: $torys-blue;
            border-width: 2px;
            margin: -1px;
            cursor: pointer;
        }
    }

    &__button--disabled:hover {
        @media #{$bp-m} {
            border-color: $medium-grey;
            color: $medium-grey;
            cursor:default;
            margin: 0;
            border: 1px solid $medium-grey;
            background-color: transparent;
        }
    }

    &__button--primary:hover {
        @media #{$bp-m} {
            cursor:default;
            margin: 0;
            border: 1px solid $darker-grey;
            background-color: transparent;
            color: $darker-grey; 
        }  
    }
    
    &__title {
        @include Display-6();
        padding-right: toRem(12px);

        @media #{$bp-m} {
            @include Body-Medium();
            font-weight: 400;
            padding-right: $spacing-2;
            
            &--primary {
                padding-right: 0;
            }

        }
        &--desktop {
            @include desktop-medium-only();
        }

        &--mobile {
            @include mobile-tablet-only();
            color: $black;

            &--disabled {
                color: $light-grey;
            }

        }
    }

    &__desktop-chevron {
        @include desktop-medium-only();
        line-height: 0;
        svg {
            pointer-events: none;
        }
        path {
            pointer-events: none;
        }
    }

    &__mobile-chevron {
        @include mobile-tablet-only();
        display: block;
        margin-left: 0;
        margin-right: toRem(16px);
        height: toRem(16px);
        width: toRem(10px);
        line-height: 0;
        color: $torys-blue;

        svg {
            max-height: 100%;
            max-width: 100%;
        }

        &--disabled {
            color: $light-grey;
        }
    }

    &__mobile-selected-filters {
        @include mobile-tablet-only();
        @include All-Caps-Small();
        color: $darker-grey-2;
        margin-left: $spacing-3;
        font-weight: 400;

        &--disabled {
            color: $light-grey;
        }
    }

    &__dropdown {
        display: none;

        &--active {
            display: flex;
            position: absolute;
            display: flex;
            flex-direction: column;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: $white;

        }

        @media #{$bp-m} {
            position: absolute;
            display: none;
            flex-direction: column;
            align-items: flex-start;
            top: 60px;
            max-height: 625px;
            width: 352px;
            background: $white;
            box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1), 0px 1px 4px rgba(0, 0, 0, 0.1);
            border-radius: 2px;
    
            &--active {
                display: flex;
                height: auto;
                top: 60px;
            }
        }
    }

    &__desktop-dropdown-title {
        @include desktop-medium-only();
        @media #{$bp-m} {
            @include Display-6();
            display: block;
            line-height: 1.875;
            padding: $spacing-4 toRem(40px) 0;
            margin-bottom: $spacing-2;

            &--disabled {
                color: $medium-grey;
            }
        }
    }

    &__mobile-dropdown-back-button {
        @include Display-6();
        @include mobile-tablet-only();
        color: $torys-blue;
        align-items: center;
        display: flex;
        flex-direction: row;
        padding: 0 0 toRem(14px);
        margin: 0 $spacing-3;
        border-bottom: 1px solid $light-grey;
    }

    &__mobile-dropdown-title {
        color: $darker-grey;
    }

    &__dropdown-list {
        overflow-y: auto;
        padding: toRem(26px) 0 $spacing-1;
        list-style-type: none;
        max-height: 100%;
        margin-bottom: toRem(92px);

        @media #{$bp-m} {
            padding: $spacing-3;
            width: 100%;
            max-height: 625px;
            margin: 0;
        }
    }

    &__desktop-dropdown-list-item {
        @include Body-Medium();
        max-width: 236px;
        margin-bottom: $spacing-2;
    }

    &__desktop-dropdown-list-item:last-child {
        margin-bottom: 0;
    }

    &__dropdown-buttons {
        @include Body-Medium();
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: $spacing-3 toRem(20px) toRem(20px) toRem(34px);
        width: 100%;
        border-top: 1px solid $light-grey-45;
        margin-top: $spacing-3;
        position: absolute;
        bottom: 0;
        background-color: $white;

        @media #{$bp-m} {
            @include Body-Medium();
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            padding: $spacing-3 toRem(20px) toRem(20px) toRem(34px);
            width: 100%;
            border-top: 1px solid $light-grey-45;
            margin-top: $spacing-3;
            position: relative;
        } 
    }

    &__clear-button {
        @include clear-filter();

        @media #{$bp-m} {
            &--disabled {
                cursor: default;
                color: $medium-grey;
            }

            &--disabled:hover {
                cursor: default;
                color: $medium-grey;
            }
        } 
    }

    &__apply-button {
        @include mobile-tablet-only();
    }

    &__no-filters {
        margin: 0 $spacing-3;

        @media #{$bp-m} {
            margin: 0;
        } 
    }

    &__no-filters-title {
        @include Body-Medium();
        padding-bottom: $spacing-1;
        color: $black;

        &--disabled {
            color: $medium-grey;
        }
    }

    &__no-filters-description {
        @include Body-Regular();
        color: $darker-grey;

        &--disabled {
            color: $medium-grey;
        }
    }

    &__clear-filter-container {
        padding: $spacing-4 toRem(40px);
        border-top: 1px solid $light-grey;
        width: 100%;

    }

    &__clear-filter-button {
        @include clear-filter();

        @media #{$bp-m} {
            &--disabled {
                cursor: default;
                color: $medium-grey;
            }

            &--disabled:hover {
                cursor: default;
                color: $medium-grey;
            }
        } 
    }
}

.zn-project-map-search-bar-filter-mobile {
    margin-bottom: $spacing-3;

    &--disabled {

    }

    &__primary-menu {
        display: none;

        &--active {
            display: flex;
            flex-direction: column;
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: $white;
        }

    }

    &__back-btn-header {
        display: flex;
        flex-direction: column;
        padding: 0 $spacing-3;
        width: 100%;
    }

    &__back-btn {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        background-color: transparent;
        outline: none;
        border: none;
        padding: 0;
        margin-bottom: toRem(12px);
        
        &:focus {
            .zn-project-map-search-bar-filter-mobile__back-btn-title {
                color: $torys-blue;
            }
        }
    }

    &__back-btn-chevron {
        margin-right: $spacing-3;
        height: toRem(16px);
        width: toRem(10px);
        line-height: 0;
        color: $torys-blue;

        svg {
            max-height: 100%;
            max-width: 100%;
        }

        &--disabled {
            color: $light-grey;
        }
    }

    &__back-btn-title {
        @include Display-6();
        color: $dark-grey;

        &--disabled {
            color: $light-grey;
        }
    }

    &__divider {
        width: 100%;
        height: 1px;
        background-color: $light-grey;
    }

    &__hl-list {
        width: 100%;
        height: 100%;
        padding: $spacing-5 0 0;
        margin: 0;
        position: relative;

        &--ad-open {
            padding-top: 0;
        }
    }

    &__hl-item {
        margin-bottom: $spacing-3;
    }

    &__secondary-menu {
        display: none;
        
        &--active {
            display: flex;
            flex-direction: column;
            position: absolute;
            top: 0;
            height: 100%;
            width: 100%;
            background-color: $white;
        }
    }

    &__checkbox-list {
        overflow: auto;
        height: 100%;
        margin: 0;
        padding: 0;
    }

    &__sd-header-container {
        padding: $spacing-3;

    }

    &__select-all {
        @include Display-6();
        color: $dark-grey;
        padding: 0 0 toRem(12px);
        margin: 0;
        border: none;
        background-color: transparent;
        outline: none;

        &--disabled {
            color: $light-grey;
        }
    }

    &__mb-footer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: $spacing-3;
        border-top: 1px solid $light-grey;

    }

    &__mb-clear-button {
        @include clear-filter();

        &--disabled {
            color: $light-grey;
        }

    }

    &__mb-apply-button {
        .zn-button-simple {
            padding: $spacing-2 $spacing-3;
        }

        &--disabled {
            .zn-button-simple {
                background-color: $light-grey;
                border-color: $light-grey;
            } 
        }
    }

    .zn-checkbox--disabled {
        color: $light-grey;

        
            &.checked {
                color: $light-grey !important;

            }

            .zn-checkbox__mobile-icon {
                svg {
                    color: $light-grey !important;
                }
            }
        
    }
}

