@import '../../styles/main.scss';

.zn-general-hero {
    &--blue {
        background-color: $torys-blue;
        color: $white;

        .zn-general-hero__description {
            color: $bg-blue;
        }
    }

    &--navy {
        color: $white;
        background: $dark-blue;

        .zn-general-hero__description {
            color: $bg-blue;
        }
    }

    &--white {
        background-color: $white;
    }

    &__container {
        display: flex;
        flex-direction: column;

        @media #{$bp-m} {
            flex-direction: row;
        }

        @media print {
            flex-direction: row;
        }
    }

    &__text {
        order: 2;
        padding: $spacing-5 $spacing-3;
        width: 100%;
        margin-top: auto;
        margin-bottom: auto;
        display: flex;
        @media #{$bp-s} {
            order: 1;
            justify-content: center;
            padding: toRem(80px) responsive-spacing($screen-m, $screen-xxl, 74px, 165px);
            &__container {
                width: toRem($section-content-max-width);
            }
        }
        @media print {
            order: 1;
            padding: 0.62in 0.6in;
            max-width: 50%;
        }

        &--half {
            @media #{$bp-m} {
                width: 50%;
                padding: toRem(112px) toRem(118px) toRem(112px) toRem(165px);
            }
        }
    }

    &__title {
        @include Display-1();

        @media #{$bp-m} {
            margin-bottom: $spacing-2;
        }
    }

    &__eyebrow {
        @include All-Caps();
        line-height: 1.83;
    }

    &__description {
        @include Body-Deck();
        margin-top: $spacing-4;
        font-family: $heading-secondary-font-family;

        @media #{$bp-m} {
            width: #{(8 * 100 / 12) + '%'};
            max-width: #{(8 * 100 / 12) + '%'};
        }

        &--half {
            width: 100%;
            max-width: 100%;
        }

        @media print {
            font-family: $body-font-family;
            font-size: 0.16in;
            font-weight: lighter;
        }
    }

    &__image {
        width: 100%;
        order: 1;

        @media #{$bp-m} {
            order: 2;
            width: 50%;
        }

        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
        @media print {
            display: none;
        }
    }

    &__pdfImage {
        display: none;
        @media print {
            display: block;
            max-height: 3.9in;

            img {
                aspect-ratio: 3 / 4;
            }
        }
    }

    &__lottie-container {
        width: 100%;
        order: 1;

        @media #{$bp-m} {
            order: 2;
            width: 50%;
        }

        @media print {
            display: none;
        }
    }

    &__button {
        margin-top: $spacing-4;

        @media #{$bp-m} {
            margin-top: $spacing-5;
        }
    }

    .zn-button--tertiary {
        padding: 0;
    }

    @include pdf-hero();
}
