@import "../../styles/main.scss";

$column-total: 12;

@mixin search-bar-chevron() {
    @include mobile-tablet-only();
    display: block;
    margin-left: 0;
    margin-right: toRem(16px);
    height: toRem(16px);
    width: toRem(10px);
    line-height: 0;

    svg {
        max-height: 100%;
        max-width: 100%;
    }
}

.zn-representative-work {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 500px;

    @media #{$bp-m} {
        flex-direction: row;
        padding-top: $spacing-4;
    }

    &__left-content {
        max-width: 100%;
        display: flex;
        flex-direction: column;

        @media #{$bp-m} {
            width: #{(3.5 * 100 / $column-total) + '%'};
            max-width: #{(3.5 * 100 / $column-total) + '%'};
            border-right: 1px solid $light-grey;
            padding-right: $spacing-3;

        }

        @media print{
            display: none;
        }
    }

    &__right-content {
        max-width: 100%;

        @media #{$bp-m} {
            width: #{(8.5 * 100 / $column-total) + '%'};
            max-width: #{(8.5 * 100 / $column-total) + '%'};
            padding-left: toRem(62px);
        }
    }

    &__input { 
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border: none;
        border: 1px solid $darker-grey;
        width: 100%;
        margin-bottom: $spacing-4;
        padding: $spacing-2 toRem(10px);
        order: 1;

        &:focus-within {
            border-color: $torys-blue;

            .zn-representative-work__search-button {
                color: $torys-blue;
            }
        }

        @media #{$bp-m} {
            order: 2;
            max-width: toRem(236px);
            margin-bottom: 0;
        }

    }

    &__form {
        width: 100%;
    }

    &__form-input {
        @include Body-Medium();
        width: 100%;
        background-color: transparent;
        border: none;
        padding: 0 0 0 $spacing-5;
        color: $darker-grey;
        width: 100%;
        font-size: toRem(22px);
        border-radius: 0;
        -webkit-appearance: none;
        -webkit-border-radius: 0;

        &:focus-visible {
            outline: none;
        }

        @media #{$bp-m} {
            font-size: toRem(18px);

        }
    }

    &__search-button { 
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        padding: 0 0 0 toRem(10px);
        color: $darker-grey;
        
        svg {
            width: 19px;
            height: 19px;
        }
    }

    &__mb-filter-opener {
        @include mobile-tablet-only();
        background-color: transparent;
        border: 1px solid $darker-grey;
        background-color: transparent;
        border-radius: 100px;
        padding: $spacing-2 $spacing-3;
        color: $darker-grey;
        margin: 0 $spacing-3 $spacing-3 0;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    &__mb-filter-opener-title {
        @include Body-Medium();
        margin-right: $spacing-2;
    }

    &__mb-filter-opener-chevron {
        line-height: 0;
    }

    &__filter-list-mb-header {
        padding: 0 $spacing-3;
    }

    &__filter-list-mb-header-content { 
        @include mobile-tablet-only();
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: toRem(12px) 0 toRem(28px);
    }

    &__filter-list-mb-header-title { 
        @include Display-3();
        color: $darker-grey;
        
    }

    &__filter-list-mb-header-divider { 
        @include mobile-tablet-only();
        display: block;
        width: 100%;
        height: 1px;
        background-color: $light-grey-45;

        &--menu-open {
            background-color: $white;
          }
    }

    &__filters-wrapper {
        display: none;
        position: absolute;
        
        &--active {
            display: flex;
            flex-direction: column;
            position: fixed;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            z-index: 8;
            background-color: $white;
        }

        @media #{$bp-m} {
            display: block;
            position: relative;

            &--active {
                position: relative;
                height: auto;
                width: auto;
                background-color: transparent;
                z-index: 1;
            }
        }
    }

    
    &__list-container {
        display: flex;
        height: 100%;
    }

    &__mb-wrapper {
        position: relative;
        height: 100%;
        width: 100%;
        padding-top: $spacing-4;
        
        &--filter-open {
            padding-top: 0;
        }

        @media #{$bp-m} {
            padding-top: 0;
        }

    }

    &__filters-container {
        order: 2;

        @media #{$bp-m} {
            order: 1;
            margin-bottom: $spacing-3;
        }
    }

    &__sel-filters {
        display: none;

        @media #{$bp-m} {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            margin-bottom: $spacing-3;
        }
    }

    &__sel-filter {
        @include desktop-medium-only();
        margin: 0 $spacing-3 $spacing-3 0;
    }

    &__sel-filter-button {
        
        @media #{$bp-m} {
            @include Body-Medium();
            border: 1px solid $darker-grey;
            background-color: transparent;
            border-radius: 100px;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: $spacing-2 $spacing-3;
            color: $darker-grey;   
        }

        &:hover {
            background-color: $torys-blue-transparent;
            color: $black;
            border-color: $torys-blue;
            border-width: 2px;
            margin: -1px;
            cursor: pointer;
        }

        &--primary {
            &:hover {
                cursor:default;
                margin: 0;
                border: 1px solid $darker-grey;
                background-color: transparent;
                color: $darker-grey;   
            }
        }
    }

    &__sel-filter-title {
        padding-right: toRem(12px);

        &--primary {
            padding-right: 0;
        }
    }

    &__sel-filter-icon {
        line-height: 0;
    }

    &__desktop-clear-all-button {
        @include desktop-medium-only();
        @include clear-filter();
        margin-bottom: $spacing-3;
        border: none;
        background: transparent;

        &:hover {
            cursor: pointer;
        }

        &--hide {
            display: none;
        }
    }

    &__mb-menu-buttons {
        @include mobile-tablet-only();
        @include Body-Medium();
        display: none;
        flex-direction: row;
        padding: $spacing-3 toRem(20px) toRem(20px) toRem(34px);
        width: 100%;
        justify-content: space-between;
        border-top: 1px solid $light-grey-45;

        &--mb-open {
            display: flex;
        }

        &--filter-open {
            display: none;
        }

        @media #{$bp-m} {
           display: none;

            &--mobile-open, &--filter-open {
                display: none;
            }
        } 
    }

    &__mb-menu-clear-button {
        @include clear-filter();
    }

    &__search-results {
        display: flex;
        flex-direction: column;
    }

    &__search-results-title {
        @include Display-4-Secondary();
        color: $black;
        margin-bottom: $spacing-3;

        @media #{$bp-m} {
            margin-bottom: $spacing-4;
        } 
    }

    &__search-results-title::first-letter {
        text-transform: capitalize;
    }

    &__search-results-list {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }

    &__pagination {
        display: flex;
        justify-content: center;

        @media #{$bp-m} {
            margin-bottom: toRem(32px);
         } 
    }

    &__month-desktop-divider {
        @include desktop-medium-only();
        max-width: 236px;
        width: 100%;
        height: 1px;
        background-color: $light-grey;
        margin: $spacing-3 0;
    }

    &__month-bottom-buttons {
        @include Body-Medium();
        @include mobile-tablet-only();
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: $spacing-3 toRem(20px) toRem(20px) toRem(34px);
        width: 100%;
        border-top: 1px solid $light-grey-45;
        margin-top: $spacing-3;
        position: absolute;
        bottom: 0;
    }

    &__loading {
        @include Display-5();
        width: 100%;
        text-align: center;
        text-transform: uppercase;
        color: $torys-blue;
    }

    &__error {
        @include Display-5();
        width: 100%;
        text-align: center;
        text-transform: uppercase;
        color: $orange;
    }

    .zn-month-picker {
        width: 100%;
        display: block;
            
        &--active {
            display: block;
            background-color: transparent;
        }

        &--mobile-open {
            display: block;
        }

        @media #{$bp-m} {
            max-width: 246px;
            display: block;
            margin: 0;
        } 
        
        &__button {
            border: none;
            background-color: transparent;
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: start;
            align-items: center;
        
            &:hover, &--active {
                border: none;
                margin: 0;
                color: $darker-grey;
            }

            @media #{$bp-m} {
                padding: toRem(10px) 0;
            } 
        }

        &__title {
            @media #{$bp-m} {
                @include Display-6();
            } 
        }

        &__title-chevron {
            position: absolute;
            right: 0;
        }

        &__calendar {

            @media #{$bp-m} {
                position: relative;
                display: block;
                overflow: hidden;
                max-height: 0px;
                transition: max-height 0.6s ease;
                top: auto;
                left: auto;
                margin: 0;
                padding: 0;
                box-shadow: none;
                width: 100%;
                background-color: transparent;
        
                &--active {
                    max-height: 625px;
                    background-color: transparent;
                }
            } 
        }
    }

    .zn-checkbox {
        &__label {
            @include Body-Medium();
            padding: $spacing-2 toRem(21px) $spacing-2 $spacing-3;
    
            @media #{$bp-m} {
                padding: 0 $spacing-3 0 toRem(12px);
                font-size: 1rem;
                background-color: inherit;

            }
        }
    }

    .zn-featured-list__item__description {
        @include Body-Medium();
    }
}