@import '../../styles/main.scss';

.zn-pagination {
    &__page {
        &--active {
            text-decoration: underline;
        }

        &:disabled {
            color: $light-grey;
            &:hover {
                color: $light-grey;
            }
        }

        &:hover {
            color: $torys-blue;
        }
    }

    @media print {
        display: none;
    }
}

.zn-pagination__button {
    display: inline-flex;
    font-weight: 400;
    vertical-align: middle;
    padding: toRem(5px) toRem(10px);

    @media #{$bp-m} {
        padding: toRem(5px) $spacing-3;
    }

    &:first-child {
        padding-left: 0;
    }

    &:last-child {
        padding-right: 0;
    }

    &:enabled {
        &:hover {
            path {
                fill: $torys-blue;
            }
            circle {
                stroke: $torys-blue;
            }
        }
    }
}
