@import '../../styles/main.scss';

.zn-content-card-row {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    grid-row-gap: $spacing-3;
    grid-column-gap: $spacing-4;
    margin-top: $spacing-5;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;

    &--hide-mobile {
        display: none;
    }

    @media #{$bp-m} { 
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        grid-row-gap: $spacing-6;
    }

    &__animate {
        .zn-content-card {
            @include fadeIn();
        }

        .zn-content-card.fadeIn {
            transition: opacity 0.75s ease, transform 0.75s ease, top ease 0.3s; 
        }
    }
}