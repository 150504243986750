@mixin fadeIn {
    &.fadeIn {
        opacity: 0;
        transition: opacity 0.75s ease, transform 0.75s ease;
        transition-delay: 0.1s;
        transform: translate3d(0, 24px, 0);
    }

    &.fadeInActive {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }

    @content;
}

// Will use same selectors as fadeIn when calling the observer
@mixin fadeInTop {
    &.fadeIn {
        opacity: 0;
        transition: opacity 0.75s ease, transform 0.75s ease;
        transition-delay: 0.1s;
        transform: translate3d(0, -24px, 0);
    }

    &.fadeInActive {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }

    @content;
}

@mixin imageHoverZoom($img-class) {
    overflow: hidden;

    #{$img-class} {
        transition: transform 0.52s ease;

        &:hover {
            transform: scale(1.05);
        }
    }
}

@mixin expandDown {
    &.expandDown {
        transform: scaleY(0);
        transform-origin: 50% 0%;
        transition: transform 0.65s ease;
        transition-delay: 0.2s;
    }

    &.expandDownActive {
        transform: scaleY(1);
    }

    @content;
}

@mixin expandRight {
    &.expandRight {
        transform: scaleX(0);
        transform-origin: 0% 50%;
        transition: transform 0.65s ease;
        transition-delay: 0.2s;
    }

    &.expandRightActive {
        transform: scaleX(1);
    }

    @content;
}

@mixin headingFadeIn($text-class) {
    &.headingFadeIn {
        overflow: hidden;
        #{$text-class} {
            $anim_offset: 100%;
            position: relative;
            transform: translate(0%, $anim_offset);
            transition: transform 0.85s ease;
            transition-delay: 0.1s;
        }
    }

    &.headingFadeInActive {
        #{$text-class} {
            transform: translate(0%, 0%);
        }
    }

    @content;
}
