@import url('https://use.typekit.net/plv0fod.css');

// Headings
@mixin heading-base {
    font-family: $heading-font-family;
    font-style: normal;
    font-weight: normal;
}

@mixin heading-secondary {
    font-family: $heading-secondary-font-family;
    font-style: normal;
    font-weight: normal;
}

// Body and some headings
@mixin body-base {
    font-family: $body-font-family;
}

@mixin Display-1() {
    @include heading-base;
    font-size: toRem(40px);
    line-height: 1.26;

    @media #{$bp-s} {
        font-size: toRem(56px);
        line-height: 1.17;
    }
}

@mixin Display-2() {
    @include heading-base;
    font-size: toRem(32px);
    line-height: 1.31;

    @media #{$bp-s} {
        font-size: toRem(46px);
        line-height: 1.28;
    }
}

@mixin Display-3() {
    @include heading-base;
    font-size: toRem(24px);
    line-height: 1.5;

    @media #{$bp-s} {
        font-size: toRem(32px);
        line-height: 1.37;
    }
}

@mixin Display-4() {
    @include heading-base;
    font-size: toRem(22px);
    line-height: 1.36;

    @media #{$bp-s} {
        font-size: toRem(24px);
        line-height: 1.42;
    }
}

@mixin Display-5() {
    @include body-base;
    font-weight: 500;
    font-size: toRem(20px);
    line-height: 1.6;

    @media #{$bp-s} {
        font-size: toRem(22px);
        line-height: 1.54;
    }
}

@mixin Display-6() {
    @include body-base;
    font-weight: 500;
    font-size: toRem(16px);
    line-height: 1.75;

    @media #{$bp-s} {
        font-size: toRem(18px);
        line-height: 1.54;
    }
}


@mixin Display-7() {
    @include body-base;
    font-weight: 500;
    font-size: toRem(14px);
    line-height: 1.57;

    @media #{$bp-s} {
        font-size: toRem(16px);
        line-height: 1.625;
    }
}

//Body Copy

@mixin Body-Large() {
    @include body-base;
    font-size: toRem(20px);
    line-height: 1.4;

    @media #{$bp-s} {
        font-size: toRem(22px);
        line-height: 1.54;
    }
}

@mixin Body-Medium() {
    @include body-base;
    font-size: toRem(16px);
    line-height: 1.5;

    @media #{$bp-s} {
        font-size: toRem(18px);
        line-height: 1.77;
    }
}

@mixin Body-Regular() {
    @include body-base;
    font-size: toRem(14px);
    line-height: 1.57;

    @media #{$bp-s} {
        font-size: toRem(16px);
        line-height: 1.625;
    }
}

@mixin Body-Deck() {
    @include heading-base;
    font-size: toRem(18px);
    line-height: 1.44;

    @media #{$bp-s} {
        font-size: toRem(20px);
        line-height: 1.7;
    }
}

@mixin Body-Deck-Franklin() {
    @include body-base;
    font-size: toRem(18px);
    line-height: 1.44;

    @media #{$bp-s} {
        font-size: toRem(20px);
        line-height: 1.7;
    }
}

@mixin All-Caps-Small {
    @include body-base;
    font-weight: 500;
    letter-spacing: 1.5px;
    font-size: toRem(12px);
    line-height: 1.83;
    text-transform: uppercase;
}

@mixin All-Caps {
    @include body-base;
    font-weight: 500;
    letter-spacing: 2px;
    font-size: toRem(14px);
    line-height: 1.57;
    text-transform: uppercase;
}

@mixin eyebrow {
    @include body-base;
    line-height: toRem(22px);
    font-size: toRem(12px);
    text-transform: uppercase;

    @media #{$bp-s} {
        line-height: toRem(16px);
    }
}


@mixin Button-Medium() {
    @include body-base;
    font-size: toRem(18px);
    line-height: toRem(28px);
    font-weight: 500;
}

@mixin Button-Link() {
    @include Body-Medium();
    font-weight: 500;
}

@mixin Nav-Item-Large() {
    @include heading-base;
    font-size: toRem(24px);
    line-height: toRem(26px);

    @media #{$bp-s} {
        font-size: toRem(46px);
        line-height: toRem(56px);
    }
}

@mixin Nav-Item-Medium() {
    @include heading-base;
    font-size: toRem(24px);
    line-height: toRem(24px);

    @media #{$bp-s} {
        font-size: toRem(32px);
        line-height: toRem(32px);
    }
}

@mixin Nav-Heading() {
    @include heading-secondary;
    font-size: toRem(22px);
    line-height: toRem(30px);

    @media #{$bp-s} {
        font-size: toRem(32px);
        line-height: toRem(44px);
    }
}

@mixin Display-3-Secondary() {
    @include Display-3();
    font-family: $heading-secondary-font-family;
}

@mixin Display-4-Secondary() {
    @include Display-4();
    font-family: $heading-secondary-font-family;
}
