@import '../../styles/main.scss';

$nav-height: toRem(80px);
$nav-height-mobile: toRem(60px);
$section-x-padding-sm: $spacing-3;
$section-x-padding-md: toRem(74px);
$section-x-padding-lg: toRem(165px);

.zn-quarterly-nav {
    &__content {
        transition: $transition-ease;
        background-color: $black-transpatent;
        display: flex;
        align-items: stretch;
        margin-top: $navigation-height-mobile;
        height: calc(100vh - #{$navigation-height-mobile});
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: $navigation-z-index + 1;

        @media #{$bp-m} {
            height: calc(100vh - #{$navigation-height});
            margin-top: $navigation-height;
        }

        .zn-horizontal-rule {
            margin: $spacing-2 auto;
        }
    }

    &__mobile {
        display: block;
        @media #{$bp-m} {
            display: none;
        }
    }

    &__social {
        margin-bottom: $spacing-4;
    }

    &__content-container {
        background-color: $white;
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        width: 100%;

        @media #{$bp-m} {
            max-width: 50%;
            width: 50%;
        }
    }

    &__content-container-lists {
        padding: $spacing-6 $spacing-4;
        @media #{$bp-m} {
            max-width: $section-content-max-width / 2;
            min-width: $section-content-max-width / 2;
            padding: $spacing-6;
            margin-left: auto;
        }
    }
}

.zn-quarterly-nav-bar-menu {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: $transition-ease;
    position: sticky;
    height: $navigation-height-mobile;
    top: 0;
    z-index: $navigation-z-index - 1;
    width: 100%;
    padding: 0 $section-x-padding-sm;
    pointer-events: none;
    padding-right: toRem(125px);
    opacity: 1;

    @media #{$bp-s} {
         padding: 0 $section-x-padding-md;
    }

    @media #{$bp-m} {
        padding: 0
            responsive-spacing($screen-m, $screen-xxl, 74px, 165px);
    }

    @media #{$bp-xxl} {
        padding: 0 $section-x-padding-lg;
    }

    @media #{$bp-m} {
        height: $navigation-height;
    }

    &.fade-out {
       animation: fadeOut 0.75s ease-out;
    }

    &.in-main-menu {
        z-index: 9;
        background-color: transparent;
        animation: fadeInFromNone 0.75s ease-out;
    }

    &.no-animation-close {
        animation: none;
    }

    &--padding-container {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $white;
        position: sticky;
        height: $navigation-height-mobile;
        top: 0;
        z-index: $navigation-z-index - 1;
        width: 100%;
        pointer-events: none;
        opacity: 1;
    
        @media #{$bp-m} {
            height: $navigation-height;
        }
    
        &.fade-out {
           animation: fadeOut 0.75s ease-out;
        }
    
        &.in-main-menu {
            z-index: 9;
            background-color: transparent;
            animation: fadeInFromNone 0.75s ease-out;
        }
    
        &.no-animation-close {
            animation: none;
        }

        &--open {
            transition: $transition-ease;
            transition-property: z-index;
            position: fixed;
            top: 0;
            z-index: $navigation-z-index + 2 !important;
            background-color: $white !important;
        }
    
    }

    @keyframes fadeOut {
        0% {
            display: block;
            opacity: 1;
        }

        1% {
            display: block;
            opacity: 0;
        }

        100% {
            display: block;
            z-index: $navigation-z-index - 1;
            opacity: 1;
        }
    }

    @keyframes fadeInFromNone {
        0% {
            display: none;
            opacity: 0;
        }

        1% {
            display: block;
            opacity: 0;
        }

        100% {
            display: block;
            opacity: 1;
        }
    }

    &__container {
        align-items: center;
        display: flex;
        flex-grow: 1;
        height: $navigation-height-mobile;
        justify-content: space-between;
        max-width: $section-content-max-width;
        top: 0;
        left: 0;
        width: 100%;
        max-width: toRem($section-content-max-width);   
        pointer-events: auto;
    }


    &__logo {
        display: none;
        height: toRem(28px);
        margin-right: toRem(44px);
        position: relative;
        width: toRem(100px);

        @media #{$bp-m} {
            display: inline-flex;
        }

        .zn-button-simple {
            max-width: 100%;
            min-height: 100%;
            padding: 0;
        }

        img {
            max-height: 100%;
            max-width: 100%;
            height: auto;
        }
    }

    &__hamburger {
        display: none;

        @media #{$bp-m} {
            display: block;
        }
    }

    &__content {
        align-items: center;
        display: flex;
    }

    .zn-quarterly-nav-bar-menu__title,
    .zn-quarterly-nav-bar-menu__sub-title {
        line-height: 1;
        padding: 0 $spacing-2;
        font-size: toRem(16px);

        @media #{$bp-m} {
            padding: 0 $spacing-3;
            font-size: toRem(22px);
        }

        &--division {
            border-right: 1px solid $medium-grey;
        }
    }

    .zn-quarterly-nav-bar-menu__title {
        font-weight: 500;
       padding-left: 0;

        @media #{$bp-m} {
            margin: 0;
            font-weight: 400;
            padding-left: $spacing-3;
        }
    }

    .zn-button-simple--border {
        display: inline-flex;
        padding: toRem(8px);
        height: $spacing-5;
        width: $spacing-5;
        margin: 0;
        justify-content: center;
        @media #{$bp-m} {
            border: 1px solid $light-grey;
            height: toRem(40px);
            width: toRem(40px);
        }
    }

    &__subscribe {
        display: none;
        @media #{$bp-m} {
            display: inline-flex;
        }
    }
}
