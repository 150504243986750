@import '../../styles/main.scss';

$grid-container-max-width: 1440px;

.zn-grid {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
    margin: 0 auto;
    max-width: toRem($grid-container-max-width);
    min-width: 100%;
    width: 100%;

    @media #{$bp-m} {
        flex-direction: row;
    }

    &--reverse-columns {
        flex-direction: column-reverse;
        @media #{$bp-m} {
            flex-direction: row;
        }
    }
}