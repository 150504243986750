@import '../../styles/main.scss';

.zn-search-bar-filter {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-right: $spacing-3;
    margin-bottom: $spacing-3;
    position: relative;
    width: 100%;

    &--active {
        height: 100%;
        margin-right: 0;
        position: absolute;
        background-color: $white;
        z-index: 3;
    }

    &--people-filter-dt {
        @include desktop-medium-only();
    }

    @media #{$bp-m} {
        width: auto;
        margin-bottom: 0;
        margin-top: $spacing-3;

        &--active {
            height: auto;
            margin-right: $spacing-3;
            position: relative;
            width: auto;
            background-color: transparent;
        }
    }

    &__button {
        border: none;
        background-color: transparent;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-left: $spacing-3;

        @media #{$bp-m} {
            border: 1px solid $darker-grey;
            background-color: transparent;
            border-radius: 100px;
            padding: $spacing-2 $spacing-3;
            color: $darker-grey;

            &--active {
                background-color: $torys-blue-transparent;
                color: $black;
                border-color: $torys-blue;
                border-width: 2px;
                margin: -1px;
            }

            &--disabled {
                border-color: $medium-grey;
                color: $medium-grey;
            }
        }
    }

    &__button:hover {
        @media #{$bp-m} {
            background-color: $torys-blue-transparent;
            color: $black;
            border-color: $torys-blue;
            border-width: 2px;
            margin: -1px;
            cursor: pointer;
        }
    }

    &__button--disabled:hover {
        @media #{$bp-m} {
            border-color: $medium-grey;
            color: $medium-grey;
            cursor: default;
            margin: 0;
            border: 1px solid $medium-grey;
            background-color: transparent;
        }
    }

    &__button--primary:hover {
        @media #{$bp-m} {
            cursor: default;
            margin: 0;
            border: 1px solid $darker-grey;
            background-color: transparent;
            color: $darker-grey;
        }
    }

    &__title {
        @include Display-6();
        padding-right: toRem(12px);

        @media #{$bp-m} {
            @include Body-Medium();
            font-weight: 400;
            padding-right: $spacing-2;

            &--primary {
                padding-right: 0;
            }
        }
        &--desktop {
            @include desktop-medium-only();
        }

        &--mobile {
            @include mobile-tablet-only();
        }
    }

    &__desktop-chevron {
        @include desktop-medium-only();
        line-height: 0;
        svg {
            pointer-events: none;
        }
        path {
            pointer-events: none;
        }
    }

    &__mobile-chevron {
        @include mobile-tablet-only();
        display: block;
        margin-left: 0;
        margin-right: toRem(16px);
        height: toRem(16px);
        width: toRem(10px);
        line-height: 0;
        color: $torys-blue;

        svg {
            max-height: 100%;
            max-width: 100%;
        }
    }

    &__mobile-selected-filters {
        @include mobile-tablet-only();
        @include All-Caps-Small();
        color: $darker-grey-2;
        margin-left: $spacing-3;
        font-weight: 400;
    }

    &__dropdown {
        display: none;

        &--active {
            display: flex;
            position: absolute;
            display: flex;
            flex-direction: column;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: $white;
        }

        @media #{$bp-m} {
            position: absolute;
            display: none;
            flex-direction: column;
            align-items: flex-start;
            top: 60px;
            max-height: 625px;
            width: 352px;
            background: $white;
            box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1), 0px 1px 4px rgba(0, 0, 0, 0.1);
            border-radius: 2px;

            &--active {
                display: flex;
                height: auto;
                top: 60px;
            }
        }
    }

    &__desktop-dropdown-title {
        @include desktop-medium-only();
        @media #{$bp-m} {
            @include Display-6();
            display: block;
            line-height: 1.875;
            padding: $spacing-4 toRem(40px) 0;
            margin-bottom: $spacing-2;

            &--disabled {
                color: $medium-grey;
            }
        }
    }

    &__mobile-dropdown-back-button {
        @include Display-6();
        @include mobile-tablet-only();
        color: $torys-blue;
        align-items: center;
        display: flex;
        flex-direction: row;
        padding: 0 0 toRem(14px);
        margin: 0 $spacing-3;
        border-bottom: 1px solid $light-grey;
        background-color: transparent;
        border: 0px none;
        padding-bottom: 0;
    }

    &__mobile-dropdown-title {
        color: $darker-grey;
    }

    &__dropdown-list {
        overflow-y: auto;
        padding: toRem(26px) 0 $spacing-1;
        list-style-type: none;
        max-height: 100%;
        margin-bottom: toRem(92px);

        @media #{$bp-m} {
            padding: $spacing-2 toRem(40px) toRem(26px);
            width: 100%;
            max-height: 625px;
            margin: 0;
        }
    }

    &__desktop-dropdown-list-item {
        @include Body-Medium();
        max-width: 236px;
        margin-bottom: $spacing-2;
    }

    &__desktop-dropdown-list-item:last-child {
        margin-bottom: 0;
    }

    &__dropdown-buttons {
        @include Body-Medium();
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: $spacing-3 toRem(20px) toRem(20px) toRem(34px);
        width: 100%;
        border-top: 1px solid $light-grey-45;
        margin-top: $spacing-3;
        position: absolute;
        bottom: 0;
        background-color: $white;

        @media #{$bp-m} {
            @include Body-Medium();
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            padding: $spacing-3 toRem(20px) toRem(20px) toRem(34px);
            width: 100%;
            border-top: 1px solid $light-grey-45;
            margin-top: $spacing-3;
            position: relative;
        }
    }

    &__clear-button {
        @include clear-filter();

        @media #{$bp-m} {
            &--disabled {
                cursor: default;
                color: $medium-grey;
            }

            &--disabled:hover {
                cursor: default;
                color: $medium-grey;
            }
        }
    }

    &__apply-button {
        @include mobile-tablet-only();
    }

    &__no-filters {
        margin: 0 $spacing-3;

        @media #{$bp-m} {
            margin: 0;
        }
    }

    &__no-filters-title {
        @include Body-Medium();
        padding-bottom: $spacing-1;
        color: $black;

        &--disabled {
            color: $medium-grey;
        }
    }

    &__no-filters-description {
        @include Body-Regular();
        color: $darker-grey;

        &--disabled {
            color: $medium-grey;
        }
    }
}
