@import '../../styles/main.scss';

@mixin search-bar-chevron() {
    @include mobile-tablet-only();
    display: block;
    margin-left: 0;
    margin-right: toRem(16px);
    height: toRem(16px);
    width: toRem(10px);
    line-height: 0;

    svg {
        max-height: 100%;
        max-width: 100%;
    }
}

.zn-event-search-bar {
    width: 100%;

    &__input {
        position: relative;
    }

    &__form {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border: none;
    }

    &__form-input {
        @include Display-4-Secondary();
        width: 100%;
        background-color: transparent;
        border: none;
        padding: $spacing-2 toRem(40px) $spacing-2 0;
        border-bottom: 1px solid $darker-grey;
        margin-bottom: 0;
        color: $black;
        border-radius: 0;
        -webkit-appearance: none;
        -webkit-border-radius: 0;

        @media #{$bp-m} {
            padding: $spacing-2 toRem(64px) $spacing-3 $spacing-3;
        }

        &:focus-visible {
            border-bottom: 2px solid $torys-blue;
            margin-bottom: -1px;
            outline: none;
        }
        &::placeholder {
            color: $darker-grey;
            opacity: 1;
        }

        &:-ms-input-placeholder {
            color: $darker-grey;
        }

        &::-ms-input-placeholder {
            color: $darker-grey;
        }
    }

    &__search-button {
        position: absolute;
        margin: auto;
        top: 0;
        bottom: 0;
        right: 0;
        padding: 0;
        color: $torys-blue;

        @media #{$bp-m} {
            padding: 0 $spacing-3 0 0;
        }
    }

    &__suggested-results-container {
        display: none;
    }

    &__suggested-results {
        list-style-type: none;
        margin: $spacing-2 0 0 0;
        padding: $spacing-4;
        position: absolute;
        z-index: 3;
        background-color: $white;
        border: 1px solid $light-grey;
        width: 100%;

        @media #{$bp-m} {
            padding: $spacing-5 $spacing-6;
            z-index: 4;
        }

        &--general-landing {
            z-index: 7;
        }
    }

    &__suggested-results-item {
        margin-bottom: $spacing-3;
    }

    &__suggested-results-item:last-child {
        margin-bottom: 0;
    }

    &__list-wrapper {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;

        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background-color: $white;

        &--active {
            height: 100%;
            position: fixed;
            z-index: 8;
        }

        @media #{$bp-m} {
            position: relative;
            height: 100%;
            background-color: transparent;

            &--active {
                z-index: 1;
            }
        }
    }

    &__mobile-header {
        padding: 0 $spacing-3;
    }

    &__mobile-header-content {
        @include Display-3();
        @include mobile-tablet-only();
        color: $darker-grey-2;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: toRem(12px) 0 toRem(28px);
        list-style-type: none;
        background-color: $white;
    }

    &__mobile-header-divider {
        @include mobile-tablet-only();
        width: 100%;
        height: 1px;
        background-color: $light-grey-45;

        &--menu-open {
            background-color: $white;
        }
    }

    &__filters {
        display: none;
        background-color: $white;

        &--active {
            display: flex;
            height: 100%;
        }

        @media #{$bp-m} {
            display: flex;
            margin-top: $spacing-3;
            background-color: transparent;

            &--active {
                display: flex;
            }
        }
    }

    &__filter-list {
        display: flex;
        flex-direction: column;
        margin: 0;
        padding: $spacing-4 0;
        height: 100%;
        justify-content: flex-start;
        width: 100%;
        position: relative;

        &--active {
            padding-top: 0;
        }

        @media #{$bp-m} {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            margin: 0;
            padding: 0;
            justify-content: flex-start;
            width: auto;
            align-items: center;
        }
    }

    &__mobile-filter-buttons {
        display: flex;
        flex-direction: row;
    }

    &__mobile-filter-button {
        border: 1px solid $darker-grey;
        background-color: transparent;
        border-radius: 100px;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: $spacing-2 $spacing-3;
        color: $darker-grey;
        margin: $spacing-5 $spacing-3 0 0;

        &--active {
            background-color: $torys-blue-transparent;
            color: $black;
            border-color: $torys-blue;
            border-width: 2px;
        }

        @media #{$bp-m} {
            display: none;
        }
    }

    &__mobile-filter-pill {
        @include Body-Medium();
        padding-right: $spacing-2;
    }

    &__chevron {
        line-height: 0;
    }

    &__mobile-footer {
        display: flex;
        flex-direction: row;
        align-items: center;

        justify-content: space-between;
        padding: $spacing-3 toRem(20px) toRem(20px) toRem(34px);
        width: 100%;
        border-top: 1px solid $light-grey-45;
        margin-top: $spacing-3;

        @media #{$bp-m} {
            display: none;
        }
    }

    &__mobile-clear-button {
        @include clear-filter();
    }

    &__desktop-clear-button {
        @include clear-filter();
        @include desktop-medium-only();

        @media #{$bp-m} {
            display: block;
            margin-top: $spacing-3;

            &--disabled {
                cursor: default;
                color: $medium-grey;
            }

            &--disabled:hover {
                cursor: default;
                color: $medium-grey;
            }
        }
    }

    &__mobile-published-menu-button {
        border: none;
        background-color: transparent;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-left: $spacing-3;

        @media #{$bp-m} {
            display: block;
        }
    }

    &__mobile-published-menu-title {
        @include Display-6();
        padding-right: toRem(12px);

        @media #{$bp-m} {
            display: none;
        }
    }

    &__mobile-published-menu-chevron {
        @include search-bar-chevron();
        color: $torys-blue;
    }

    &__mobile-published-menu-selected-filters {
        @include All-Caps-Small();
        @include mobile-tablet-only();
        color: $darker-grey-2;
        margin-left: $spacing-3;
        font-weight: 400;
    }

    &__published-menu-wrapper {
        @include desktop-medium-only();

        &--active {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            position: absolute;
            width: 100%;
            z-index: 3;
            height: 100%;
            background-color: $white;
        }
    }

    &__published-menu-back-button {
        @include Display-6();
        @include mobile-tablet-only();
        color: $torys-blue;
        align-items: center;
        display: flex;
        flex-direction: row;
        padding: 0 0 toRem(14px);
        margin: 0 $spacing-3;
        border-bottom: 1px solid $light-grey;
    }

    &__published-menu-chevron {
        @include search-bar-chevron();
    }

    &__published-menu-title {
        color: $darker-grey;
    }

    &__published-menu-month-title {
        @include Display-6();
        @include mobile-tablet-only();
        color: $black;
        margin-bottom: $spacing-2;
    }

    &__published-menu-months {
        display: flex;
        flex-direction: column;
        padding: $spacing-3;

        @media #{$bp-m} {
            flex-direction: row;
            padding: 0;
        }
    }

    &__month-bottom-buttons {
        @include Body-Medium();
        @include mobile-tablet-only();
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: $spacing-3 toRem(20px) toRem(20px) toRem(34px);
        width: 100%;
        border-top: 1px solid $light-grey-45;
        margin-top: $spacing-3;
        position: absolute;
        bottom: 0;
    }

    &__clear-button {
        @include clear-filter();
    }

    &__sc-editing {
        @include Display-5();
        padding: $spacing-3 0;
    }

    @media print {
        display: none;
    }
}
