@import '../../styles/main.scss';

.zn-search-results {
    margin: 0 auto;
    max-width: $section-content-max-width;
    padding-bottom: $spacing-6;
    width: 100%;
    scroll-margin-top: $spacing-6;

    @media #{$bp-m} {
        scroll-margin-top: $spacing-9;
    }

    &__nav {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: $spacing-3 0;

        h4 {
            @include Display-4-Secondary();
        }

        @media #{$bp-s} {
            align-items: center;
            flex-direction: row-reverse;
        }

        &--people-only {
            display: none;
        }
    }

    &__nav-result-details {
        display: flex;
        flex-direction: column;

        p {
            color: $darker-grey;
        }
    }

    &__nav-result-details {
        display: flex;
        flex-direction: column;
    }

    &__nav-result-filter {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin-bottom: $spacing-3;
        width: 100%;

        @media #{$bp-s} {
            margin-bottom: 0;
            margin-left: $spacing-2;
            width: auto;
        }
    }

    &__nav-result-filter-label {
        font-weight: 500;
        margin-right: $spacing-4;
        white-space: nowrap;
    }

    &__list {
        padding: 0;

    }

    &__list-item {
        list-style: none;
        border-top: 1px solid $light-grey;

        &:last-child {
            border-bottom: 1px solid $light-grey;
        }

        &--people-only {
            flex-direction: row-reverse;
            border: none;
            
            &:last-child {
                border-bottom: none;
            }
        }
    }

    &__loading {
        @include Display-5();
        width: 100%;
        text-align: center;
        text-transform: uppercase;
        color: $torys-blue;
    }

    &__error {
        @include Display-5();
        width: 100%;
        text-align: center;
        text-transform: uppercase;
        color: $orange;
    }

    &__pagination {
        display: flex;
        justify-content: center;
    }

    .zn-dropdown {
        padding: 0;

        &__content {
            padding: 0;
        }

        &__title {
            @include Body-Regular();
            color: $darker-grey;
            font-weight: 400;
        }

        &__list-item {
            padding: $spacing-2 $spacing-3;
            border-bottom: 1px solid $light-grey;
            margin: 0;
        }

        &__list-item:last-child {
            border-bottom: none;
        }

        &__list-item-button {
            @include Body-Regular();
            color: $darker-grey;
            background-color: transparent;
            width: 100%;
            text-align: left;
            border: none;
            padding: 0;

            &:hover {
                color: $torys-blue;
                cursor: pointer;
            }
        }
    }
}
