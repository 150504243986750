@import '../../styles/main.scss';

.zn-quote {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__icon {
        &--blue {
            color: $torys-blue;
        }

        &--orange {
            color: $orange;
        }

        &--teal {
            color: $teal;
        }

        &--grey {
            color: $light-grey;
        }
        &--navy {
            color: $dark-blue;
        }
    }

    &__text {
        @include Display-3-Secondary();
        text-align: center;
        margin: $spacing-3 0;
        color: $black;

        @media #{$bp-s} {
            margin-bottom: $spacing-4;
        }
    }

    &__subtext {
        @include All-Caps();
        text-align: center;
        color: $darker-grey;
    }

    @media print {
        page-break-inside: avoid;
    }
}
