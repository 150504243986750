@import '../../styles/main.scss';

$section-content-max-width: 637px;
$section-small-content-max-width: 414px;

$section-mobile-y-padding: $spacing-6;
$section-mobile-x-padding: $spacing-3;
$section-y-padding: toRem(54px);
$section-x-padding: toRem(72px);
$section-large-y-padding: toRem(236px);
$section-large-x-padding: toRem(169px);

@mixin QL-Hero-Heading() {
    @include heading-base;
    font-size: toRem(40px);
    line-height: 1.26;
    font-weight: normal;

    @media #{$bp-s} {
        font-size: toRem(54px);
        line-height: 1.18;
    }

    @media #{$bp-l} {
        font-size: toRem(72px);
    }
}

@mixin QL-Hero-Background-Image() {
    @include desktop-only();
    position: absolute;
    z-index: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-size: cover;
}

.zn-quarterly-landing-hero {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
    width: 100%;
    &__padding {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 100%;
        padding: $section-mobile-y-padding $section-mobile-x-padding;
        @media #{$bp-s} {
            padding: $section-y-padding $section-x-padding;
        }
        @media #{$bp-m} {
            padding: $section-large-y-padding $section-large-x-padding;
        }
    }
    &__content {
        width: 100%;
        z-index: 2;
        max-width: toRem($section-small-content-max-width);
        @media #{$bp-m} {
            max-width: toRem($section-content-max-width);
        }

        &--full {
            max-width: none;
        }
    }

    &__title {
        @include QL-Hero-Heading();
        color: $dark-blue;
        mix-blend-mode: normal;
        opacity: 0.96;
    }
    &__eyebrow {
        @include eyebrow();
        display: block;
        font-weight: 400;
        font-size: toRem(12px);
        margin-bottom: $spacing-3;
        @media #{$bp-s} {
            line-height: toRem(14px);
            font-weight: 500;
            margin-bottom: $spacing-2;
        }
        color: $darker-grey;
    }
    &__description {
        @include Body-Medium();
        color: $darker-grey;
        line-height: toRem(28px);
        margin-top: $spacing-5;
    }
    &__background-image {
        @include QL-Hero-Background-Image();
        background-position: right bottom;
    }
    &__background-shape {
        @include QL-Hero-Background-Image();
        background-position: right top;
        @media #{$bp-xl} {
            width: 80%;
        }
        z-index: 1;
    }
    &__mobile-image {
        @include mobile-only();
        width: 100%;
        height: auto;
    }
    &__scroll {
        display: none;
        left: $section-mobile-x-padding;
        @media #{$bp-s} {
            left: $section-x-padding;
        }
        @media #{$bp-m} {
            display: block;
            left: $section-large-x-padding;
        }
        position: absolute;
        bottom: 0;
        z-index: 2;
        height: 121px;
        border-left: 1px solid $darker-grey;
        box-sizing: border-box;
    }
    &__scroll-text {
        @include All-Caps();
        font-weight: normal;
        position: absolute;
        top: 0;
        left: toRem(28px);
        transform-origin: 0 0;
        transform: rotateZ(90deg);
        color: $darker-grey;
    }
}
