@import '../../styles/main.scss';

.zn-content-card {
    background-color: $bg-blue;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-decoration: none;
    top: 0;
    transition: top ease 0.3s;
    color: $darker-grey;
    height: 100%;

    &:hover {
        cursor: pointer;
        top: -10px;
        text-decoration: none;
        color: $darker-grey;

        .zn-content-card__arrow {
            background-color: $torys-blue;

            svg {
                color: $bg-blue;
            }
        }

        .zn-button {
            border-color: $white;
            background-color: $white;
            color: $torys-blue;
        }
    }

    &--non-navigatable {
        &:hover {
            cursor: default;
        }
    }

    &--white {
        background-color: $white;

        &:hover {
            .zn-content-card__arrow {
                svg {
                    color: $white;
                }
            }
        }
    }

    &--sc-editing {
        &:hover {
            top: 0;
            color: $darker-grey;
        }
    }

    &--series {
        background-color: $torys-blue;
        color: $white;
        justify-content: start;

        &:hover {
            cursor: default;
            color: $white;
        }
    }

    &__image {
        img {
            object-fit: cover;
            width: 100%;
            height: auto;
            max-height: 100%;
        }
    }

    &__image-highlight {
        width: 100%;
        height: $spacing-2;

        &--orange {
            background-color: $orange;
        }

        &--teal {
            background-color: $teal;
        }

        &--navy {
            background-color: $dark-blue;
        }

        &--blue {
            background-color: $torys-blue;
        }
    }

    &__content {
        padding: $spacing-4;
    }

    &__eyebrow-content {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    &__icon {
        color: $torys-blue;

        &--series {
            color: $white;
        }

        svg {
            color: inherit;
        }
    }

    &__content-type {
        @include All-Caps-Small();
        padding-left: toRem(10px);
        font-weight: 400;
        size: 12px;
        line-height: 1rem;

    }

    &__title {
        @include Display-4-Secondary();
        padding-top: $spacing-3;
        color: $black;

        &--series {
            color: $white;
        }
    }

    &__description {
        @include Body-Regular();
        padding-top: $spacing-3;
    }

    &__card-footer {
        width: 100%;
        padding: $spacing-4;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    &__date {
        @include All-Caps-Small();
        padding-left: toRem(10px);
        font-weight: 400;
        size: 12px;
        line-height: 1rem;
    }

    &__arrow {
        background-color: transparent;
        line-height: 0;
        padding: $spacing-1;
        border-radius: 50%;
        transition: background-color ease 0.3s;

        svg {
            color: $torys-blue;
            transition: color ease 0.3s;

        }
    }

    &__cta-container {
        display: block;
        margin: $spacing-4;
    }

    .zn-button {
        display: inline-block;
        width: auto;
    }
}