@import '../../styles/main.scss';

.zn-main-nav {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: $navigation-z-index;

    &--open {
        z-index: 10;
    }

    &--light {
        background-color: $bg-blue;
    }

    &--dark {
        background-color: $dark-blue;
    }

    &.page-editing {
        position: relative;
    }

    .zn-mega-menu {
        position: absolute;
        left: 0;
        top: 0;
        z-index: $navigation-z-index;
    }

    .zn-main-nav__bypass-button {
        font-size: toRem(16px);
        z-index: $navigation-z-index;
        width: 100%;
        position: absolute;
        left: -100000px;
        outline: 0 none !important;
        a {
            display: block;
            width: 100%;
        }
        &:focus {
            position: relative;
            left: 0;
            right: 0;
            width: 100%;
            z-index: 999;

            width: 100%;
            &:hover {
                background-color: #ffffff;
            }
        }
    }

    &__animate {
        .zn-main-nav-bar {
            @include fadeInTop();
        }
    }
}

.zn-mega-menu {
    transition: $transition-ease;
    -webkit-animation: fadeIn $transition-ease;
    animation: fadeIn $transition-ease;
    background-color: $bg-blue;
    display: flex;
    flex-direction: column;
    margin-top: toRem(62px);
    min-height: calc(100vh - 62px);
    max-height: calc(100vh - 62px);
    overflow-y: auto;
    width: 100%;

    @media #{$bp-m} {
        margin-top: toRem(86px);
        min-height: calc(100vh - 86px);
        max-height: calc(100vh - 86px);
    }

    @supports (-webkit-touch-callout: none) {
        min-height: fill-available;
        max-height: fill-available;
    }
}

.zn-mega-menu__content {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    flex: 1;
    padding: $spacing-6 $spacing-3;

    @supports (-webkit-touch-callout: none) {
        display: block;
        overflow-y: auto;
        max-height: fill-available;
    }

    @media #{$bp-m} {
        padding: $spacing-6 $spacing-9;
    }

    @media #{$bp-l-scaling} {
        margin: auto;
        width: 1250px;
        padding-left: 0;
        padding-right: 0;
    }
}
