@import '../../styles/main.scss';

.zn-item--icon {
    display: flex;
    align-items: flex-start;

    .zn-item--icon__icon {
        display: flex;
        margin-right: $spacing-3;
        width: toRem(18px);
        img {
            max-width: 100%;
            height: fit-content;
        }
    }

    .zn-button {
        padding: 0;
        margin-bottom: $spacing-2;
    }

    .type-body {
        color: $dark-grey;
    }

    .zn-item--icon__content {
        display: flex;
        flex-direction: column;
    }

    .zn-item--icon__content__description {
        display: none;
        @media #{$bp-m} {
            display: block;
        }
    }
}