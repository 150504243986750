@import '../../styles/main.scss';

.zn-contact-cards {
    &__title {
        margin-bottom: toRem(24px);

        @media print {
            font-weight: 100;
            font-size: 0.12in;
            letter-spacing: 1px;
        }
    }

    @media print {
        page-break-inside: avoid;
        display: inline-block;
        width: 100%;
    }
}

.zn-contact-cards-list {
    padding: 0;
    margin: 0;
    margin-bottom: toRem(24px);

    .zn-contact-card {
        margin-bottom: toRem(16px);
        @media print {
            display: inline-block !important;
            width: 32.6%;
            max-width: 32.6%;
            margin-right: 0.5% !important;
            &:last-child {
                margin-right: 0 !important;
            }
            &:nth-child(n + 3) {
                margin-right: 0 !important;
            }
        }
    }

    &--inline {
        .zn-contact-card {
            display: inline-flex;
            margin-right: toRem(40px);
            vertical-align: middle;
        }
    }

    @media print {
        width: 100%;
        display: block;
    }
}

.zn-contact-cards-list-item {
    list-style: none;
    margin-bottom: $spacing-3;
}

.zn-contact-cards--hide-images-mobile {
    .zn-contact-card__img {
        display: none;
        @media #{$bp-s} {
            display: inline-block;
        }
    }
}

.zn-contact-cards--hide-images {
    .zn-contact-card__img {
        display: none;
    }
}
