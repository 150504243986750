@function toRem($size) {
    $remSize: $size / $font-size-base;
    @return #{$remSize}rem;
}

// If the parameters have different units, it might mess up the render of the value.
// It's better if it's always used with pixels as units
@function responsive-spacing($min-screen-size, $max-screen-size, $min-space, $max-space) {
    $factor: ($max-space - $min-space) / ($max-screen-size - $min-screen-size);
    $size-diff: calc(100vw - #{$min-screen-size});
    @return calc(#{$size-diff} * #{$factor} + #{$min-space});
}

@mixin ellipsis-text() {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

@mixin arrow-down($size, $ml, $mr) {
    content: ' ';
    border-color: $black;
    border-width: 0px 2px 2px 0px;
    border-style: solid;
    display: inline-block;
    height: $size;
    margin: 0 0 $ml $mr;
    transform: rotate(45deg);
    width: $size;
}

// Container - subject to change with designs
@mixin container() {
    width: 100%;
    padding: $spacing-6 $spacing-3;

    @media #{$bp-m} {
        padding: 0 $spacing-7;
    }

    @media #{$bp-l} {
        padding: 0 $spacing-8;
    }

    @media #{$bp-xl} {
        padding: 0 $spacing-9;
    }
}

// Link - subject to change with designs
@mixin standard-link() {
    @include Body-Regular();
    text-decoration: none;
    color: $white;

    &:hover {
        text-decoration: underline;
        color: $white;
    }
}

@mixin list-with-title() {
    display: flex;
    flex-direction: column;
    color: $white;
    margin-bottom: $spacing-4;
    align-items: flex-start;

    > ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }
}

@mixin mobile-only() {
    display: block;
    @media #{$bp-s} {
        display: none;
    }
}

@mixin mobile-tablet-only() {
    display: block;
    @media #{$bp-m} {
        display: none;
    }
}

@mixin desktop-only() {
    display: none;
    @media #{$bp-s} {
        display: block;
    }
}

@mixin desktop-medium-only() {
    display: none;
    @media #{$bp-m} {
        display: block;
    }
}

@mixin contact-link() {
    color: $white;
    text-decoration: none;
    line-height: toRem(28px);

    &:hover {
        text-decoration: underline;
        color: $white;
        cursor: pointer;
    }

    &:focus {
        border: 1px solid $white;
    }
}

@mixin clear-filter() {
    @include Display-6();
    color: $torys-blue;
    border: none;
    font-weight: 400;
    background-color: transparent;
    padding: 0;
    text-decoration: underline;

    @media #{$bp-s} {
        &:hover {
            cursor: pointer;
            color: $torys-dark-blue;
        }
    }
}

@mixin pdf-hero() {
    @media print {
        position: relative;
        width: calc(100% + 1.2in);
        left: -0.6in;
        right: -0.6in;
    }
}
