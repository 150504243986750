@import '../../styles/main.scss';

.zn-expertise-page-links-container {
    position: relative;
    right: 1rem;
    width: 100vw;
    padding-left: 0;
    padding-right: 0;

    @media #{$bp-s} {
        width: 100%;
    }

    &__desktop {
        display: none;
        @media #{$bp-s} {
            display: block;
        }
    }
    &__mobile {
        display: block;
        @media #{$bp-s} {
            display: none;
        }
    }

    &__editor {
        width: 100%;
        min-height: toRem(100px);
        margin: 1rem;
    }

    &__row {
        width: 100%;
        display: flex;
    }

    &__white {
        background-color: $white;
    }

    &__gray {
        background-color: $bg-blue;
    }

    &__bottom-line-container {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    &__bottom-line {
        width: 50%;
        margin-left: $spacing-3;
        margin-right: $spacing-3;
        height: 1px;
        border-bottom: 1px solid $light-grey;
    }
}
