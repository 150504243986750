//Typography
$heading-font-family: 'mencken-std-head', sans-serif;
$heading-secondary-font-family: 'mencken-std-text', sans-serif;
$body-font-family: 'franklin-gothic-urw', sans-serif;
$font-size-base: 16px;

// Palette
$black: #000000;
$primary-black: #15191b;
$white: #ffffff;
$dark-blue: #002b51;
$torys-blue: #006eaa;
$torys-dark-blue: #005b8c;
$bg-light-blue: #006eaa15;
$bg-blue: #f8f9fa;
$orange: #f16522;
$orange-45: #d54414;
$teal: #9bc6b8;
$Teal-3: #6c9f90;
$teal-45: #2b846e;
$light-grey: #e6e6e6;
$light-grey-45: #ebebeb;
$medium-grey: #bebebe;
$dark-grey: #6e6e6e;
$darker-grey: #4c4c4c;
$darker-grey-2: #424D54;
$black-transpatent: rgba(21, 25, 27, 0.4);
$shadow: rgba(0, 0, 0, 0.1);
$card-shadow: 0px 1px 4px 0px $shadow;
$torys-blue-transparent: rgba(0, 110, 170, 0.05);
$torys-dark-blue-transparent: rgba(0, 110, 170, 0.15);
$torys-orange-transparent: rgba(241, 101, 34, 0.05);
$torys-teal-transparent: rgba(155, 198, 184, 0.15);
$torys-grey-transparent: rgba(230, 230, 230, 0.25);

// Widths
$section-content-max-width: 1101px;
$section-full-width-max-width: 1600px;

// Spacer
$spacing-1: 0.25rem; // 4px
$spacing-2: 0.5rem; // 8px
$spacing-3: 1rem; // 16px
$spacing-4: 1.5rem; // 24px
$spacing-5: 2rem; // 32px
$spacing-6: 3rem; // 48px
$spacing-7: 5rem; // 80px
$spacing-8: 6rem; // 96px
$spacing-9: 7.5rem; // 120px

// Breakpoints
$screen-s: 768px;
$screen-m: 992px;
$screen-l: 1200px;
$screen-l-scaling: 1300px;
$screen-xl: 1400px;
$screen-xxl: 1920px;
$screen-2560: 2560px;
$screen-3840: 3840px;

$bp-s: 'screen and (min-width: #{$screen-s})';
$bp-m: 'screen and (min-width: #{$screen-m})';
$bp-l: 'screen and (min-width: #{$screen-l})';
$bp-l-scaling: 'screen and (min-width: #{$screen-l-scaling})';
$bp-xl: 'screen and (min-width: #{$screen-xl})';
$bp-xxl: 'screen and (min-width: #{$screen-xxl})';
$bp-2560: 'screen and (min-width: #{$screen-2560})';

// Utils
$transition-ease: .25s ease 0s;
$navigation-height: #{86px / $font-size-base}rem;
$navigation-height-mobile: #{62px / $font-size-base}rem;
$navigation-z-index: 7;
