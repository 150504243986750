@import '../../styles/main.scss';

.zn-contact-card {
    display: flex;
    align-items: center;
    list-style: none;
    margin: 0;
    padding: 0;

    &__title {
        font-weight: 500;
        word-break: break-word;

        @media print {
            font-size: 0.146in;
            text-underline-offset: 0.05in;
            margin-bottom: 0;
            text-decoration: underline;
            display: inline-flex;
        }
    }

    .zn-contact-card__middle-initial,
    img {
        border-radius: 100%;
        height: toRem(64px);
        margin-right: $spacing-3;
        width: toRem(64px);

        @media print {
            width: 0.48in;
            height: 0.48in;
        }
    }

    .zn-link {
        @media print {
            font-size: 0.146in;
            text-underline-offset: 0.05in;
        }
    }

    .zn-contact-card__middle-initial {
        background-color: $teal;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
