@import '../../styles/main.scss';

.zn-checkbox {
    padding-bottom: $spacing-2;

    &.checked {
        background-color: $bg-blue;
        font-weight: 500;
        color: $torys-blue;
    }


    @media #{$bp-m} {
        font-weight: inherit;
        padding: 0;
        margin-bottom: $spacing-3;

        &.checked {
            font-weight: inherit;
            color: $torys-blue;
            padding: 0;
            margin-bottom: $spacing-3;
            background-color: transparent;
        }

        &--disabled {
            color: $medium-grey;
        }
    }

    &__wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin: 0;

        @media #{$bp-m} {
            justify-content: flex-start;
            &:hover {
                cursor: pointer;
                color: $torys-blue;
                .zn-checkbox__styled {
                    color: $torys-blue;
    
                    &--disabled {
                        color: $medium-grey;
                    }
                }
            }
        } 
    }

    &__hidden {
        border: 0;
        clip: rect(0 0 0 0);
        clippath: inset(50%);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        white-space: nowrap;
        width: 1px;
    }


    &__styled {
        display: none;
        position: absolute;

        @media #{$bp-m} {
            display: block;
            line-height: 0;
            transition: all 150ms;
            color: $dark-grey;
            position: relative;

            &.checked {
                color: $torys-blue;
            }

            &--disabled {
                color: $medium-grey !important;
            }
        }
    }

    &__container {
        display: none;
        position: absolute;


        @media #{$bp-m} {
            display: block;
            position: relative;
        }
    }

    &__label {
        @include Body-Medium();
        padding: $spacing-2 toRem(21px) $spacing-2 $spacing-3;

        @media #{$bp-m} {
            padding: 0 $spacing-3 0 toRem(12px);

            &--disabled {
                color: $medium-grey;
            }
        }
    }

    &__mobile-icon {
        margin-right: $spacing-3;
        
        svg {
            color: $torys-blue;
        }

        @media #{$bp-m} {
            display: none;
        }
    }

    @media #{$bp-m} {
        &:focus-within {
            .zn-checkbox__label {
                color: $torys-blue;
                
                @media #{$bp-m} {
                    &--disabled {
                        color: $medium-grey;
                    }
                }
            }
    
            .zn-checkbox__styled {
                color: $torys-blue;
    
                &--disabled {
                    color: $medium-grey;
                }
            }
        }
    } 
}

.zn-checkbox-dropdown {

    &__header-container {
        display: flex;
        flex-direction: row;
        width: 100%;
        align-items: center;

        .zn-checkbox {
            margin-bottom: 0;
            width: $spacing-4;
            margin: 0 $spacing-3 0 0;
            
        }
    }

    &__title-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border: none;
        background-color: transparent;
        border-bottom: 1px solid $light-grey;
        padding-bottom: $spacing-1;
        width: 100%;

    }

    &__title {
        @include Body-Medium();
        color: $black;
        text-align: left;

        &--disabled {
            color: $medium-grey;
        }
    }

    &__chevron {
        color: $torys-blue;

        &--disabled {
            color: $medium-grey;
        }
    }

    &__wrapper {
        width: 100%;
    }

    &__dropdown-list {
        display: block;
        overflow: hidden;
        max-height: 0px;
        transition: max-height 0.6s ease;
   

        width: 100%;
        padding: $spacing-3 0 0 toRem(37px);

        &--active {
            max-height: 625px;
            position: relative;
            overflow-y: auto;
            
        }
    }

}