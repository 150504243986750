@import '../../../styles/main.scss';

.zn-event-autofill-search-result {
    @include Body-Medium();
    display: flex;
    flex-direction: row;
    align-items: center;
    color: $black;
    text-decoration: none;
    
    &__link {
        &:hover {
            color: $torys-blue;
            cursor: pointer;
            text-decoration: none;

        }
    }
    &__text {
        padding-left: $spacing-3;
        overflow: hidden;
        color: inherit;
    }

    &__text-bold {
        font-weight: 500;
    }

    &__image {
        padding: 0;
        line-height: 0;
        width: toRem(40px);
        min-width: toRem(40px);
        height: toRem(40px);
        border-radius: 4px;
        
        img {
            width: toRem(40px);
            height: toRem(40px);
            border-radius: 4px;
        }
    }

    &__icon {
        padding: 0;
        line-height: 0;
        width: toRem(40px);
        min-width: toRem(40px);
        height: toRem(40px);
        border-radius: 4px;
        background-color: $bg-blue;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $torys-blue;
    }
}

