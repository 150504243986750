@import '../../styles/main.scss';

$section-x-padding-override-md: toRem(165px);

.zn-sticky-side-banner {
    display: flex;
    flex-direction: row;
    position: relative;

    &__content {
        display: flex;
        flex: 1;
        flex-direction: column;
        max-width: 100%;

        .zn-section {
            @media #{$bp-s} {
                padding-left:  $section-x-padding-override-md;
                padding-right:  $section-x-padding-override-md;
            }
        }

        .zn-section__content {
            max-width: toRem(732px);
        }
    }

    &__banner-container {
        @include desktop-only();
        z-index: 1;
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        pointer-events: none;

        &--sc-editing {
            position: unset;
            border-left: 1px dotted black;
            height: unset;
        }    
    }

    &__banner {
        padding-top: toRem(135px);
        padding-bottom: toRem(135px);
        position: sticky;
        top: 0;
        pointer-events: auto;

        &--sc-editing {
            position: unset;
        }    
    }

    &__banner-content {
        background-color: $bg-blue;
        padding: $spacing-4 $spacing-3;
    }
}