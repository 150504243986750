@import '../../styles/main.scss';

$column-total: 12;
$section-y-padding: toRem(52px);
$section-x-padding: toRem(74px);
$section-large-y-padding: toRem(104px);
$section-large-x-padding: toRem(165px);

.zn-sticky-side-section {
    position: relative;
    display: flex;
    flex-direction: column;

    &--alternate-colors {
        .show-section:nth-child(odd) {
            background-color: $white;
        }
        .show-section:nth-child(even) {
            background-color: $bg-blue;

            @media print {
                background-color: $white;
            }
        }

        /* THIS IS HACKY BELOW: NEEDS TO BE FIXED */

        /* after the first non-.parent, toggle colors */
        .zn-section:not(.show-section) ~ .show-section:nth-child(odd) {
            background-color: $bg-blue;
        }
        .zn-section:not(.show-section) ~ .show-section:nth-child(even) {
            background-color: $white;
        }

        /* after the second non-.parent, toggle again */
        .zn-section:not(.show-section)
            ~ .zn-section:not(.show-section)
            ~ .show-section:nth-child(odd) {
            background-color: $white;
        }
        .zn-section:not(.show-section)
            ~ .zn-section:not(.show-section)
            ~ .show-section:nth-child(even) {
            background-color: $bg-blue;
        }

        /* after the third non-.parent, toggle again */
        .zn-section:not(.show-section)
            ~ .zn-section:not(.show-section)
            ~ .zn-section:not(.show-section)
            ~ .show-section:nth-child(odd) {
            background-color: $bg-blue;
        }
        .zn-section:not(.show-section)
            ~ .zn-section:not(.show-section)
            ~ .zn-section:not(.show-section)
            ~ .show-section:nth-child(even) {
            background-color: $white;
        }

        /* after the fourth non-.parent, toggle again */
        .zn-section:not(.show-section)
            ~ .zn-section:not(.show-section)
            ~ .zn-section:not(.show-section)
            ~ .zn-section:not(.show-section)
            ~ .show-section:nth-child(odd) {
            background-color: $white;
        }
        .zn-section:not(.show-section)
            ~ .zn-section:not(.show-section)
            ~ .zn-section:not(.show-section)
            ~ .zn-section:not(.show-section)
            ~ .show-section:nth-child(even) {
            background-color: $bg-blue;
        }

        /* after the fifth non-.parent, toggle again */
        .zn-section:not(.show-section)
            ~ .zn-section:not(.show-section)
            ~ .zn-section:not(.show-section)
            ~ .zn-section:not(.show-section)
            ~ .zn-section:not(.show-section)
            ~ .show-section:nth-child(odd) {
            background-color: $bg-blue;
        }
        .zn-section:not(.show-section)
            ~ .zn-section:not(.show-section)
            ~ .zn-section:not(.show-section)
            ~ .zn-section:not(.show-section)
            ~ .zn-section:not(.show-section)
            ~ .show-section:nth-child(even) {
            background-color: $white;
        }

        @media print {
            background-color: $white;
        }
    }

    &--sc-editing {
        display: flex;
        flex-direction: row;
    }

    &__navigation {
        z-index: 1;
        width: 100%;
        height: 100%;
        position: sticky;
        pointer-events: none;
        top: $navigation-height-mobile;
        @media #{$bp-s} {
            position: absolute;
            height: 100%;
            top: 0;
            width: #{(4 * 100 / $column-total) + '%'};
            max-width: #{(4 * 100 / $column-total) + '%'};

            &--sc-editing {
                margin: 2rem 0;
                position: relative;
            }
        }

        @media #{$bp-m} {
            position: absolute;
            height: 100%;
            top: 0;

            &--sc-editing {
                display: flex;
                flex-direction: row;
            }
        }

        &--sc-editing {
            position: unset;
            display: flex;
            flex-direction: row;
            margin: 0;
            padding: 0;
            width: unset;
            border-right: 1px dotted black;
            height: unset;
        }
    }

    &__navigation-container {
        position: sticky;
        top: $navigation-height-mobile;
        pointer-events: auto;

        @media #{$bp-s} {
            position: sticky;
            top: 0;
            margin-top: 0;
            padding-top: $spacing-7;
            padding-bottom: $spacing-3;
            margin-left: $section-x-padding;
        }

        @media #{$bp-m} {
            position: sticky;
            top: 0;
            margin-top: 0;
            padding-top: $section-large-y-padding;
            margin-left: $section-large-x-padding;
        }

        &--sc-editing {
            position: unset;
            margin: 0;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;

        .zn-section {
            display: flex;

            @media #{$bp-s} {
                justify-content: flex-end;
            }
        }

        .zn-section__content {
            width: 100%;

            @media #{$bp-s} {
                justify-content: flex-end;
                width: #{(8 * 100 / $column-total) + '%'};
                max-width: #{(8 * 100 / $column-total) + '%'};
            }
        }

        &--sc-editing {
            flex: 1;

            .zn-section__content {
                width: 100%;
                max-width: unset;
            }
        }
    }

    @media print {
        margin-top: 0.02in;
    }
}
