@import '../../styles/main.scss';

.zn-simple-hero {
    padding: $spacing-5 $spacing-3;
    @media #{$bp-s} {
        padding: toRem(80px) toRem(166px);
    }

    &__container {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
    }

    &__divider {
        margin: toRem(32px) 0;
        position: relative;
        width: toRem(95px);
        height: toRem(5px);
    }

    @include pdf-hero();
}
