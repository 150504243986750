@import '../../styles/main.scss';

.zn-news-card-row {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    grid-row-gap: $spacing-3;
    grid-column-gap: $spacing-4;
    margin-top: $spacing-5;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
    width: 100%;

    &--hide-mobile {
        display: none;
    }

    @media #{$bp-m} { 
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        grid-row-gap: toRem(40px);
    }
}