@import '../../styles/main.scss';

.zn-mega-menu-primary {
    flex: 1;
    flex-direction: column;
    -webkit-animation: fadeIn $transition-ease;
    animation: fadeIn $transition-ease;

    &--xs {
        display: block;
        @media #{$bp-m} {
            display: none;
        }
    }

    &--lg {
        display: none;
        @media #{$bp-m} {
            display: flex;
        }
    }
}

.zn-mega-menu-primary-list__item {
    list-style: none;
}

.zn-mega-menu-primary__body {
    display: none;
    @media #{$bp-m} {
        display: flex;
        flex-direction: row;
    }
}

.zn-mega-menu-primary__body--xs {
    flex-direction: column;
    @media #{$bp-m} {
        display: none;
    }
    .zn-mega-menu-primary__content--xs {
        padding: 0;
    }

    .zn-mega-menu-primary-list__item {
        margin-bottom: $spacing-4;

        .zn-button--tertiary {
            &:hover {
                color: $black !important;
            }
        }
    }
}

.zn-mega-menu-primary__content {
    @media #{$bp-m} {
        display: flex;
        flex-wrap: wrap;
    }

    &--one {
        display: block;
    }

    .zn-mega-menu-primary__content__column {
        display: inline-block;
        margin-bottom: $spacing-3;
        justify-content: flex-start;
        vertical-align: top;

        @media #{$bp-m} {
            margin-right: 5px;
            margin-left: 0;
            width: calc(50% - 5px);
            &:nth-child(even) {
                margin-right: 0;
                margin-left: 5px;
            }
        }

        @media #{$bp-l} {
            width: calc(33.3% - 27px);
            &:nth-child(3n + 2) {
                margin: 0;
            }
            &:nth-child(3n-2) {
                margin-right: toRem(28px);
                margin-left: 0;
            }
            &:nth-child(3n) {
                margin-right: 0;
                margin-left: toRem(28px);
            }
        }
    }

    .zn-mega-menu-primary__content__column--one {
        display: block;
        &:nth-child(n) {
            width: 100%;
            margin: 0;
        }
    }
    .zn-link--nav {
        font-size: toRem(16px);
        padding: 0;
    }

    .zn-button {
        padding-left: 0;
    }
}

.zn-mega-menu-primary__footer--xs {
    display: flex;
    @media #{$bp-m} {
        display: none;
    }

    .zn-divider {
        margin: $spacing-3 0 $spacing-5;
    }
}

.zn-mega-menu-primary__sidebar {
    display: flex;
    flex-direction: column;

    .zn-mega-menu-primary-list__item {
        padding-left: toRem(20px);
        margin-bottom: toRem(40px);

        &.active {
            align-items: center;
            border-left: 4px solid $torys-blue;
            display: flex;
            padding-left: $spacing-3;
            position: relative;
        }
    }

    .zn-link--nav {
        margin-bottom: toRem(18px);
    }

    padding: 0;
    margin-right: $spacing-2;
    min-width: toRem(300px);
}

.zn-mega-menu-primary__footer--xs {
    .zn-button {
        margin-bottom: $spacing-2;
    }
}

.mobile-navigation-item {
    &.zn-link--light {
        &:hover {
            color: $black !important;
        }
    }

    &.zn-button--tertiary {
        &:hover {
            color: $black !important;
        }
    }

    &.zn-link--light.inactive {
        &:hover {
            color: $dark-grey !important;
        }
    }
}
