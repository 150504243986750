@import '../../styles/main.scss';

.map-container {
  height: 100%;
}

.sidebar {
  background-color: rgba(35, 55, 75, 0.9);
  color: #fff;
  padding: 6px 12px;
  font-family: monospace;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  margin: 12px;
  border-radius: 4px;
}

.marker {
  width: 40px;
  height: 60px;
}

.mapboxgl-popup {
  width: toRem(287px);
  max-width: toRem(287px) !important;

  @media #{$bp-s} {
    width: toRem(392px);
    max-width: toRem(392px) !important;
  }
}


.mapboxgl-popup-anchor-top, .mapboxgl-popup-anchor-top-left, .mapboxgl-popup-anchor-top-right {
  .mapboxgl-popup-tip {
    border-right: 9px solid transparent !important;
    border-left: 9px solid transparent !important;
    border-bottom: 16px solid $dark-blue !important;
    filter: drop-shadow(0px -2px 1px rgba(0, 0, 0, 0.25));
  }
  
  .mapboxgl-popup-tip:after {
    border-right: 9px solid transparent;
    border-left: 9px solid transparent;
    border-bottom: 16px solid $bg-blue;  
    bottom:-18px;
    right:-9px;
  }
}

.mapboxgl-popup-anchor-top-left {
  .mapboxgl-popup-tip {
    left: 9px;
  }
}

.mapboxgl-popup-anchor-top-right {
  .mapboxgl-popup-tip {
    right: 9px;
  }
}

.mapboxgl-popup-anchor-bottom, .mapboxgl-popup-anchor-bottom-left, .mapboxgl-popup-anchor-bottom-right {
  top: -65px !important;
  .mapboxgl-popup-tip {
    border-right: 9px solid transparent !important;
    border-left: 9px solid transparent !important;
    border-top: 16px solid $dark-blue !important;
    filter: drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.25));
  }
  
  .mapboxgl-popup-tip:after {
    border-right: 9px solid transparent;
    border-left: 9px solid transparent;
    border-top: 16px solid $bg-blue;  
    top:-18px;
    left:-9px;
  }
}

.mapboxgl-popup-anchor-bottom-left {
  .mapboxgl-popup-tip {
    left: 9px;
  }
}

.mapboxgl-popup-anchor-bottom-right {
  .mapboxgl-popup-tip {
    right: 9px;
  }
}

.mapboxgl-popup-anchor-left { 
  .mapboxgl-popup-tip {
    border-top: 9px solid transparent !important;
    border-bottom: 9px solid transparent !important;
    border-right: 16px solid $dark-blue !important;
    filter: drop-shadow(-2px 0px 1px rgba(0, 0, 0, 0.25));

  }
  
  .mapboxgl-popup-tip:after {
    border-top: 9px solid transparent;
    border-bottom: 9px solid transparent;
    border-right: 16px solid $bg-blue;  
    right:-18px;
    bottom:-9px;
  }
}

.mapboxgl-popup-anchor-right { 
  .mapboxgl-popup-tip {
    border-top: 9px solid transparent !important;
    border-bottom: 9px solid transparent !important;
    border-left: 16px solid $dark-blue !important;
    filter: drop-shadow(2px 0px 1px rgba(0, 0, 0, 0.25));

  }
  
  .mapboxgl-popup-tip:after {
    border-top: 9px solid transparent;
    border-bottom: 9px solid transparent;
    border-left: 16px solid $bg-blue;  
    left:-18px;
    bottom:-9px;
  }
}

.mapboxgl-popup-tip {
  width: 0; 
  height: 0; 
  position: relative;
}

.mapboxgl-popup-tip:after {
  content:'';
  width: 0; 
  height: 0; 
  position:absolute;
}

.mapboxgl-popup-content {
  padding: $spacing-3 $spacing-5 $spacing-3 $spacing-3 !important;
  width: 100%;
  border-radius: toRem(10px) !important;
  background-color: $bg-blue !important;
  border: 1px solid $dark-blue;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;

}

.mapboxgl-popup-content:after, .mapboxgl-popup-content:before {
  display: block;
  position: absolute;
  left: 100%;
  width: 0;
  height: 0;
  border-style: solid;

}

.mapboxgl-popup-close-button {
    background-image: url(../../assets/closeButton.svg);
    width: $spacing-3;
    height: $spacing-3;
    background-repeat: no-repeat;
    background-position: center;
    margin: toRem(19px) toRem(19px) 0 0;
    color: transparent;
    
    &:hover {
      background-color: $bg-blue !important;
    }

    &:focus-visible {
      outline: none !important;
    }
}

.mapboxgl-control-container{
    display: none;

    @media #{$bp-s} {
        display: block;
    }
}

.mapboxgl-ctrl-group{
  border-radius: 4px !important;
  margin: toRem(24px) toRem(40px) 0 0 !important;
  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25));
  box-shadow: none !important;
}

.mapboxgl-ctrl-zoom-in{
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.mapboxgl-ctrl-zoom-out{
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.mapboxgl-accessibility-marker {
  background: transparent;
  margin: 0;
  padding: 0;
  appearance: none;
  border-radius: 0;
  border: none;
  position: fixed;
}

.mapboxgl-accessibility-marker:focus {
  border: 2px solid $black;
  width: 42px;
  height: 63px;
  top: 48px;
}

.zn-interactive-map {

  &__map-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    right: 0;
    z-index: 2;
    background: none;
    background-color: $white;
    color: inherit;
    border: none;
    border-radius: toRem(3px);
    padding: $spacing-1 $spacing-3;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25));

    &--with-padding {
      margin-right: $spacing-2;
    }

    &:focus {
      background-color: $bg-blue;
    }
  }

  &__map-button:hover {
    cursor: pointer;
  }

  &__map-buttons {
    position: absolute;
    display: none;
    margin: toRem(24px) toRem(78px) 0 0;
    right: 0;

    @media #{$bp-s} {
      display: flex;
      flex-direction: row;

    }

    &:focus {
      border: none;
    }
  }

  &__button-text {
    @include Body-Regular();
    margin-right: $spacing-2;
    color: $black;

    &--toggle {
      margin-right: 0;
    }
  }

  &__loading-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: $bg-blue; 
    opacity: 0.5;
    z-index: 3;

  }

  &__loading-animation {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 50%;
    margin-left: -44px;
    width: toRem(88px);
    height: toRem(55px);
    padding-top: $spacing-2;
    background: $white;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: $spacing-2;
    z-index: 4;
    margin-top: $spacing-4;
  }
}

