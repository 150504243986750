@import '../../styles/main.scss';

$column-total: 12;

.zn-statistics {
    display: flex;
    flex-direction: column;
    padding: $spacing-3 0;

    @media #{$bp-m} {
        flex-direction: row;
        padding: $spacing-5 0;
    }

    &__left-col {
        max-width: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;

        @media #{$bp-m} {
            width: #{(6 * 100 / $column-total) + '%'};
            max-width: #{(6 * 100 / $column-total) + '%'};
        }
        @media print {
            //max-width: 4.01in;
            padding-right: 1.2in;
        }
    }

    &__right-col {
        display: flex;
        flex-direction: column;
        max-width: 100%;
        width: 100%;

        @media #{$bp-m} {
            width: #{(6 * 100 / $column-total) + '%'};
            max-width: #{(6 * 100 / $column-total) + '%'};
            justify-content: center;
            align-items: flex-end;
        }

        @media print {
            max-width: 3.646in;
        }
    }

    &__right-col-container {
        display: flex;
        flex-direction: column;
        max-width: 100%;
        width: 100%;

        @media #{$bp-m} {
            width: #{(5 * 100 / 6) + '%'};
            max-width: #{(5 * 100 / 6) + '%'};
        }
    }

    &__title {
        @include Display-2();
        color: $black;
        margin-bottom: $spacing-5;

        @media #{$bp-m} {
            line-height: 1.22;
            margin-bottom: toRem(40px);
        }

        a {
            color: $torys-blue;
            font-style: italic;
        }
    }

    &__subtitle-container {
        display: flex;
        flex-direction: column;
        margin-bottom: $spacing-3;

        @media #{$bp-m} {
            flex-direction: row;
            margin-bottom: 0;
        }

        @media print {
            flex-direction: row;
            margin-bottom: 0;
            justify-content: space-between;
        }
    }

    &__subtitle {
        padding-bottom: toRem(28px);
        @media #{$bp-m} {
            max-width: 50%;
            padding-right: $spacing-4;
            padding: 0 $spacing-4 0 0;
        }

        @media print {
            max-width: 48%;
        }
    }

    &__subtitle-header {
        @include Body-Medium();
        color: $black;
        font-weight: 500;
        line-height: toRem(24px);
        padding-bottom: $spacing-2;

        @media #{$bp-s} {
            line-height: toRem(24px);
        }

        @media print {
            font-weight: bold;
            font-size: 0.142in;
        }
    }

    &__subtitle-description {
        @include Body-Regular();
        color: $darker-grey;
        padding-bottom: toRem(12px);
        @media print {
            font-size: 0.13in;
        }
    }

    &__statistic {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &--primary {
            padding-bottom: toRem(23px);
            border-bottom: 1px solid $light-grey;
            width: 100%;

            @media #{$bp-m} {
                padding-bottom: $spacing-5;
            }
            @media print {
                border-bottom: 1px solid rgba($light-grey, 0.6);
            }
        }

        &--secondary {
            padding: toRem(30px) $spacing-3;
            display: flex;
            flex: 1;
            justify-content: flex-start;
            flex-basis: 49%;

            @media #{$bp-m} {
                padding: toRem(50px) $spacing-5 $spacing-5;
            }

            @media print {
                padding-top: 0.4in;
                padding-bottom: 0.3in;
            }
        }

        &--secondary:first-child {
            margin-right: auto;
        }

        &--secondary:last-child {
            margin-left: auto;
        }
    }

    &__statistic-value {
        font-family: $heading-font-family;
        color: $torys-blue;
        font-style: normal;
        font-weight: normal;
        opacity: 1;
        text-align: center;

        @media #{$bp-m} {
            &--animation {
                opacity: 0;
                transform: translate3d(0, 24px, 0);
                transition: transform 0.75s ease, opacity 0.75s ease;
            }
        }

        &--primary {
            font-size: toRem(76px);
            line-height: toRem(98px);

            @media #{$bp-m} {
                line-height: toRem(128px);
                font-size: toRem(118px);
            }
        }

        &--secondary {
            font-size: toRem(61px);
            line-height: toRem(83px);

            @media #{$bp-m} {
                font-size: toRem(96px);
                line-height: toRem(128px);
                transition-delay: 0.5s;
            }

            &-small {
                font-size: toRem(44px);
            }
            @media print {
                font-size: 0.34in;
            }
        }

        &--sc-editing {
            font-size: toRem(55px);
        }
    }

    &__statistic-value-label {
        @include Display-6();
        opacity: 1;
        text-align: center;

        @media #{$bp-m} {
            &--animation {
                opacity: 0;
                transition: opacity 0.73s ease;
                transition-delay: 0.8s;
            }

            &--primary {
                transition-delay: 0.5s;
            }
        }

        @media print {
            font-weight: bold;
            font-size: 0.13in;
        }
    }

    &__statistic-container {
        display: flex;
        flex-direction: row;
        width: 100%;
    }

    &__statistic-divider {
        width: 1px;
        background-color: $light-grey;
        &--horizontal {
            width: 100%;
            height: 1px;
        }

        @media print {
            background-color: rgba($light-grey, 0.6);
        }
    }

    .zn-button--tertiary {
        padding: 0;
    }

    .fadeIn {
        .zn-statistics__statistic-value {
            opacity: 1;
            transform: translate3d(0, 0, 0);
        }
        .zn-statistics__statistic-value--primary {
            opacity: 1;
            transform: translate3d(0, 0, 0);
        }
        .zn-statistics__statistic-value-label {
            opacity: 1;
        }
    }
    @media print {
        flex-direction: row;
        align-items: center;
        page-break-inside: avoid;
    }
}
