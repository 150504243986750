@import '../../styles/main.scss';

.zn-search-form {
    position: relative;

    .zn-search-form__input {
        @include Display-4-Secondary();
        background-color: transparent;
        border: none;
        border-bottom: 1px solid $dark-grey;
        font-size: toRem(24px);
        height: toRem(64px);
        padding: 0 toRem(38px);
        padding-left: 38px;
        width: 100%;
        border-radius: 0;
        -webkit-appearance: none;
        -webkit-border-radius: 0;

        &:focus {
            outline: none;
        }
    }

    .zn-search-form__search-button {
        position: absolute;
        left: 0;
        top: toRem(22px);
        padding: 0;
        height: toRem(20px);
        width: toRem(20px);
    }

    .zn-search-form__close-button {
        position: absolute;
        right: 0;
        top: $spacing-3;
    }

    &__suggested-results-container {
        position: relative;
    }

    &__suggested-results {
        list-style-type: none;
        margin: $spacing-2 0 0 0;
        padding: $spacing-4;
        position: absolute;
        z-index: 3;
        background-color: $white;
        border: 1px solid $light-grey;
        width: 100%;
        display: none;

        @media #{$bp-m} {
            padding: $spacing-5 $spacing-6;
            z-index: 4;
            display: block;
        }
    }

    &__suggested-results-item {
        margin-bottom: $spacing-3;
    }

    &__suggested-results-item:last-child {
        margin-bottom: 0;
    }
}

.zn-search-form--dark {
    color: $white;
    .zn-search-form__input {
        border-color: $white;
        color: $white;
        &:placeholder {
            color: $light-grey;
        }
    }
}

.zn-search-form__wrapper {
    display: none;
    @media #{$bp-m} {
        display: block;
        width: 100%;
        max-width: toRem(980px);
        margin: 0 $spacing-4;
    }
}