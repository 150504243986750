@import '../../styles/main.scss';

.zn-quarterly-animation-section {
    position: relative;
    height: 92vh;

    &--editing {
        height: inherit;
    }
    
    &__background-image {
        background-position: center;
        position: absolute;
        z-index: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-size: cover;

        &--editing {
            position: relative;
        }
    }
}