@import '../../styles/main.scss';

.zn-news-card {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    margin-bottom: 2rem;
    @media #{$bp-m} {
        margin-bottom: 0;
    } 
    a, a:hover, a:focus, a:active {
        text-decoration: none;
    }
    &__image-link {
        width: 100%;
        max-width: 100%;
        height: auto;
    }
    &__image {
        width: 100%;
        height: 100%;
    }
    &__date {
        @include All-Caps-Small();
        line-height: 1rem;
        font-weight: 400;
        color: $darker-grey;
        margin: $spacing-4 0 $spacing-2 0;
    }
    &__title-link {
        &:hover {
            text-decoration: none;
            color: inherit;
        }
    }
    &__image-link + &__title-link {
        margin-top: toRem(47px);
    }
    &__title {
        @include Display-4-Secondary();
        color: $black;
        width: 100%;
        padding-bottom: 0;
    }
    &__description {
        @include Body-Regular();
        padding-top: $spacing-3;
        color: $darker-grey;

        &--insight {
            position: relative;
            div { 
                * {
                    display: none;
                }
            }
            div > :first-child {
                display: block;
                max-height: toRem(130px);
                text-overflow: ellipsis;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 5;
                -webkit-box-orient: vertical;
               * {
                   display: block;
               }
            }
        }
    }

    .zn-button {
        @include Button-Medium();
        padding: 0;
        margin-top: toRem(12px);
    }
}