@import '../../styles/main.scss';

.zn-rich-text {
    @include Body-Medium();

    &__wrapper {
        width: 100%;

        &--highlight {
            background-color: $white;
        }

        .zn-ExpertiseRichText {
            @include Body-Medium();
            color: $torys-blue;

            ul {
                display: flex;
                flex-wrap: wrap;
            }

            li {
                margin: 0;
                padding: toRem(24px) 0 toRem(24px) 0;
                margin-bottom: toRem(8px);
                list-style: none;
                border-bottom: 1px solid $light-grey-45;
                width: 100%;

                &::before {
                    content: unset;
                }

                @media #{$bp-s} {
                    min-width: calc(50% - 12px);
                    max-width: calc(50% - 12px);
                    line-height: 1.6;

                    &:nth-child(odd) {
                        margin-right: toRem(12px);
                    }
                    &:nth-child(even) {
                        margin-left: toRem(12px);
                    }
                }
            }
        }

        .zn-RecognitionRichText {
            table {
                margin: 0;
            }

            tr:not(:last-child) {
                border-bottom: 1px solid $light-grey-45;

                @media print {
                    border-bottom: 1px solid $light-grey-45;
                }
            }

            td {
                padding: toRem(16px) toRem(8px) toRem(16px) 0;
                border: none;

                @media #{$bp-s} {
                    padding-right: toRem(16px);
                }

                &:nth-child(1) {
                    @include All-Caps-Small();
                    font-weight: normal;
                    color: $darker-grey;
                    vertical-align: middle;
                    width: 40%;

                    @media #{$bp-s} {
                        line-height: 1.34;
                        letter-spacing: 1.5px;
                        width: auto;
                    }

                    @media print {
                        width: 100px;
                    }
                }

                &:nth-child(2) {
                    @include Body-Medium();
                    color: $black;
                    padding-right: none;

                    @media #{$bp-s} {
                        line-height: 1.5;
                    }
                }
            }
        }
    }

    &--highlight {
        border-left-width: 4px;
        border-left-style: solid;
        padding: $spacing-5 0 $spacing-3 $spacing-3;

        &-orange {
            border-color: $orange-45;
            background: $torys-orange-transparent;
        }

        &-blue {
            border-color: $torys-blue;
            background: $torys-blue-transparent;
        }

        &-teal {
            border-color: $teal;
            background: $torys-teal-transparent;
        }

        &-grey {
            border-color: $light-grey;
            background: $torys-grey-transparent;
        }

        &.zn-rich-text {
            &--light,
            &--dark {
                ul li:before {
                    color: $primary-black;
                }
            }
        }
    }

    &--dark {
        color: $white;

        a {
            color: inherit;
        }

        a:hover {
            color: $bg-blue;
        }

        ul li:before {
            color: $light-grey-45;
        }
    }

    &--light {
        color: $darker-grey;

        a {
            color: $torys-blue;
        }

        a:hover {
            color: $torys-dark-blue;
        }

        ul li:before {
            color: $torys-blue;
        }
    }

    p {
        @include Body-Medium();
        padding-bottom: $spacing-3;

        @media print {
            line-height: 1.3;
            font-size: 0.172in;
            margin-bottom: 0in;
        }
    }

    &--small,
    &--small * {
        @include Body-Regular();
    }

    &--medium,
    &--medium * {
        @include Body-Medium();
    }

    &--large,
    &--large * {
        @include Body-Large();
    }

    h1 {
        @include Display-1();
        padding-bottom: $spacing-5;
    }

    h2 {
        @include Display-2();
        padding-bottom: $spacing-4;
    }

    h3 {
        @include Display-3();
        padding-top: $spacing-4;
        padding-bottom: $spacing-4;
    }

    h4 {
        @include Display-4();
        padding-bottom: $spacing-3;
    }

    h5 {
        @include Display-5();
        padding-bottom: $spacing-3;
    }

    h6 {
        @include Display-6();
        padding-bottom: $spacing-3;
    }

    &--large {
        p {
            @include Body-Large();
            padding-bottom: $spacing-3;
        }
    }

    a {
        text-decoration: underline;
    }

    ul {
        list-style-type: disc;
        padding-left: $spacing-3;

        * > :first-child {
            margin-top: $spacing-3;
        }

        & > li {
            list-style: none;
            &:before {
                content: '\2022';
                display: inline-block;
                width: toRem(16px);
                margin-left: toRem(-16px);
            }
        }
    }

    li {
        @include body-base;
        margin-bottom: $spacing-3;
    }

    table {
        margin: toRem(40px) 0;
        border-collapse: collapse;
        display: block;
        position: relative;

        @media #{$bp-m} {
            display: table;
            width: 100%;
        }

        &.mobile {
            display: table;
            width: 100%;
        }

        ul {
            margin: 0;
            list-style-position: inside;

            li::marker {
                margin: 0;
            }
        }
    }

    thead {
        position: relative;
        display: table;
        width: inherit;
        padding: 0 auto;
        min-width: 100%;
    }

    tbody {
        position: relative;
        overflow-x: auto;
        display: block;
        width: inherit;

        @media #{$bp-m} {
            display: table;
            min-width: auto;
        }

        &.mobile {
            display: table;
        }
    }

    td,
    th {
        border: 1px solid $darker-grey;
    }

    .titleRow {
        @include Display-5();
        color: $white;
        background-color: $dark-blue;
        padding: $spacing-2 $spacing-2 $spacing-2;
        font-weight: 500;
        text-align: center;

        @media #{$bp-m} {
            padding: $spacing-3 0 toRem(12px);
            text-align: center;
        }
    }

    .headingCell {
        @include Body-Large();
        color: $dark-blue;
        background-color: $torys-blue-transparent;
        padding: $spacing-1 $spacing-2;
        text-align: left;
        font-weight: 500;

        @media #{$bp-m} {
            padding: $spacing-4 $spacing-4 toRem(20px);
        }
    }

    td {
        @include Body-Medium();
        color: $dark-blue;
        padding: $spacing-2;
        vertical-align: top;
        white-space: normal;
        word-wrap: break-word;

        @media #{$bp-m} {
            vertical-align: top;
            padding: $spacing-3 $spacing-4 toRem(12px);
            min-width: 0 !important;
        }

        &.mobile {
            min-width: 0 !important;
        }
    }

    img {
        max-width: 100%;
    }
}
