@import '../../styles/main.scss';

.zn-icon-dropdown {
    position: relative;
    padding: 0;
    display: inline-block;
    align-self: flex-start;
    
    &__popup {
        height: max-content;
        position: absolute;
        display: flex;
        align-items: center;
        flex-direction: row;
        right: 100%;
        max-height: toRem(304px);
        z-index: 1;

        &--right-mobile {
            left: 100%;
            right: auto;
    
            @media #{$bp-m}{ 
                right: 100%;
                left: auto;
            }
        }

        &--sc-editing {
            position: unset;
        }
    }

    &__popup:focus {
        outline: 0;   
    }

    &__modal {
        overflow: hidden;
        background-color: $white; 
        padding: $spacing-3;
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1), 0px 1px 4px rgba(0, 0, 0, 0.1);

        &--dark {
            background-color: $dark-blue;
        }

        &--hidden {
            visibility: hidden;
        }
    }

    &__modal-triangle {
        width: $spacing-3;
        height: $spacing-3;
        overflow: hidden;
        top: $spacing-1;
        position: absolute;


        &--right-mobile {
            right: 0;

            @media #{$bp-m}{ 
                right: auto;
            }
        }

        &::after {
            content: "";
            position: absolute;
            width: $spacing-2;
            height: $spacing-2;
            top: $spacing-1;
            left: -$spacing-1;
            background: $white;
            transform: rotate(45deg);
            box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1), 0px 1px 4px rgba(0, 0, 0, 0.1);
        }

        &--dark::after {
            background: $dark-blue;
        }

        &--right-mobile::after {
            left: toRem(12px);
            
            @media #{$bp-m} { 
                left: -$spacing-1;
            }
        }
    }

    &__icon {
        padding: 0;
        margin: 0;

        svg {
            display: block;
            stroke-width: 1.5px;
        }
    }

    &__button {
        background-color: transparent;
        border: none;
        box-shadow: none;
        cursor: pointer;
        display: flex;
        align-items: center;
        position: relative;
        padding: 0; 
        margin-left: $spacing-3; 
        color: $torys-blue;

        &--dark {
            color: $white;
        }

        &--right-mobile {
            margin-left: 0;
            margin-right: $spacing-3;

            @media #{$bp-m}{
                margin-left: $spacing-3;
                margin-right: 0;
            }
        }
    }

    &__button > * {
        pointer-events: none;
    }

    &--sc-editing {
        margin-left: 300px;
        display: flex;
        flex-direction: row;
    }
}