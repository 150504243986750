@import '../../styles/main.scss';

.zn-footnotes {
    width: 100%;
    
    &__banner {
        display: flex;
        flex-direction: row;
        align-items: center;
        border: none;
        outline: none;
        cursor: pointer;
        padding: $spacing-2 0;
        margin-bottom: $spacing-2;
        background-color: $white;
    }

    &__title {
        @include All-Caps();
        color: $black;
        margin-right: $spacing-2;
    }

    &__icon {
        color: $torys-blue;
        display: inline-block;
    }

    &__content {
        overflow: hidden;
        transition: max-height 0.6s ease;

        ol {
            counter-reset: li-count;
            margin: 0;
            padding: 0;
            list-style: none;
        }
    }

     &__editor {
         @include Body-Medium();
     }
}