@import '../../styles/main.scss';

.zn-context-link-list {
    width: 100%;

    .zn-article-heading--medium {
        font-weight: 400;
    }
    
    .zn-link-list {

        .zn-link-list-item {

            .zn-link--Hyperlink {
                color: $torys-blue;
                text-decoration-line: underline;

                &:hover {
                    color: $torys-dark-blue;
                }
            }
        }
    }
}