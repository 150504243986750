@import '../../styles/main.scss';

.zn-accordion {
    width: 100%;

    &__info {
        display: flex;
        flex-direction: row;
        align-items: center;
        border: none;
        outline: none;
        width: 100%;
        cursor: pointer;
        justify-content: space-between;
        padding: 0;
        background-color: transparent;
        transition: margin 0.6s ease;

        &--active {
            margin-bottom: $spacing-3;
        }
    }

    &__title {
        @include Display-5();
        color: $black;
        text-align: left;
        word-break: break-word;

        &--navy {
            color: $dark-blue;
        }
    }

    &__icon {
        color: $torys-blue;
        display: inline-block;
    }

    &__accent {
        width: 100%;
        height: toRem(1px);
        background-color: $light-grey;
        margin: $spacing-4 0;
    }

    &__content {
        overflow: hidden;
        transition: max-height 0.6s ease;
    }

    &__editor {
        @include Body-Medium();
    }
}
