@import '../../styles/main.scss';

.zn-knowledge-hub {
    &-date &-date__link a:hover {
        text-decoration: underline;
    }
    &-hero {
        background-color: $dark-blue;
        color: $white;
        font-weight: 400;
        width: 100%;
        display: flex;
        justify-content: center;
        padding-top: toRem(28px);
        padding-bottom: toRem(28px);
        padding-left: $spacing-3;
        padding-right: $spacing-3;

        @media #{$bp-s} {
            padding-top: $spacing-6 toRem(74px);
        }
        @media #{$bp-m} {
            padding: $spacing-6 responsive-spacing($screen-m, $screen-xxl, 74px, 165px);
        }
        @media #{$bp-xxl} {
            padding: $spacing-6 toRem(165px);
        }
        &__container {
            max-width: toRem($section-content-max-width);
            width: 100%;
        }
        a {
            color: $white;
            text-decoration: none;

            &:hover {
                color: $white;
            }
        }

        &__content {
            display: flex;
            width: 100%;
            flex-direction: column;
            margin-top: toRem(40px);

            @media #{$bp-s} {
                margin-top: toRem(64px);
                flex-direction: row;
            }
        }

        &__left {
            width: 100%;
            color: $white;
            text-decoration: none;

            @media #{$bp-s} {
                width: 60%;
                margin-right: $spacing-5;
            }
        }

        a:hover {
            cursor: pointer;
        }
        &__title {
            @include Display-1();

            @media #{$bp-s} {
                @include heading-base;
                line-height: toRem(85px);
                font-size: 72px;
            }
        }

        &__accent {
            width: toRem(80px);
            height: toRem(5px);
            margin: $spacing-3 0;

            @media #{$bp-s} {
                margin: $spacing-5 0;
            }

            &--orange {
                background-color: $orange;
            }
            &--teal {
                background-color: $teal;
            }
            &--blue {
                background-color: $torys-blue;
            }
            &--navy {
                background-color: $dark-blue;
            }
        }

        &__subtitle {
            @include Display-4-Secondary();
        }

        &__image {
            width: 100%;
            margin-bottom: $spacing-4;
            overflow: hidden;
            @media #{$bp-s} {
                margin-bottom: $spacing-5;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
                transition: transform 0.3s ease;

                &:hover {
                    transform: scale(1.1);
                }
            }
        }
        &__featured-page {
            margin-left: 0;
            margin-right: 0;
            margin-bottom: toRem(40px);

            @media #{$bp-s} {
                margin-left: $spacing-4;
                margin-right: $spacing-4;
                margin-bottom: 0;
            }
        }

        &__content-style {
            @include All-Caps-Small();
            margin-bottom: $spacing-3;
            display: flex;
            align-items: center;
        }

        &__content-icon {
            color: $teal;
            display: flex;
            align-items: center;
            margin-right: $spacing-2;
        }

        &__featured-page-eyebrow {
            @include Display-4();
            margin-bottom: $spacing-3;

            &:hover {
                text-decoration: underline;
            }
        }

        &__featured-page-description {
            @include Body-Regular();
            margin-bottom: $spacing-4;

            @media #{$bp-s} {
                margin-bottom: toRem(68px);
            }
        }

        &__article-footer {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        &__article-date {
            @include All-Caps-Small();
        }

        &__arrow-link {
            color: $torys-blue;
            //transition: all 0.3s;

            color: $teal;

            @media #{$bp-s} {
                margin-right: toRem(10px);
            }
            &:hover {
                transform: translateX(0.5rem);
                transition: color 0.2s ease, transform 0.2s;
            }
        }

        &__right {
            width: 100%;
            height: auto;

            @media #{$bp-s} {
                width: 40%;
            }
        }

        &__links-1 {
            margin-bottom: $spacing-4;
        }

        &__links-title {
            @include All-Caps();
            color: $white;
            text-decoration: none;
            display: block;
            text-align: center;
            width: 100%;
            padding: $spacing-2;
            background-color: $torys-blue;
        }

        @include pdf-hero();
    }
}
