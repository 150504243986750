@import '../../styles/main.scss';

.zn-expertise-page-links-item {
    list-style: none;

    .zn-button {
        @include Body-Medium();
        font-weight: 400;
        flex-direction: row-reverse;
        padding-left: 0;
        padding-right: 0;

        &__icon {
            margin-left: 0;
            margin-right: toRem(10px);
        }
    }
}