@import '../../styles/main.scss';

.zn-mega-menu-secondary {
    display: flex;
    flex-direction: column;
    -webkit-animation: fadeIn $transition-ease;
    animation: fadeIn $transition-ease;
    
    .zn-mega-menu-secondary__list {
        padding: 0;
    }

    .zn-mega-menu-secondary__list__item {
        list-style: none;
    }
}
.zn-mega-menu-secondary__body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media #{$bp-m} {
        flex-direction: row;
    }
}

.zn-mega-menu-secondary__body__column {
    .zn-mega-menu-secondary__title {
        @include Nav-Heading();
        @media #{$bp-m} {
            margin-bottom: toRem(40px);
        }
    }
}

.zn-mega-menu-secondary__body__main-column {
    @media #{$bp-m} {
        min-width: 60%;
        padding-right: $spacing-2;
        width: 60%;
    }

    .zn-mega-menu-secondary__list {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        flex-direction: column;
        @media #{$bp-m} {
            flex-direction: row;
        }
    }

    .zn-mega-menu-secondary__list__item {
        margin-bottom: $spacing-4;
        @media #{$bp-m} {
            min-width: 50%;
            width: 50%;
        }
    }
}

.zn-mega-menu-secondary__body__secondary-column {
    .zn-link-list__item {
        padding: 0 0 0 $spacing-3;
        line-height: toRem(27px);
        .zn-button__icon {
            display: none;
            line-height: toRem(27px);
        }
    }
}
