@import '../../styles/main.scss';

.zn-bio-education {
    width: 100%;
    .zn-article-heading {
        font-weight: 400;
    }
    .zn-link-list--two-columns {
        display: flex;
        flex-wrap: wrap;

        .zn-link-list-item {
            min-width: 100%;
            justify-content: flex-start;
            @media #{$bp-s} {
                min-width: calc(50% - 12px);
                max-width: calc(50% - 12px);
                margin-bottom: $spacing-2;

                &:nth-child(odd) {
                    margin-right: toRem(12px);
                }
                &:nth-child(even) {
                    justify-content: flex-end;
                    margin-left: toRem(12px);
                }
                &--justify-space-between {
                    &:nth-child(even) {
                        display: flex;
                        justify-content: flex-end;
                    }
                }
            }
            &:first-child {
                border-top: none;
            }
        }
    }

    @media print {
        margin-top: 0.24in;

        .zn-link-list-item__pdf {
            display: block;
            min-width: calc(50% - 0.1in) !important;
            max-width: calc(50% - 0.1in) !important;
            text-decoration: none;
            margin-bottom: 0.12in;
            &:nth-child(even) {
                text-align: right;
            }
        }
    }
}
