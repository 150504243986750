@import '../../styles/main.scss';

.zn-video {
    max-width: 100%;
    width: 100%;
    height: 100%;

    &--dark {
        .zn-video__caption {
            color: $white;
        }
    }

    &__media {
        position: relative;
        padding-bottom: 56.25%; // 16 : 9 aspect ratio
        height: 0;
  
        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    &__caption {
        @include Body-Regular();
        margin: $spacing-3 0 toRem(9px) 0; 
        color: $dark-grey;
    }
}
