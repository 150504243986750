@import '../../styles/main.scss';

.zn-expertise-page-links {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: $spacing-4 $spacing-3;

    @media #{$bp-s} {
        width: 50%;
    }

    &__title {
        width: 100%;
        margin-bottom: $spacing-3;

        .zn-button {
            @include Display-4();
            width: 100%;
            justify-content: space-between;
            padding: 0;

            @media #{$bp-s} {
                width: auto;
                justify-content: center;
            }
        }
    }

    &__description {
        @include Body-Regular();
        margin-bottom: toRem(12px);
    }

    &__dropdown {

        &-CTA {
            display: flex;
            flex-direction: row;
            align-items: center;
            border: none;
            outline: none;
            cursor: pointer;
            background-color: inherit;
            padding: 0;
        }

        &-title {
            @include Display-6();
            margin-right: $spacing-2;

            &--open {
                margin-top: $spacing-1;
            }
        }

        &-icon {
            color: $torys-blue;
            display: inline-block;
            margin-top: $spacing-1;
        }

        &-links {
            overflow: hidden;
            transition: max-height 0.6s ease;

            ol {
                margin: $spacing-2 0;
                padding-inline-start: 0;
            }
        }
    }

}