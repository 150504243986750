@import '../../styles/main.scss';

.zn-button {
    @include Button-Link();
    border-radius: 0.125rem;
    border-style: none;
    line-height: 1.55;
    padding: $spacing-2 $spacing-5;
    text-align: center;
    text-decoration: none;
    width: 100%;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    transition: $transition-ease;

    @media #{$bp-s} {
        width: initial;
    }

    &__icon {
        display: flex;
        align-items: center;
        margin-left: toRem(13.5px);
        @media print {
            display: none;
        }
    }

    &--primary {
        border-color: $torys-blue;
        background-color: $torys-blue;
        color: $white;
        border-style: none;
        border-style: solid;

        &--dark {
            border-color: $white;
            background-color: $white;
            color: $torys-blue;

            &:hover {
                border-color: $white;
                background-color: transparent;
                color: $white;
                transition: $transition-ease;
            }
        }

        &--light {
            border-color: $torys-blue;
            background-color: $torys-blue;
            color: $white;

            &:hover {
                border-color: $torys-blue;
                background-color: transparent;
                color: $torys-blue;
                transition: $transition-ease;
            }
        }
    }

    &--secondary {
        background-color: $black;
        color: $white;
        border-style: solid;

        &--dark {
            border-color: $white;
            background-color: transparent;
            color: $white;

            &:hover {
                border-color: $white;
                background-color: $white;
                color: $torys-blue;
                transition: $transition-ease;
            }
        }

        &--light {
            border-color: $torys-blue;
            background-color: transparent;
            color: $torys-blue;

            &:hover {
                border-color: $torys-blue;
                background-color: $torys-blue;
                color: $white;
                transition: $transition-ease;
            }
        }

        @media print {
            border-color: $torys-blue;
            color: $torys-blue;
            background-color: $white;
        }
    }

    &--tertiary {
        padding: $spacing-2;
        padding-left: 0;
        margin: 0;
        width: initial;
        text-align: left;
        justify-content: flex-start;

        &--dark {
            color: $white;

            &__icon {
                margin-left: toRem(6px);
                color: $white;
                transition: all 0.3s;
            }

            &:hover {
                color: $bg-blue;
            }

            &:hover & {
                &__icon {
                    color: $bg-blue;
                    transform: translateX(0.5rem);
                    transition: color 0.2s ease, transform 0.2s;
                }
            }
        }

        &--light {
            color: $black;

            &__icon {
                margin-left: toRem(6px);
                color: $torys-blue;
                transition: all 0.3s;
            }

            &:hover {
                color: $torys-blue;
            }

            &:hover & {
                &__icon {
                    transform: translateX(0.5rem);
                    transition: color 0.2s ease, transform 0.2s;
                }
            }
        }
    }

    &:hover {
        text-decoration: none;
    }

    &--mb-small {
        margin-bottom: $spacing-3;
    }

    &--mb-medium {
        margin-bottom: $spacing-5;
    }

    &--mb-large {
        margin-bottom: $spacing-6;
    }

    &--mt-small {
        margin-top: $spacing-3;
    }

    &--mt-medium {
        margin-top: $spacing-5;
    }

    &--mt-large {
        margin-top: $spacing-6;
    }

    &--pdf-disabled {
        @media print {
            display: none;
        }
    }
    @media print {
        max-width: 1.9in;
        padding-left: 0;
        padding-right: 0;
        text-align: center;
        font-size: 0.205in;
        text-decoration: none !important;
    }
}
