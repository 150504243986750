@import '../../styles/main.scss';

.zn-link-list {
    margin: $spacing-3 0;
    padding: 0;
    width: 100%;

    .zn-link-list-item {
        list-style: none;
    }
    .zn-article-heading + & {
        margin-top: $spacing-2;
    }

    @media print {
        margin-top: 0;
    }
}

.zn-link-list--two-columns {
    @media #{$bp-s} {
        display: flex;
        flex-wrap: wrap;
        .zn-link-list-item {
            min-width: calc(50% - 12px);
            max-width: calc(50% - 12px);
            &:nth-child(odd) {
                margin-right: toRem(12px);
            }
            &:nth-child(even) {
                margin-left: toRem(12px);
            }
            &--justify-space-between {
                &:nth-child(even) {
                    display: flex;
                    justify-content: flex-end;
                }
            }
            &:first-child {
                border-top: none;
            }
        }
    }
}

.zn-link-list--two-columns-space-between {
    .zn-link-list-item {
        &:nth-child(even) {
            display: flex;
            justify-content: flex-end;
            text-align: end;
        }
    }
}

.zn-colored-list {
    margin-bottom: $spacing-5;
}
