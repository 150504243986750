
@import '../../styles/main.scss';

$scroll-triggered-down-delay: 480ms;
$scroll-triggered-up-delay: 120ms;
$scroll-triggered-duration: 540ms;

.zn-quarterly-introduction-animation {
    position: absolute;
    top: 100%;
    width: 100%;
    height: 100%;

    &.scroll-down-animation {
        top: 100%;
        animation-name: scroll-fade-in-intro;
        animation-play-state: running;
        animation-fill-mode: forwards;
        animation-duration: $scroll-triggered-duration;
        animation-delay: $scroll-triggered-down-delay;
    }

    &.scroll-up-animation {
        top: 0%;
        animation-name: scroll-fade-out-intro;
        animation-play-state: running;
        animation-fill-mode: forwards;
        animation-duration: $scroll-triggered-duration;
        animation-delay: $scroll-triggered-up-delay;
    }

    @keyframes scroll-fade-in-intro {
        0% {
            top: 100%;
        }
        100% { 
            top: 0;
        }
    }

    @keyframes scroll-fade-out-intro {
        0% {
            top: 0;
        }
        100% { 
            top: 100%;
        }
    }

    &--editing {
        position: relative;
    }
}