@import '../../styles/main.scss';

.zn-advanced-filter-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    max-width: toRem(821px);
    width: toRem(821px);
    transform: translate(-50%, -50%);
    background-color: $white;
    z-index: 10; 
    border-radius: $spacing-3;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &__header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: toRem(18px) toRem(53px);
        border-bottom: 1px solid $light-grey;
        margin-bottom: toRem(40px);
    }

    &__title {
        @include Display-6();
        color: $black;
    }

    &__close-button {
        position: absolute;
        right: 0;
        border: none;
        padding: 2px;
        margin: 0 toRem(50px) 0 0;
        outline: none;
        background-color: transparent;

        svg {
            display: block;
        }

        &:focus {
            border: 1px solid $torys-blue;
            padding: 1px;
        }
    }

    &__filters {
        max-height: toRem(516px);
        overflow-y: auto;
        padding: 0 toRem(40px);
    }

    &__footer {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        padding: $spacing-3 toRem(40px);
        border-top: 1px solid $light-grey;
        margin-top: $spacing-5;
    }

    &__cancel {
        margin-right: $spacing-5;
    }
}