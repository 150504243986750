@import '../../styles/main.scss';

.zn-image {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    align-items: center;

    h6 {
        color: $darker-grey;
    }

    &__media {
        align-items: center;
        display: flex;
        justify-content: center;
        width: 100%;
        height: auto;

        img {
            max-width: 100%;
            width: 100%;
            height: 100%;
        }
    }

    &__caption {
        @include Body-Regular();
        margin: $spacing-3 0 toRem(9px) 0;
        color: $dark-grey;
    }

    &--hide-on-print {
        @media print {
            display: none;
        }
    }

    &--fill-space {
        height: 100%;

        .zn-image {
            &__media {
                height: 100%;

                img {
                    object-fit: cover;

                    @media #{$bp-m} {
                        // Makes the image always smaller than the text, but with object-fit, it will grow to
                        // the text height
                        aspect-ratio: 10 / 1;
                    }
                }

                /* 
                    Some browser versions don't support Aspect Ratio, so to leave the image height as tall as the text
                    it had to be used as a background for the div instead of the img tag.
                */
                @supports (aspect-ratio: 10 / 1) {
                    @media #{$bp-m} {
                        // Dont render the image on the div if aspect ratio behaves correctly
                        background: none !important;
                    }
                }

                @supports not (aspect-ratio: 10 / 1) {
                    @media #{$bp-m} {
                        background-repeat: no-repeat;
                        background-size: cover;
                        background-position: center;

                        // Don't render the image component since its size its going to be bigger than expected
                        img {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}
