@import '../../styles/main.scss';

.zn-horizontal-rule {
    width: 100%;
    padding-top: 1rem;
    padding-bottom: 1rem;

    &__line {
        width: 100%;
        border: none;
        height: 1px;

        &--light {
            @extend .zn-horizontal-rule__line;
            background-color: $light-grey;
        }

        &--medium {
            @extend .zn-horizontal-rule__line;
            background-color: $medium-grey;
        }

        &--dark {
            @extend .zn-horizontal-rule__line;
            background-color: $dark-grey;
        }

        @media print {
            display: none !important;
        }
    }

    $sizes: 'none', 'small', 'medium', 'large';
    @for $i from 1 through length($sizes) {
        $size: nth($sizes, $i);
        &--pt-#{$size} {
            padding-top: toRem(16px * ($i - 1));
        }

        &--pb-#{$size} {
            padding-bottom: toRem(16px * ($i - 1));
        }
    }

    @media print {
        display: none !important;
    }
}
