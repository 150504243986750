@import '../../styles/main.scss';

.zn-bio-related-article-list {
    &__description {
        @include Body-Medium();
        color: $darker-grey;
        padding-bottom: $spacing-3;

        @media #{$bp-m} {
            padding-bottom: $spacing-4;
        }
    }

    &__secondary-title {
        @include Body-Large();
        color: $dark-blue;
        padding-top: $spacing-3;

        @media #{$bp-m} {
            padding-top: $spacing-4;
        }
    }

    &__no-results {
        @include Display-5();
        padding: 1rem 0;
        color: $black;
    }

    .zn-link-list-item--divider {
        padding: $spacing-3 0;
    }

    .zn-button:hover {
        cursor: pointer;
    }

    .zn-featured-list__item {
        .zn-featured-list__item__description {
            @include Body-Regular();
            p {
                margin-bottom: 26px;
            }
        }
    }

    .zn-link-list {
        margin: 0 0 $spacing-3 0;

        .zn-link-list-item {
            .zn-link--Hyperlink {
                color: $torys-blue;
                text-decoration-line: underline;

                &:hover {
                    color: $torys-dark-blue;
                }
            }

            &--divider:first-child {
                margin-top: $spacing-3;
            }

            &--eyebrow:first-child {
                margin-top: 0;
            }
        }
    }

    &__desktop-button {
        @media print {
            margin-top: 0.22in;
        }
    }

    &__representative-work {
        @media print {
            margin-bottom: 0.32in;
            margin-top: 0.15in;
        }
    }
    @media print {
        margin-bottom: 0.15in;
    }
}
