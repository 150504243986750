#featured_work {
    background-color: white;

    .zn-button {
        font-weight: normal;
        font-size: 0.182in;
    }

    .zn-heading-accent {
        margin-top: 0.12in;
        height: 0.03125in;
        width: 0.9in;
    }

    .zn-featured-list__item__title {
        margin-bottom: 0.125in;
        font-size: 0.21in;
        h4 {
            font-size: 0.21in;
        }
    }
    .zn-featured-list__item__description {
        margin-bottom: 0.12in;
    }
}
