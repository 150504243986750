@page {
    size: letter !important;
    margin-left: 0;
    margin-right: 0;
    padding: 0;
    margin-top: 0.25in;
    margin-bottom: 0.25in;
}

@media print {
    #recent_recognition {
        .zn-column {
            padding-top: 0;
            margin-top: 0;
        }
    }
    body {
        margin: 0;
        padding-top: 0in !important;
        padding-bottom: 0in !important;
        padding-left: 0.9in !important;
        padding-right: 0.9in !important;
    }
    .readmore-button {
        color: black;
        font-weight: normal;
    }

    .zn-featured-list__item {
        padding: 0 !important;
        margin: 0 !important;
        margin-bottom: 0.22in !important;
        page-break-inside: avoid !important;
    }

    p:empty {
        margin: 0;
        padding: 0;
    }

    #onetrust-consent-sdk {
        display: none;
    }
}
