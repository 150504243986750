@import './variables.scss';

.zn-scroll-padding {
    scroll-padding-top: $navigation-height-mobile;
    @media #{$bp-m} {
        scroll-padding-top: $navigation-height;
    }
}
.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

.zn-flex {
    display: flex;

    &--justify-space-between {
        justify-content: space-between;
    }

    &--align-center {
        align-items: center;
    }

    &--justify-center {
        justify-content: center;
    }

    &--direction-column {
        flex-direction: column;
    }
}

.zn-divider {
    border-color: $light-grey;
}

.zn-list {
    padding: 0;
    .zn-list__item {
        list-style: none;
    }
}
.scEmptyPlaceholder {
    min-width: 100px;
}

.body-nav {
    margin-top: toRem(62px);
    @media #{$bp-m} {
        margin-top: toRem(86px);
    }
    &.nav-open {
        overflow: hidden;
        .zn-projects-map-container__left-container {
            z-index: 1;
        }
    }
    &.page-editing {
        margin-top: 0;
        @media #{$bp-m} {
            margin-top: 0;
        }
    }
    &.quarterly-animation {
        overflow: scroll;
        @media #{$bp-s} {
            overflow-x: hidden;
        }
    }
}

.modal-open {
    overflow: hidden;
}

@-webkit-keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.slide-in {
    animation: slide-in 0.5s forwards;
    -webkit-animation: slide-in 0.5s forwards;
}

.slide-out {
    animation: slide-out 0.5s forwards;
    -webkit-animation: slide-out 0.5s forwards;
}

@keyframes slide-in {
    from {
        transform: translateX(-100%);
        transition-timing-function: ease-in;
    }
    to {
        transform: translateX(0%);
        transition-timing-function: ease-out;
    }
}

@-webkit-keyframes slide-in {
    0% {
        transform: translateX(-100%);
    }
    100% {
        -webkit-transform: translateX(0%);
    }
}

@keyframes slide-out {
    0% {
        transform: translateX(0%);
    }
    100% {
        transform: translateX(-100%);
    }
}

@-webkit-keyframes slide-out {
    0% {
        -webkit-transform: translateX(0%);
    }
    100% {
        -webkit-transform: translateX(-100%);
    }
}

.no-nav {
    margin-top: 0 !important;
}

.zn-page-editing-title {
    color: $torys-blue;
}

.no-animation * {
    animation: none !important;
    transition: none !important;
}

/* TODO: Make this more dynamic */
.is-pdf-view {
    .zn-sticky-side-section {
        display: inherit;
    }
    .zn-sticky-side-section__content {
        display: inherit;
    }
    .zn-sticky-side-section__navigation {
        display: none;
    }
    .zn-sticky-side-section__content .zn-section__content {
        width: 100% !important;
        max-width: 100% !important;
    }
    .zn-section__content {
        display: inherit;
        max-width: 100% !important;
    }
    .zn-profile-hero__icon-bar {
        display: none;
    }
    section:not(.hide-section),
    .zn-sticky-side-banner,
    .zn-sticky-side-banner__content {
        display: block !important;
    }
    .zn-profile-hero__photo-col {
        max-height: 320px;
    }
    .zn-profile-hero__columns {
        flex-direction: row;
    }
    .zn-profile-hero__photo-col {
        justify-content: left;
        width: 50%;
        margin-right: 30px;
    }
    .zn-article-hero-content {
        max-width: 100% !important;
    }
    .zn-contact-card__middle-initial {
        border: 1px solid black;
    }
    .zn-section {
        padding-left: 4rem !important;
        padding-right: 4rem !important;

        @media print {
            padding-left: 0 !important;
            padding-right: 0 !important;
        }
    }
    .zn-article-hero__background {
        padding-left: 4rem !important;
        padding-right: 4rem !important;
    }
    .zn-profile-hero {
        padding-left: 4rem !important;
        padding-right: 4rem !important;
    }
    .zn-quote {
        text-align: center !important;
    }
    .zn-featured-list {
        display: inherit;
    }
    .zn-featured-list__item {
        display: inherit;
    }
    .zn-featured-list__item__container {
        display: inherit;
    }
    li {
        break-inside: avoid;
        break-inside: avoid-page;
    }
}

.fade-enter {
    opacity: 0;
    z-index: 1;
}

.fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 300ms ease-in;
}

@keyframes slide-in {
    from {
        transform: translateX(-100%);
        transition-timing-function: ease-in;
    }
    to {
        transform: translateX(0%);
        transition-timing-function: ease-out;
    }
}

.slide-in {
    animation: slide-in 0.5s forwards;
    -webkit-animation: slide-in 0.5s forwards;
}

.slide-out {
    animation: slide-out 0.5s forwards;
    -webkit-animation: slide-out 0.5s forwards;
}

@-webkit-keyframes slide-in {
    0% {
        transform: translateX(-100%);
    }
    100% {
        -webkit-transform: translateX(0%);
    }
}

@keyframes slide-out {
    0% {
        transform: translateX(0%);
    }
    100% {
        transform: translateX(-100%);
    }
}

@-webkit-keyframes slide-out {
    0% {
        -webkit-transform: translateX(0%);
    }
    100% {
        -webkit-transform: translateX(-100%);
    }
}
