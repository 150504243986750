@import '../../styles/main.scss';

.zn-side-navigation {

    &__content-nav {
        background-color: $bg-blue;
        padding: 0 toRem(16px);


        @media #{$bp-s} {
            background-color: transparent;
            margin: 0;
        }

        transition: max-height 0.6s ease;


        @media #{$bp-s} {
            overflow: visible;
            height: 100%;
            max-height: 100% !important;
        }

        nav {
            padding: $spacing-3 0;

            @media #{$bp-s} {
                padding: 0;
                display: block !important;
            }
        }
    }

    &__info {
        display: flex;
        flex-direction: row;
        align-items: center;
        border: none;
        outline: none;
        width: 100%;
        cursor: pointer;
        padding: toRem(18px) $spacing-3 toRem(12px);
        background-color: $white;

        @media #{$bp-s} {
            display: none;
        }
    }

    &__title {
        @include Display-6();
        color: $black;
        padding-right: $spacing-2;
    }

    &__icon {
        color: $torys-blue;
        display: inline-block;
    }

    &__list {
        list-style: none;
        margin: 0 0 $spacing-4;
        padding: 0;
    }

    &__contact {
        display: flex;
        flex-direction: column;
    }

    &__accent {
        width: 100%;
        height: 1px;
        background-color: $light-grey;
        margin-bottom: $spacing-5;

        @media #{$bp-s} {
            max-width: toRem(262px);

        }

        &--pdf {
            margin-bottom: $spacing-4;
        }
    }

    &__contact-title {
        @include All-Caps();
        color: $black;
        margin-bottom: $spacing-5;
    }

    &__contact-cards {
        list-style-type: none;
        margin: 0 0 $spacing-5;
        padding: 0;
    }

    .zn-social-media-link {
        padding-left: 0;
    }
}