@import '../../styles/main.scss';

.zn-project-map-search-bar {
    width: 100%;
    padding: $spacing-2 $spacing-3;    

    @media #{$bp-m} {
        padding: $spacing-5; 
    }

    &__dt-filters {
        display: none;
        @media #{$bp-m} {
            display: block;
        }
    }

    &__mb-filters {
        display: none;

        &--open {
            position: fixed;
            display: flex;
            flex-direction: column;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background-color: $white;
            z-index: $navigation-z-index + 2;

        }
        @media #{$bp-m} {
            display: none;  
        }
    }

    &__mb-advanced-filters {
        padding-top: $spacing-5;
        
    }

    &__title {
        @include Display-2();
        @include desktop-medium-only();
        color: $black;
        margin-bottom: $spacing-3;
       
    }

    &__divider {
        @include desktop-medium-only();
        width: 80px;
        height: 5px;
        background-color: $orange;
        margin-bottom: $spacing-4;
    }

    &__input-container {
        display: flex;
        flex-direction: row;
    }

    &__input { 
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        border-right: 1px solid $light-grey;


        &:focus-within {
            border-color: $torys-blue;

            .zn-representative-work__search-button {
                color: $torys-blue;
            }
        }

        @media #{$bp-m} {
            border: none;
            border-bottom: 1px solid $darker-grey;
            margin-bottom: $spacing-4;
            padding: $spacing-2 toRem(10px);
        }
    }

    &__form {
        width: 100%;
    }

    &__form-input {
        @include Body-Medium();
        width: 100%;
        background-color: transparent;
        border: none;
        padding: 0 0 0 $spacing-5;
        color: $darker-grey;
        width: 100%;
        border-radius: 0;
        -webkit-appearance: none;
        -webkit-border-radius: 0;
        font-size: $spacing-3;

        &:focus-visible {
            outline: none;
        }

        @media #{$bp-m} {
            font-size: toRem(18px);

        }
    }

    &__search-button { 
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        padding: 0;
        
        svg {
            width: 19px;
            height: 19px;
            color: $torys-blue;
        }

        @media #{$bp-m} {
            padding: 0 0 0 toRem(10px);
        }
    }

    &__mb-filters-button {
        @include mobile-tablet-only();
        display: flex;
        flex-direction: row;
        background-color: transparent;
        outline: none;
        border: none;
        padding: 0 0 0 $spacing-3;
        align-items: center;
    }

    &__mb-filters-button-text {
        @include Body-Medium();
        padding-left: $spacing-2;
        color: $dark-grey;
    }

    &__mb-filter-top-container {
        display: flex;
        flex-direction: column;
        
        width: 100%;
        padding: 0 $spacing-3;
    }

    &__mb-filter-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: $spacing-2 0 $spacing-4;
    }

    &__mb-filter-title {
        @include Display-3();
        color: $dark-grey;
    }

    &__mb-filter-divider {
        width: 100%;
        height: 1px;
        padding: 0 $spacing-3;
        background-color: $light-grey;    
    }

    &__mb-filter-container {
        position: relative;
        padding-top: $spacing-5;
        height: 100%;

        &--ad-open {
            padding-top: 0;
        }
    }

    &__filter-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
    }

    &__overlay {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        background-color: $bg-blue; 
        opacity: 0.5;
        z-index: 5;

        @media #{$bp-m} {
            display: block;
        } 
    }

    &__advanced-filters {
        margin: $spacing-3 $spacing-3 0 0;
        padding: 0;
        background-color: transparent;
        outline: none;
        border: none;

        svg {
            color: $dark-grey;
        }

        &:hover, &:focus {
             svg {
                 color: $torys-blue;
             }
        }

        &--disabled {
            svg {
                color: $light-grey;
            }
        }
    }

    &__clear-all-button {
        @include clear-filter();

        @media #{$bp-m} {
            margin-top: $spacing-3;
            
            &--disabled {
                cursor: default;
                color: $medium-grey;
            }

            &--disabled:hover {
                cursor: default;
                color: $medium-grey;
            }
        } 
    }

    &__mb-footer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: $spacing-3;
        border-top: 1px solid $light-grey;

    }

    &__mb-clear-button {
        @include clear-filter();

    }

    &__mb-apply-button {
        .zn-button-simple {
            padding: $spacing-2 $spacing-3;
        }
    }
}
