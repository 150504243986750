@use "sass:list";
@import '../../styles/main.scss';

$column-total: 12;

// Horizontal Padding
$section-x-padding-sm: $spacing-3;
$section-x-padding-md: toRem(74px);
$section-x-padding-lg: toRem(165px);
$section-x-padding-override-md: toRem(165px);

// Vertical Padding
$small-screen-y-paddings: 1rem, 1.5rem, 2rem;
$medium-screen-y-paddings: 2rem, 4rem, 4rem;
$large-screen-y-paddings: 3rem, 4rem, 4rem;

.zn-two-col-sticky-side-banner {
    display: flex;
    flex-direction: row;
    position: relative;
    justify-content: center;
    padding: list.nth($large-screen-y-paddings, 1) $section-x-padding-sm;
    scroll-margin-top: toRem(66px);

    @media #{$bp-s} {
        padding: list.nth($large-screen-y-paddings, 2) $section-x-padding-md;
        scroll-margin-top: toRem(82px);
    }

    @media #{$bp-m} {
        padding: list.nth($large-screen-y-paddings, 3)
            responsive-spacing($screen-m, $screen-xxl, 74px, 165px);
    }

    @media #{$bp-xxl} {
        padding: list.nth($large-screen-y-paddings, 3) $section-x-padding-lg;
    }

    &--sc-editing {
        padding: 0;
    }

    &__content {
        display: flex;
        flex: 1;
        flex-direction: column-reverse;
        max-width: 100%;
        width: 100%;

        @media #{$bp-m} {
            flex-direction: row;
            justify-content: space-between;
            max-width: toRem($section-content-max-width);
            
            &--sc-editing {
                max-width: 100%;
            }
        }

        .zn-section {
            padding-left: 0;
            padding-right: 0;
        }

        .zn-section__content {
            max-width: toRem(732px);
        }
    }

    &__left-content {
        max-width: 100%;
        @media #{$bp-m} {
            width: #{(7 * 100 / $column-total) + '%'};
            max-width: #{(7 * 100 / $column-total) + '%'};
        }
    }

    &__right-content {
        max-width: 100%;
        @media #{$bp-m} {
            width: #{(4 * 100 / $column-total) + '%'};
            max-width: #{(4 * 100 / $column-total) + '%'};
        }
    }

    &__banner-container {
        @include desktop-only();
        z-index: 1;
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        pointer-events: none;

        &--sc-editing {
            position: unset;
            border-left: 1px dotted black;
            height: unset;
        }    
    }

    &__banner {
        padding-top: toRem(135px);
        padding-bottom: toRem(135px);
        position: sticky;
        top: 0;
        pointer-events: auto;

        &--sc-editing {
            position: unset;
        }    
    }

    &__banner-content {
        background-color: $bg-blue;
        padding: $spacing-4 $spacing-3;
    }
}