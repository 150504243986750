@import '../../styles/main.scss';

.zn-month-picker {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: $spacing-3;
    position: relative;
    width: 100%;
    padding: $spacing-2;
    border: 1px solid $darker-grey;

    &--active {
        height: 100%;
        margin-right: 0;
        position: absolute;
        background-color: $white;
        z-index: 3;
        top: 0;
        left: 0;
        border: none;
    }

    @media #{$bp-m} {
        display: flex;
        width: auto;
        margin-right: $spacing-3;
        padding: 0;
        border: none;
        margin-bottom: 0;

        &--active {
            margin-right: $spacing-3;
            position: relative;
            width: auto;
         }
    }

    &__button {
        border: none;
        background-color: transparent;
        display: flex;
        flex-direction: row;
        align-items: center; 
        color: $darker-grey;

        @media #{$bp-m} {
            border: 1px solid $darker-grey;
            background-color: transparent;
            border-radius: 100px;
            padding: $spacing-2 $spacing-3;
            color: $darker-grey;
    
            &--active {
                background-color: $torys-blue-transparent;
                color: $black;
                border-color: $torys-blue;
                border-width: 2px;
                margin: -1px;
            }
        }
    }

    &__title {
        @include Body-Medium();
        font-weight: 400;

        @media #{$bp-m} {
            padding-right: $spacing-2;
        }
    }

    &__title-chevron {
        display: none;

        @media #{$bp-m} {
            display: flex;
        }
    }

    &__button:hover {
        background-color: $torys-blue-transparent;
        color: $black;
        border-color: $torys-blue;
        border-width: 2px;
        margin: -1px;
        cursor: pointer;
    }

    &__calendar-icon {
        margin-right: toRem(12px);
        line-height: 0;
    }

    &__calendar {
        display: none;

        &--active {
            display: flex;
            position: absolute;
            display: flex;
            flex-direction: column;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: $white;
        }

        @media #{$bp-m} {
            position: absolute;
            display: none;
            flex-direction: column;
            align-items: flex-start;
            background-color: $white;
            width: toRem(214px);
            height: toRem(195px);
            padding: $spacing-2;
            box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1), 0px 1px 4px rgba(0, 0, 0, 0.1);
            border-radius: 2px;
    
            &--active {
                display: flex;
                height: auto;
                top: toRem(60px);
            }
        }
    }

    &__header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 0 $spacing-3 $spacing-2;
        margin-top: $spacing-3;
        width: 100%;

        @media #{$bp-m} {
            border-bottom: 1px solid $darker-grey;
            padding: 0 0 $spacing-2;

        }
    }

    &__arrow {
        border: none;
        background-color: transparent;
        color: $darker-grey;
    }

    &__year {
        @include Body-Medium();
        color: $darker-grey
    }

    &__body {
        margin: $spacing-2 toRem(22px) 0;
        border-top: 1px solid $darker-grey;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        justify-content: center;
        align-items: center;

        @media #{$bp-m} {
            margin: 0;
            padding-top: $spacing-2;
            border: none;
        }
    }

    &__item-wrapper {
        display: flex;
        justify-content: center;  
    }

    &__item {
        @include Body-Regular();
        border: 1px solid transparent;
        width: toRem(40px);
        height: toRem(40px);
        background-color: transparent;
        padding: $spacing-2;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: $spacing-1;
        color: $darker-grey;

        &--active {
            background-color:rgba(0, 110, 170, 0.08);
            color: $black;
        }

        &:hover {
            border: 1px solid $darker-grey;
            cursor: pointer;
        }
    }

    &__chevron {
        display: block;
        height: toRem(16px);
        width: toRem(10px);
        line-height: 0;

        svg {
            color: inherit;
            max-height: 100%;
            max-width: 100%;
        }

        &--left {
            margin-left: $spacing-3;
            margin-right: 0;
        }

        &--right {
            margin-left: 0;
            margin-right: $spacing-3;
        }

        &:hover {
            cursor: pointer;
            color: $torys-blue;
        }
    }

    &__mobile-back-button {
        @include Display-6();
        color: $torys-blue;
        align-items: center;
        display: flex;
        flex-direction: row;
        padding: 0 0 toRem(14px);
        margin: 0 $spacing-3;
        border-bottom: 1px solid $light-grey;

        @media #{$bp-m} {
            display: none;
        }
    }

    &__mobile-back-title {
        color: $darker-grey;
    }

    &__bottom-buttons{
        @include Body-Medium();
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: $spacing-3 toRem(20px) toRem(20px) toRem(34px);
        width: 100%;
        border-top: 1px solid $light-grey-45;
        margin-top: $spacing-3;
        position: absolute;
        bottom: 0;

        @media #{$bp-m} {
            display: none;
        }
    }

    &__clear-button{
        @include Display-6();
        color: $torys-blue;
        border: none;
        font-weight: 400;
        background-color: transparent;
        padding: 0;
        text-decoration: underline;
    }
}