@import '../../styles/main.scss';

.zn-event-search-results {
    margin: 0 auto;
    max-width: $section-content-max-width;
    padding-bottom: $spacing-6;
    width: 100%;
    scroll-margin-top: $spacing-6;

    &__heading-row {
        display: flex;
        justify-content: space-between;
        margin-bottom: $spacing-5;
    }

    &__featured-content {
        display: block;
        margin-bottom: $spacing-5;
        @media #{$bp-m} {
            margin-bottom: toRem(35px);
        }
    }

    &__desktop-button {
        display: none;
        @media #{$bp-s} {
            display: block;
            align-self: flex-end;
        }
    }

    &__mobile-button {
        display: block;
        margin-bottom: $spacing-5;
        @media #{$bp-s} {
            display: none;
        }
    }

    &__loading {
        @include Display-5();
        text-transform: uppercase;
        color: $torys-blue;
    }

    &__error {
        @include Display-5();
        text-transform: uppercase;
        color: $orange;
    }

    &__no-results {
        @include Display-5();
        color: $black;
    }

    &__grid {
        display: grid;
        grid-template-columns: repeat(1, minmax(0, 1fr));
        grid-row-gap: $spacing-3;
        grid-column-gap: $spacing-4;
        margin-bottom: $spacing-5;

        @media #{$bp-m} {
            display: grid;
            grid-template-columns: repeat(3, minmax(0, 1fr));
            grid-row-gap: $spacing-6;
        }
    }

    &__item {
        display: none;

        &--mobile-display {
            display: block;

            @media #{$bp-m} {
                display: none;
            }
        }

        &--desktop-display {
            @media #{$bp-m} {
                display: block;
            }
        }
    }

    .zn-featured-content {
        padding: 0;
    }

    .zn-button {
        white-space: nowrap;

        &:hover {
            cursor: pointer;
        }
    }

    &__animate {
        .zn-autoload-content-row {
            &__item {
                @include fadeIn();
            }
        }
    }
    
    @media #{$bp-m} {
        scroll-margin-top: $spacing-9;
    }

    &__nav {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: $spacing-3 0;

        h4 {
            @include Display-4-Secondary();
        }

        @media #{$bp-s} {
            flex-direction: row-reverse;
        }

        &--people-only {
            display: none;
        }
    }

    &__nav-result-details {
        display: flex;
        flex-direction: column;

        p {
            color: $darker-grey;
        }
    }

    &__nav-result-details {
        display: flex;
        flex-direction: column;
    }

    &__nav-result-filter {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin-bottom: $spacing-3;
        width: 100%;

        @media #{$bp-s} {
            margin-bottom: 0;
            margin-left: $spacing-2;
            width: auto;
        }
    }

    &__nav-result-filter-label {
        font-weight: 500;
        margin-right: $spacing-4;
        white-space: nowrap;
    }

    &__list {
        padding: 0;

    }

    &__list-item {
        list-style: none;
        border-top: 1px solid $light-grey;

        &:last-child {
            border-bottom: 1px solid $light-grey;
        }

        &--people-only {
            flex-direction: row-reverse;
            border: none;
            
            &:last-child {
                border-bottom: none;
            }
        }
    }

    &__loading {
        @include Display-5();
        width: 100%;
        text-align: center;
        text-transform: uppercase;
        color: $torys-blue;
    }

    &__error {
        @include Display-5();
        width: 100%;
        text-align: center;
        text-transform: uppercase;
        color: $orange;
    }

    &__pagination {
        display: flex;
        justify-content: center;
    }

    .zn-dropdown {
        padding: 0;

        &__content {
            padding: 0;
        }

        &__title {
            @include Body-Regular();
            color: $darker-grey;
            font-weight: 400;
        }

        &__list-item {
            padding: $spacing-2 $spacing-3;
            border-bottom: 1px solid $light-grey;
            margin: 0;
        }

        &__list-item:last-child {
            border-bottom: none;
        }

        &__list-item-button {
            @include Body-Regular();
            color: $darker-grey;
            background-color: transparent;
            width: 100%;
            text-align: left;
            border: none;
            padding: 0;

            &:hover {
                color: $torys-blue;
                cursor: pointer;
            }
        }
    }
}
