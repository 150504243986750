@import "../../styles/main.scss";


.zn-representative-work-filter {
    width: 100%;
    display: none;
    margin-bottom: $spacing-3;
    max-height: 100%;
        
    &--active {
        display: block;
    }

    &--mobile-open {
        display: block;
    }

    @media #{$bp-m} {
        max-width: 246px;
        display: block;
        margin: 0;
    } 

    &__options-list {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }

    &__header {
        border: none;
        background-color: transparent;
        display: flex;
        flex-direction: row;
        justify-content: start;
        padding: 0;
        align-items: center;
        
        &:hover {
            cursor: pointer;

            &--disabled {
                cursor: default;
            }
        }

        &--active, &--disabled {
            margin-left: $spacing-3;
            padding-bottom:toRem(14px);
        }

        @media #{$bp-m} {
            width: 100%;
            justify-content: space-between;
            padding: toRem(10px) 0;

            &--active, &--disabled {
                margin: 0;
            }
        } 
    }

    &__title {
        @include Display-6();
        color: $black;
        padding-left: $spacing-3;

        &--active, &--disabled {
            order: 2;
            color: $darker-grey;
            padding-left: 0;
        }

        @media #{$bp-m} {
            padding: 0;
            display: flex;
            color: $darker-grey;
            text-align: left;
            
            &--active {
                order: 1;
            }

            &--disabled {
                order: 1;
                color: $medium-grey;
            }
        } 
    }

    &__desktop-chevron {
        @include desktop-medium-only();
        color: $darker-grey;
        order: 2;
        line-height: 0;

        &--disabled {
            color: $medium-grey;
        }
    }

    &__mobile-chevron {
        @include mobile-tablet-only();
        display: block;
        margin-right: 0;
        margin-left: toRem(12px);
        height: toRem(16px);
        width: toRem(10px);
        line-height: 0;
        color: $torys-blue;

        svg {
            max-height: 100%;
            max-width: 100%;
        }

        &--active {
            order: 1;
            margin-right: toRem(16px);
            margin-left: 0;
        }
    }

    &__divider {
        @include desktop-medium-only();
        width: 100%;
        height: 1px;
        background-color: $light-grey;
        margin: $spacing-3 0;
        max-width: toRem(236px);    
    }

    &__mobile-divider {
        @include mobile-tablet-only();
        display: none;
        height: 1px;
        background-color: $light-grey;
        margin: 0 $spacing-3;

        &--filter-open {
            display: block;
        }

        @media #{$bp-m} {
            &--filter-open {
                display: none;
            }
        } 
    }

    &__desktop-selected-filters {
        display: none;

        @media #{$bp-m} {
            padding-left: $spacing-2;
            display: flex;
            align-items: center;
            padding-right: $spacing-2;
        }
    }

    &__mobile-selected-filters {
        @include mobile-tablet-only();
        @include All-Caps-Small();
        color: $darker-grey-2;
        margin-left: $spacing-3;
        font-weight: 400;

        &--filter-open {
            display: none;
        }
    }

    &__options-list {
        display: none;
        animation: 0s linear 0.6s forwards delayedHide;

        &--active {
            display: block;
            animation: none;
            visibility: visible;
            height: 100%;
            max-height: 100%;
            overflow: auto;
            width: 100%;
            position: absolute;
            padding: $spacing-4 0 toRem(160px);
        }

        @media #{$bp-m} {
            display: block;
            overflow: hidden;
            max-height: 0px;
            transition: max-height 0.6s ease;
    
            &--active {
                max-height: 625px;
                padding: 0;
                position: relative;
                overflow-y: auto;
            }
        } 
    }

    @keyframes delayedHide {
        to {
          visibility: hidden;
        }
      }

    &__options-footer { 
        @include Body-Medium();
        display: none;
        position: absolute;
        flex-direction: row;
        padding: $spacing-3 toRem(20px) toRem(20px) toRem(34px);
        width: 100%;
        justify-content: space-between;
        border-top: 1px solid $light-grey-45;
        bottom: 0;
        background-color: $white;

        &--active {
            display: flex;
        }

        @media #{$bp-m} {
            display: none;
        } 
    }

    &__clear-button {
         @include clear-filter();
         text-align: left;
    }

    &__no-filters {
        margin: 0 $spacing-3;

        @media #{$bp-m} {
            margin: 0;
        } 
    }

    &__no-filters-title {
        @include Body-Medium();
        padding-bottom: $spacing-1;
        color: $black;

        &--disabled {
            color: $medium-grey;
        }
    }

    &__no-filters-description {
        @include Body-Regular();
        color: $darker-grey;

        &--disabled {
            color: $medium-grey;
        }
    }

    .zn-checkbox {
        @media #{$bp-m} {
            &.checked {
                background-color: transparent;
            }
        } 
    }
}