@use "sass:list";
@import '../../styles/main.scss';

// Horizontal Padding
$section-x-padding-sm: $spacing-3;
$section-x-padding-md: toRem(74px);
$section-x-padding-lg: toRem(165px);

$highlight-width: $spacing-3;
$highlight-mobile-width: $spacing-1;

// Vertical Padding
$small-screen-y-paddings: 1rem, 1.5rem, 2rem;
$medium-screen-y-paddings: 2rem, 4rem, 4rem;
$large-screen-y-paddings: 3rem, 4rem, 4rem;

@mixin y-padding-size($top-or-bottom, $size, $paddings) {
    &--#{$top-or-bottom}-#{$size} {
        #{$top-or-bottom}: list.nth($paddings, 1);
        @media #{$bp-s} {
            #{$top-or-bottom}: list.nth($paddings, 2);
        }
        @media #{$bp-m} {
            #{$top-or-bottom}: list.nth($paddings, 3);
        }
    }
}

.zn-section {
    display: flex;
    position: relative;
    justify-content: center;
    margin: 0 auto;
    width: 100%;
    padding: list.nth($large-screen-y-paddings, 1) $section-x-padding-sm;
    scroll-margin-top: toRem(66px);

    @media #{$bp-s} {
        padding: list.nth($large-screen-y-paddings, 2) $section-x-padding-md;
        scroll-margin-top: toRem(82px);
    }

    @media #{$bp-m} {
        padding: list.nth($large-screen-y-paddings, 3)
            responsive-spacing($screen-m, $screen-xxl, 74px, 165px);
    }

    @media #{$bp-xxl} {
        padding: list.nth($large-screen-y-paddings, 3) $section-x-padding-lg;
    }

    @include y-padding-size('padding-top', 'small', $small-screen-y-paddings);
    @include y-padding-size('padding-top', 'medium', $medium-screen-y-paddings);
    @include y-padding-size('padding-top', 'large', $large-screen-y-paddings);
    @include y-padding-size('padding-bottom', 'small', $small-screen-y-paddings);
    @include y-padding-size('padding-bottom', 'medium', $medium-screen-y-paddings);
    @include y-padding-size('padding-bottom', 'large', $large-screen-y-paddings);

    &--padding-bottom-none {
        padding-bottom: 0;
    }

    &--padding-top-none {
        padding-top: 0;
    }

    &--indent {
        padding-left: calc(#{$section-x-padding-sm} + #{$highlight-mobile-width});
        @media #{$bp-s} {
            padding-left: calc(#{$section-x-padding-md} + #{$highlight-width});
        }
        @media #{$bp-m} {
            padding-left: calc(#{$section-x-padding-lg} + #{$highlight-width});
        }
    }

    &--full-width {
        @media #{$bp-m} {
            padding-left: 0;
            padding-right: 0;
            justify-content: flex-start;
        }
    }

    &--full-height {
        padding-top: 0;
        padding-bottom: 0;
    }

    &--white {
        background-color: $white;
    }

    &--grey {
        background-color: $bg-blue;
    }

    &--blue {
        background-color: $torys-blue;
    }

    &--navy {
        background-color: $dark-blue;
    }

    &--lightblue {
        background-color: $bg-light-blue;
    }

    &__content {
        max-width: toRem($section-content-max-width);
        width: 100%;

        &--full-width {
            max-width: 100%;
        }

        &--left {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            text-align: left;
        }

        &--center {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;

            @media print {
                text-align: left;
                align-items: left;
            }
        }

        &--right {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            text-align: right;
        }
    }

    &__highlight {
        top: 0;
        left: 0;
        height: 100%;
        width: $highlight-mobile-width;
        position: absolute;
        background-color: $orange;

        @media #{$bp-s} {
            width: $highlight-width;
        }

        &--orange {
            background-color: $orange;
        }

        &--teal {
            background-color: $teal;
        }

        &--blue {
            background-color: $torys-dark-blue;
        }

        &--navy {
            background-color: $dark-blue;
        }

        &--grey {
            background-color: $light-grey;
        }
    }
    @media print {
        background-color: transparent !important;
    }
}
