@import '../../styles/main.scss';


.zn-projects-map-container {
    display: flex;
    flex-direction: column;
    width:100%;
    height: 92vh;

    @media #{$bp-m} {
        flex-direction: row;
    }
    
    &__left-container {
        flex-direction: column;
        background-color: $white;
        width: 100%;

        &--mobile-view {
            display: flex;
            height: 100%;
            width: 100%;
        }

        &--mobile-map-open {
            z-index: 10;
        }

        @media #{$bp-m} {
            display: flex;
            max-width: toRem(519px);
            width: 36%;
        }

        &--no-map {
            display: flex;
            height:100%;
            width: 100%;
            max-width: 100%;
        }
    }

    &__mobile-projects-search-bar {
        display: flex;
        height: toRem(42px);
        
        @media #{$bp-m} {
           display: none;
        }
    }

    &__projects-search-bar {
        width: 100%;

        @media #{$bp-m} {
            display: flex;
        }
    }

    &__projects-list {
        overflow-y: auto;
        display: none;
        margin-bottom: $spacing-3;

        &--mobile-view {
            display: flex;
        }

        @media #{$bp-m} {
            display: flex;
            margin-bottom: $spacing-5;
        }

        &--no-map {
            display: flex;
        }
    }

    &__right-container {
        height: 100%;
        width: 100%;

        &--hide-mobile {
            display: none;
        }

        @media #{$bp-m} {
            &--hide-mobile {
                display: block;
            }
        }

        &--no-map {
            display: none;
        }
    }

    &__projects-map {
        position: relative;
        height: 100%;
        z-index: 1;
    }

    &__toggle-button-container {
        position:absolute;
        width: 100%;
        display: flex;
        bottom: 0;
        justify-content: center;
        align-items: center;
    }

    &__toggle-view-button {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin: 0 auto $spacing-6;
        color: $white;
        background-color: $torys-blue;
        border: none;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
        border-radius: 2px;
        padding: $spacing-2 $spacing-3;
        z-index: 1;

        @media #{$bp-m} {
           display: none;
        }
    }

    &__toggle-view-button-text {
        @include Display-6();
        margin-left: $spacing-2;
    }
}
