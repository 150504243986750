@import '../../styles/main.scss';

.zn-expertise-featured-work-list {

    &__button-container {
        display: flex;
        justify-content: center;
        padding-top: $spacing-4;

        @media #{$bp-s} {
            padding-top: toRem(20px);
        }

        .zn-button:hover {
            cursor: pointer;
        }
    }


    .zn-featured-list__item {
        .zn-featured-list__item__description {
            @include Body-Regular();
        }
    }
}