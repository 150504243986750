@import '../../styles/main.scss';

.zn-link-list-item {
    margin-bottom: $spacing-3;

    &--divider {
        margin: 0;
        padding: toRem(17px) 0 toRem(25px) 0;
        border-bottom: 1px solid $light-grey-45;

        &:first-child {
            border-top: 1px solid $light-grey-45;
        }

        @media print {
            border-top: 0 none !important;
            border-bottom: 0 none;
        }
    }

    &--eyebrow {
        &:first-child {
            border-top: none;
        }
    }

    &--highlighted {
        border-left: 4px solid;
        padding: 2px $spacing-3;
        margin-bottom: $spacing-3;
        line-height: toRem(27px);

        &:nth-child(4n + 1) {
            border-color: $teal;
        }

        &:nth-child(4n + 2) {
            border-color: $orange;
        }

        &:nth-child(4n + 3) {
            border-color: $torys-blue;
        }

        &:nth-child(4n + 4) {
            border-color: $dark-blue;
        }

        &.zn-link-list-item--divider {
            border-top: none;
            border-bottom: none;
        }

        .zn-button--tertiary {
            @include Body-Medium();
            display: inline-flex;
            justify-content: space-between;
            width: 100%;
            padding: 0;
            font-weight: 400;
        }
    }
    @media print {
        padding-left: 0;
        margin-left: 0;
        border-left: 0 none;
        margin-bottom: 0.1in;
    }
    &__subtext {
        @media print {
            display: inline-block;

            border-bottom: 0.004in solid #4c4c4c;
        }
    }

    &--no-padding {
        padding: 0;
        margin: 0;
    }

    .zn-link {
        color: $darker-grey;
        line-height: toRem(27px);

        &:hover {
            color: $torys-dark-blue;
        }
    }

    @media print {
        a {
            page-break-inside: avoid !important;
            text-align: left;
            padding: 0;
            margin: 0;
            width: auto !important;
            border-radius: 0;
            max-width: none;
            color: #4c4c4c;
            border-bottom: 0.004in solid #4c4c4c !important;
            &:hover {
                color: inherit !important;
            }
        }
    }

    &__pdf {
        @media print {
            display: block;
            text-decoration: underline;
            text-underline-offset: 0.04in;
            text-decoration-thickness: 0.0104in;
            font-size: 0.17in;
            margin-bottom: 0.12in;
            color: $darker-grey;
            page-break-inside: avoid !important;

            em {
                font-style: normal !important;
            }

            a {
                color: $darker-grey !important;
            }
        }
    }
}

.zn-link-list-item__eyebrow,
.zn-link-list-item__subtext,
.zn-link-list-item__text {
    @include Body-Medium();

    margin-bottom: 0px;

    &--light {
        color: $darker-grey;
    }

    &--dark {
        color: $bg-blue;
    }
}

.zn-link-list-item__eyebrow {
    @include eyebrow;
    margin-bottom: toRem(8px);
}
