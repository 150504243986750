@import '../../styles/main.scss';

.zn-carousel {
    $image-height-mobile: toRem(164px);
    $image-height: toRem(352px);

    align-items: center;
    background-color: $white;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    max-width: 100%;
    position: relative;

    @media #{$bp-s} {
        max-width: toRem(732px);
    }

    .zn-carousel__content {
        padding: 0;
        position: relative;
        max-width: 100%;
    }

    .zn-carousel__item {
        display: none;
        &--active {
            display: block;
        }
    }

    .zn-carousel__pagination {
        align-items: center;
        display: flex;
        justify-content: center;
        left: 0;
        position: absolute;
        top: calc(#{$image-height-mobile} + #{toRem(22px)});
        width: 100%;

        @media #{$bp-s} {
            top: calc(#{$image-height} + #{toRem(22px)});
        }

        .zn-button {
            width: fit-content;
            padding: 0;
        }

        svg {
            margin: 0 toRem(4px);
        }
    }

    .zn-image .zn-image__media {
        align-items: center;
        display: flex;
        justify-content: center;
        padding: 0 toRem(64px);
        margin-bottom: toRem(32px);
        height: $image-height-mobile;

        @media #{$bp-s} {
            height: $image-height;
            padding: 0 toRem(80px);
        }

        img {
            object-fit: contain;
            height: auto;
            max-height: 100%;
            max-width: 100%;
        }
    }

    .zn-image .zn-image__caption {
        padding: 0 toRem(16px);
        @media #{$bp-s} {
            padding: 0;
        }
    }
}

.zn-carousel__chevron {
    padding: 0;
    position: absolute;
    top: toRem(66px);
    width: auto;
    z-index: 2;

    @media #{$bp-s} {
        top: toRem(160px);
    }

    &--left {
        left: toRem(20px);

        @media #{$bp-s} {
            left: toRem(4px);
        }
    }

    &--right {
        right: toRem(20px);
        
        @media #{$bp-s} {
            right: toRem(4px);
        }
    }

    img,
    svg {
        width: toRem(36px);
        height: toRem(36px);
        @media #{$bp-s} {
            width: toRem(48px);
            height: toRem(48px);
        }
    }
}

.zn-carousel-editor {
    align-items: stretch;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .zn-image {
        max-width: 200px;
    }
}
