@import '../../styles/main.scss';

.zn-featured-list {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    width: 100%;
    list-style-type: none;
    padding: 0;
    margin: 0;

    &--two-columns {
        @media #{$bp-s} {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .zn-featured-list__item {
                width: calc(50% - 22px);
            }
        }
    }
}
.zn-featured-list__item__title {
    @media print {
        font-size: 0.21in;
    }

    h4 {
        @media print {
            font-size: 0.21in;
            margin-bottom: 0.125in;
        }
    }
}
