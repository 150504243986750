@import '../../styles/main.scss';

.zn-project-map-table {
    width: 100%;

    table {
        width: 100%;
    }

    td {
        padding: $spacing-2;
        @include Body-Regular();
    }

    &__table {
        margin-bottom: $spacing-4;
    }

    &__title {
        padding-right: $spacing-6;
        font-weight: 500;
        width: toRem(150px);

        @media #{$bp-s} {
            width: toRem(200px);
        }
    }
}
