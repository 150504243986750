@import '../../styles/main.scss';

.zn-interactive-map-popup {
    width: 100%;
    display: flex;
    flex-direction: row;

    &__icon-container {
        margin-right: $spacing-3;
    }

    &__icon {
        height: toRem(32px);
        width: toRem(32px);
    }

    &__content {
        display: flex;
        flex-direction: column;
        margin-right: $spacing-3;
    }

    &__title-container {
        overflow: hidden;
        color: $black;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        line-clamp: 3;
        -webkit-box-orient: vertical;
    }

    &__title {
        @include Body-Medium();
        padding-bottom: $spacing-1;
        color: $black;
        
        @media #{$bp-m} {
            line-height: 1.5;
        }
    }

    &__tag {
        @include Body-Regular();
        color: $dark-grey;
    }

    &__close-button {
        position: absolute;
        right: $spacing-3;
    }

    .zn-button {
        font-size: toRem(14px);
        line-height: 1.57;
        padding: 0;
        padding-top: $spacing-2;

        &:hover {
            cursor: pointer;
        }
    }
}