@import '../../styles/main.scss';

.zn-search-bar-filter-range-datepicker-wrapper {
    position: relative;
    @media screen and (max-width: #{$screen-m}) {
        position: unset;
    }
}

.zn-search-bar-filter-range-datepicker-popup {
    font-family: 'franklin-gothic-urw', sans-serif;
    position: absolute;
    top: 60px;
    max-height: 625px;
    background: $white;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1), 0px 1px 4px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    z-index: 9999;
    display: flex;
    align-items: stretch;

    @media screen and (max-width: #{$screen-m}) {
        top: 0px;
        max-width: 100%;
        flex-direction: column;
        max-height: unset;
        bottom: 0;
        width: 100%;
        border: 0 none;
        padding-top: 10px;
        box-shadow: 0 0 0 transparent;
        overflow-y: auto;
        .rdp-caption {
            position: unset;
        }
        .rdp-caption_end .rdp-nav {
            top: 60px;
            right: 12%;
        }
        .rdp-caption_start .rdp-nav {
            top: 60px;
            left: 12%;
        }
    }
}

.zn-search-bar-filter-range-datepicker-popup-container {
    .rdp-multiple_months {
        margin-top: 10px;
    }
    .current-date {
        font-size: 0.9rem;
        display: flex;
        border-bottom: 1px solid $light-grey-45;
        height: 40px;
        .date {
            padding: 10px 0;
            flex-grow: 1;
            max-width: 50%;
            text-align: center;
        }

        @media screen and (max-width: #{$screen-m}) {
            display: none;
        }
    }
    .rdp-day:not(.rdp-day_selected) {
        &:hover {
            background-color: $white !important;
            color: $torys-blue !important;
        }
    }
    .rdp-day_selected {
        color: #000;
        background-color: $light-grey-45;
    }
    .rdp-day_selected.rdp-day_range_end,
    .rdp-day_selected.rdp-day_range_start {
        color: $white;
        background-color: $torys-blue;
    }
    .rdp-day_today:not(.rdp-day_selected) {
        border: 1px solid $torys-blue;
        border-radius: 50%;
    }

    .rdp-dropdown {
        font-size: 0.8rem;
        border-radius: 0;
        padding: 0 8px;
    }
    .rdp-caption_label {
        font-size: 1rem;
        font-weight: normal;
        border-radius: 0 !important;
        margin-left: 2px;
        margin-right: 2px;
    }

    .rdp-months {
        @media screen and (max-width: #{$screen-m}) {
            flex-direction: column;
            align-items: center;
            max-width: 100%;

            .rdp-month {
                margin: auto;
            }
        }
    }
}
.zn-search-bar-filter__apply {
    &:disabled {
        &:hover {
            color: $medium-grey;
        }
        color: $medium-grey;
    }
}
.zn-search-bar-filter-range-datepicker-popup__buttons {
    min-width: 140px;
    max-width: 150px;
    border-left: 1px solid $light-grey;
    padding-top: 10px;

    button {
        background-color: transparent;
        border: 0 none;
        border-radius: 0;
        font-family: 'franklin-gothic-urw', sans-serif;
        font-size: 1rem;
        line-height: 2;
        padding: 0 1rem;
        text-transform: capitalize;
        color: $darker-grey;
        text-align: left;
        &:hover {
            color: $torys-blue;
        }
        @media screen and (max-width: #{$screen-m}) {
            min-width: 90px;
            text-align: center;
            padding: 0;
        }
    }

    &__disabled {
        pointer-events: none;
    }

    &__button--disabled:hover {
        @media #{$bp-m} {
            border-color: $medium-grey;
            color: $medium-grey;
            cursor: default;
            margin: 0;
            border: 1px solid $medium-grey;
            background-color: transparent;
        }
    }

    @media screen and (max-width: #{$screen-m}) {
        max-width: 100%;
        overflow-x: auto;
        flex-wrap: nowrap;
        display: flex;
        min-height: 60px;
        border-left: 1px solid #e6e6e6;
        padding-top: 0px;
        padding-bottom: 10px;
        position: relative;
        top: -10px;
    }
}
