@import '../../styles/main.scss';

.zn-featured-list__item {
    list-style: none;
    display: flex;
    flex-direction: column;
    padding: $spacing-4 0;
    position: relative;

    .zn-featured-list__item__title {
        @include Display-4-Secondary();
        font-size: 0.21in;
    }

    .zn-featured-list__item__description {
        color: $darker-grey;
        margin-bottom: toRem(16px);
        @media print {
            font-size: 0.16in;
        }
    }

    .zn-button {
        padding: 0 $spacing-2 0 0;
        font-size: toRem(18px);
        margin-top: toRem(4px);
    }

    &--divider {
        &:not(:last-child) {
            border-bottom: 1px solid $light-grey;
        }

        @media #{$bp-s} {
            .zn-featured-list--two-columns &:nth-last-child(2) {
                border-bottom: none;
            }
        }

        @media print {
            border-bottom: 0 none !important;
        }
    }

    &__title-section {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 1rem;

        @media print {
            margin-bottom: 0.1in;
        }
        svg {
            color: $torys-blue;
            flex: none;
        }
    }

    &__title {
        &--icon {
            margin-left: toRem(18px);
        }
    }

    &__title-link {
        display: flex;
        flex-direction: row;
        align-items: center;
        color: $black;
        text-decoration: none;
        transition: all 0.3s;
    }

    &__title-link:hover {
        text-decoration: none;
        color: $torys-blue;
        cursor: pointer;

        @media #{$bp-s} {
            transform: translateX(0.5rem);
            transition: color 0.3s ease, transform 0.3s ease;
        }
    }

    &__container {
        display: flex;
    }

    &__highlight {
        border-left: 4px solid $white;
        padding: 0 $spacing-5 0 0;

        &--teal {
            border-left-color: $teal;
        }

        &--orange {
            border-left-color: $orange;
        }

        &--blue {
            border-left-color: $torys-blue;
        }

        &--navy {
            border-left-color: $dark-blue;
        }
    }

    // Removes the last margin-bottom of the last section
    &__content > *:last-child {
        margin-bottom: 0;
    }

    @media print {
        padding: 0;
        margin-top: 0.24in;
        margin-bottom: 0.24in;
    }
}

.zn-featured-list__animate {
    .zn-featured-list__item {
        &__content {
            @include fadeIn();
        }
        &__highlight {
            @include expandDown();
        }
    }
}
