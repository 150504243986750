@import '../../styles/main.scss';

.zn-dropdown {
    display: inline-flex;
    position: relative;

    &__title {
        align-items: center;
        display: flex;
        font-weight: 400;
        padding: $spacing-2 0;

        &--dark {
            color: $white;
        }

        &:hover {
            color: $torys-blue;
        }
    }

    &__chevron {
        height: toRem(16px);
        width: toRem(24px);
        display: inline-flex;
        margin-left: $spacing-1;

        &.active {
            transform: rotate(180deg);
            transition: transform 0s;
        }

        svg {
            max-height: 100%;
            max-width: 100%;
        }
    }

    &__icon {
        display: inline-flex;
        margin-right: $spacing-2;
    }

    &__content {
        background-color: $white;
        box-shadow: $card-shadow;
        display: inline-block;
        top: 100%;
        padding: $spacing-3;
        position: absolute;
        left: 0;
    }

    &__list-item-content {
        font-size: toRem(16px);
    }

    .zn-dropdown__list-item {
        margin-bottom: toRem(10px);
        list-style: none;
        font-size: toRem(16px);
    
        &:last-child {
            margin-bottom: 0;
        }
    }
    
    .zn-dropdown__list-item-content {
        padding: 0;
    }
}

.zn-dropdown--border {
    background-color: $white;
    border: 1px solid $light-grey;
    min-width: toRem(140px);

    .zn-dropdown__title {
        font-size: toRem(16px);
        font-weight: 500;
        padding: $spacing-2 $spacing-3;
        width: 100%;
    }

    .zn-dropdown__chevron {
        margin-left: auto;
    }

    .zn-dropdown__content {
        border: 1px solid $light-grey;
        box-shadow: none;
        padding: $spacing-2 $spacing-3;
        width: 100%;
    }
}
