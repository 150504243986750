@import '../../styles/main.scss';

.zn-mega-menu-header {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: toRem(16px) 0;
    margin-bottom: $spacing-3;

    @media #{$bp-m} {
        border-bottom: 1px solid $light-grey;
        flex-direction: row;
        margin-bottom: $spacing-6;
        padding: toRem(32px) 0 toRem(62px);
    }

    .zn-mega-menu-header__content-container {
        width: 100%;
    }

    .zn-mega-menu-header__content {
        border-bottom: 1px solid $light-grey;
        display: flex;
        flex-direction: column;
        padding: toRem(16px) 0;
        width: 100%;

        @media screen and (max-width: #{$screen-m}) {
            .zn-button--tertiary {
                &:hover {
                    color: $black !important;
                }
            }
        }
        @media #{$bp-m} {
            padding: 0;
            border: none;

            &--secondary {
                margin-top: $spacing-5;
            }
        }

        .type-body {
            display: none;
            color: $dark-grey;
            font-size: toRem(18px);
            @media #{$bp-m} {
                display: inline-block;
            }
        }

        .zn-button {
            padding: 0;
        }
    }
}

.zn-mega-menu-header__title {
    padding-bottom: $spacing-5;
    border-bottom: 1px solid $light-grey;
    width: 100%;
    @media #{$bp-m} {
        border: none;
        margin-right: toRem(38.25px);
        padding-right: 1.5rem;
        width: toRem(300px);
        min-width: toRem(300px);
    }

    @media screen and (max-width: #{$screen-m}) {
        .zn-link {
            &:hover {
                color: $black !important;
            }
        }
    }
}
