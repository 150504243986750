@import '../../styles/main.scss';

.type-h1 {
  @include Display-1();

  &.default-margin {
    margin-bottom: $spacing-5;
  }
}

.type-h2 {
  @include Display-2();

  &.default-margin {
    margin-bottom: $spacing-4;
  }
}

.type-h3 {
  @include Display-3();

  &.default-margin {
    margin-bottom: $spacing-4;
  }
}

.type-h4 {
  @include Display-4();

  &.default-margin {
    margin-bottom: $spacing-3;
  }
}

.type-h5 {
  @include Display-5();

  &.default-margin {
    margin-bottom: $spacing-3;
  }
}

.type-h6 {
  @include Display-6();

  &.default-margin {
    margin-bottom: $spacing-3;
  }
}

.type-body {
  @include Body-Regular();

  &.default-margin {
    margin-bottom: $spacing-3;
  }
}

.type-bodyMd {
  @include Body-Medium();

  &.default-margin {
    margin-bottom: $spacing-3;
  }
}

.type-bodyLg {
  @include Body-Large();

  &.default-margin {
    margin-bottom: $spacing-3;
  }
}

.type-allCapsSmall {
  @include All-Caps-Small();
  
  &.default-margin {
    margin-bottom: $spacing-3;
  }
}

.type-allCaps {
  @include All-Caps();

  &.default-margin {
    margin-bottom: $spacing-3;
  }
}

