@import '../../styles/main.scss';

.zn-link {
    @include Body-Medium();
    align-items: center;
    display: inline-flex;
    color: $black;
    text-decoration: none;
    color: inherit;
    cursor: pointer;

    &--light {
        color: $black;
        &:hover {
            color: $torys-blue;
        }
    }

    &--dark {
        color: $white;
        &:hover {
            color: $bg-blue;
        }
    }

    &__icon {
        display: flex;
        align-items: center;
        margin-left: toRem(12px);
        @media #{$bp-m} {
            margin-left: $spacing-3;
        }
    }

    &:hover {
        text-decoration: none;
    }
    &:hover & {
        &__icon--right {
            transform: translateX(0.5rem);
            transition: color 0.2s ease, transform 0.2s;
        }
    }

    &.small {
        font-size: toRem(16px);
    }
}

.zn-link__icon {
    &--chevron-right {
        height: toRem(16px);
        width: toRem(10px);
        @media #{$bp-m} {
            height: toRem(26px);
            width: toRem(16px);
        }
        svg {
            max-height: 100%;
            max-width: 100%;
            height: 100%;
            width: 100%;
        }
    }

    &--chevron-left {
        margin-left: 0;
        margin-right: toRem(16px);
        height: toRem(16px);
        width: toRem(10px);
        @media #{$bp-m} {
            margin-right: toRem(28px);
            height: toRem(26px);
            width: toRem(16px);
        }
        svg {
            max-height: 100%;
            max-width: 100%;
            height: 100%;
            width: 100%;
        }
    }
}

.zn-link--nav {
    align-items: center;
    font-family: $body-font-family;
    display: flex;

    @media #{$bp-m} {
        margin: 0;
        padding: 0 toRem(12px);
        margin-bottom: toRem(10px);
    }

    &.bold {
        font-weight: 500;
    }

    &.no-margin {
        margin: 0;
    }

    &.small {
        font-size: toRem(16px);
    }

    &:hover {
        color: $torys-blue;
        text-decoration: none;
    }

    &.dropdown {
        .zn-link__icon {
            margin-left: toRem(6px);
        }
        &:hover {
            .zn-link__icon {
                transform: none;
            }
        }

        &.active {
            .zn-link__icon {
                transform: rotate(180deg);
                transition: transform 0s;
            }
        }
    }
}

.zn-link--nav__dropdown {
    &::after {
        @include arrow-down(8px, 4px, 14px);
    }

    &.active {
        &::after {
            border-width: 2px 0px 0px 2px;
            margin: 0 0 -3px 14px;
        }
    }
}

.zn-link--nav--mega {
    @include Nav-Item-Large();
}

.zn-link--nav--mega--medium {
    @extend .zn-link--nav--mega;
    @include Nav-Item-Medium();

    .zn-link__icon {
        height: toRem(16px);
    }
}

.zn-link.inactive {
    &.zn-link--nav,
    &.zn-link--nav--mega {
        color: $dark-grey;
        &::before {
            border-color: $dark-grey;
        }
        &::after {
            border-color: $dark-grey;
        }
    }
    &:hover {
        color: $torys-blue;
    }
    .zn-link__icon svg {
        filter: saturate(0);
    }
}

.zn-link--hyperlink {
    line-height: 28px;
    margin: 0;
    text-decoration: underline;

    &--light {
        color: $torys-blue;
        &:hover {
            color: $torys-dark-blue;
        }
    }

    &--dark {
        color: $white;
        &:hover {
            color: $bg-blue;
        }
    }

    &:hover {
        text-decoration: underline;
    }

    .zn-link__icon {
        display: flex;
        align-items: center;
        margin-right: 0.5rem;
    }
}

.zn-link--small {
    @include Body-Regular();
    display: inline-flex;
    text-decoration: none;

    &--light {
        color: $black;
    }

    &--dark {
        color: $white;
    }

    &:hover {
        text-decoration: none;
    }
}

.zn-link--bold { 
    font-weight: 500;
}
