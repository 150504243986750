@import '../../styles/main.scss';

.zn-autoload-content-row {
    width: 100%;

    &__heading-row {
        display: flex;
        justify-content: space-between;
        margin-bottom: $spacing-5;
    }

    &__featured-content {
        display: block;
        margin-bottom: $spacing-5;
        @media #{$bp-m} {
            margin-bottom: toRem(35px);
        }
    }

    &__desktop-button {
        display: none;
        @media #{$bp-s} {
            display: block;
            align-self: flex-end;
        }
    }

    &__mobile-button {
        display: block;
        margin-bottom: $spacing-5;
        @media #{$bp-s} {
            display: none;
        }
    }

    &__loading {
        @include Display-5();
        text-transform: uppercase;
        color: $torys-blue;
    }

    &__error {
        @include Display-5();
        text-transform: uppercase;
        color: $orange;
    }

    &__no-results {
        @include Display-5();
        color: $black;
    }

    &__grid {
        display: grid;
        grid-template-columns: repeat(1, minmax(0, 1fr));
        grid-row-gap: $spacing-3;
        grid-column-gap: $spacing-4;
        margin-bottom: $spacing-5;

        @media #{$bp-m} {
            display: grid;
            grid-template-columns: repeat(3, minmax(0, 1fr));
            grid-row-gap: $spacing-6;
        }
    }

    &__item {
        display: none;

        &--mobile-display {
            display: block;

            @media #{$bp-m} {
                display: none;
            }
        }

        &--desktop-display {
            @media #{$bp-m} {
                display: block;
            }
        }
    }

    .zn-featured-content {
        padding: 0;
    }

    .zn-button {
        white-space: nowrap;

        &:hover {
            cursor: pointer;
        }
    }

    &__animate {
        .zn-autoload-content-row {
            &__item {
                @include fadeIn();
            }
        }
    }
}
