@import '../../styles/main.scss';

$column-total: 12;

.zn-featured-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: $spacing-3 0 $spacing-3;
    @media #{$bp-m} {
        padding: $spacing-7 0 $spacing-7;
        flex-direction: row-reverse;
    }

    &--blue {
        background-color: $torys-blue;
    }

    &--navy {
        background-color: $dark-blue;
    }
    &--white &__title {
        color: $black;
    }
    &--white &__description {
        color: $darker-grey;
    }

    &__image-display {
        max-width: 100%;
        width: 100%;
        display: flex;
        flex-direction: row;
        margin-bottom: $spacing-4;

        @media #{$bp-m} {
            margin-bottom: 0;
            width: #{(6 * 100 / $column-total) + '%'};
            max-width: #{(6 * 100 / $column-total) + '%'};
        }

        &--accent {
            border-right-style: solid;
            border-right-width: $spacing-2;

            @media #{$bp-m} {
                border-right-width: $spacing-3;
            }
        }

        &--teal {
            border-color: $teal;
        }

        &--orange {
            border-color: $orange;
        }

        &--blue {
            border-color: $torys-blue;
        }

        &--navy {
            border-color: $dark-blue;
        }
    }

    &__image {
        width: 100%;
        max-width: 100%;
        object-fit: cover;
        max-height: 100%;
        height: 55vw;

        @media #{$bp-m} {
            height: auto;
        }
    }

    &__content {
        max-width: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @media #{$bp-m} {
            width: #{(6 * 100 / $column-total) + '%'};
            max-width: #{(6 * 100 / $column-total) + '%'};
            margin-right: $spacing-4;

            &--full-width {
                width: 100%;
                max-width: 100%;
                margin-right: $spacing-8;
            }
        }

        &--dark {
            color: $white;
        }
    }

    &__title {
        @include Display-3();
        margin-bottom: $spacing-4;
        line-height: toRem(36px);

        @media #{$bp-m} {
            @include Display-2();
        }
    }

    &__description {
        @include body-base;
        margin-bottom: $spacing-4;
        line-height: toRem(27px);
        font-size: toRem(16px);

        @media #{$bp-s} {
            font-size: toRem(18px);
        }
        &--insight {
            position: relative;
            div {
                * {
                    display: none;
                }
            }

            div > :first-child {
                display: block;
                max-height: toRem(148px);
                text-overflow: ellipsis;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 5;
                -webkit-box-orient: vertical;
                * {
                    display: inline-block;
                }
            }
        }
    }

    .zn-button {
        padding: 0;
    }

    &__animate {
        .zn-featured-content {
            &__content {
                @include fadeIn();
            }

            &__image-display {
                @include fadeIn();
                @include imageHoverZoom('.zn-featured-content__image');
            }
        }
    }
}
