@import '../../styles/main.scss';

$column-full-padding: 160px;
$no-image-column-width: #{(8 * 100 / 12) + '%'};

.zn-profile-hero {
    color: $white;
    background-color: $dark-blue;
    padding: $spacing-4 $spacing-3;

    @media #{$bp-m} {
        padding: toRem(64px) 0;
    }

    &__columns {
        display: flex;
        flex-direction: column;

        @media #{$bp-m} {
            flex-direction: row;
        }
    }

    &__photo-col {
        display: flex;
        justify-content: center;
        
        @media #{$bp-m} {
            justify-content: start;
            padding-left: toRem($column-full-padding);
            width: 50%;
            max-width: 50%;
        }
    }

    &__image {
        margin-bottom: $spacing-3;
        
        @media #{$bp-m} {
            margin: 0 toRem(119px) 0 0;

        }

        img {
            object-fit: contain;
            height: auto;
            max-height: 100%;
            max-width: 100%;
            width: 100%;
        }
    }

    &__info-col {
        width: 100%;
        display: flex;
        flex-direction: column;

        @media #{$bp-m} {
            padding: 0 toRem($column-full-padding) 0 0;
            margin-bottom: toRem(60px);
            width: 50%;
            max-width: 50%;
        }

        &--no-image {

            @media #{$bp-m} {
                max-width: 100%;
                width: 100%;
                margin-bottom: 0;
                padding-left: toRem($column-full-padding);
                padding-right: toRem($column-full-padding);
            }
        }
    }

    &__icon-bar {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        width: 100%;
        order: 4;
        padding-top: $spacing-3;

        @media #{$bp-m} {
            padding-top: 0;
            order: 1;
            justify-content: flex-end;
        }
    }
    
    &__icon-element{
        display: flex;
        flex-direction: row;
        align-self: center;
        margin: 0 0.5rem;

        &:first-child {
            margin-left: 0;
            margin-right: 0;

            @media #{$bp-m} {
                margin-right: 0.5rem;
            }
        }

        &:last-child {
            margin-right: 0;
            

        }
    }

    &__icon-element:empty {
        margin: 0;
    }

    &__download {
        display: flex;
        flex-direction: row;
        color: $dark-blue;
        margin-left: 0;
        margin-right: 0.5rem;

        @media #{$bp-m} {
            margin-left: 0.5rem;

        }
    }

    &__download-link {
        color: $white;
        text-decoration: none;
        display: flex;
        align-items: center;
        cursor: pointer;
    }

    &__info-text--no-image {

        @media #{$bp-m} {
            width: $no-image-column-width;
            max-width: $no-image-column-width;
        }
    }

    &__title {
        @include Display-2();
        order: 1;
        word-wrap: break-word;
        @media #{$bp-m} {
            margin-top: $spacing-2;
        }
    }

    &__position {
        @include All-Caps();
        order: 2;
        margin-top: $spacing-3;

        @media #{$bp-m} {
            margin-top: toRem(21px);
        }
    }

    &__accent {
        width: toRem(80px);
        height: toRem(5px);
        margin-top: $spacing-3;
        background-color: $teal;
        order: 3;

        @media #{$bp-m} {
            margin-top: toRem(17px);
        }

    }

    &__description {
        @include Body-Deck();
        font-family: $heading-secondary-font-family; 
        margin: toRem(40px) 0 $spacing-4 0;
        order: 5;
         
        @media #{$bp-m} {
            margin-top: toRem(20px);
        }

        &--no-image {
            @media #{$bp-m} {
                width: $no-image-column-width;
                max-width: $no-image-column-width;
            }
        }
    }

    &__contact-accent {
        width: 100%;
        height: toRem(1px);
        background-color: $white;
        opacity: 0.25;
        order: 6;

        &--no-image {
            @media #{$bp-m} {
                width: $no-image-column-width;
                max-width: $no-image-column-width;
            }
        }
    }

    &__contact-bar {
        display: flex;
        flex-direction: row;
        margin-top: $spacing-4;
        justify-content: space-between;
        order: 7;

        &--no-image {
            @media #{$bp-m} {
                width: $no-image-column-width;
                max-width: $no-image-column-width;
            }
        }
    }

    &__accent + &__contact-accent {
        margin-top: 1rem
    }
    
    &__contact-title {
        @include All-Caps();

    }

    &__contact-info {
        @include Body-Medium(); 
        text-align: right;
    }

    &__phone-numbers {
        margin-bottom: $spacing-2;
        list-style-type: none;
        margin: 0;
        padding: 0;
    }

    &__phone-number {
        @include contact-link();
        margin-left: $spacing-1;
    }

    &__email {
        @include contact-link();
    }
}