@import '../../styles/main.scss';

.zn-in-page-navigation {
    $initial-z-index: 6; // Main navigation z-index is 7. Other components are around 2-3

    padding: 0 $spacing-3;
    display: flex;
    justify-content: center;
    position: sticky;
    top: $navigation-height-mobile; // Stack under the main navigation in a sticky fashion
    z-index: $initial-z-index;
    background-color: $white;
    scroll-behavior: smooth;
    max-width: 100%;
    width: 100%;
    height: $navigation-height-mobile;

    &--sc-editing {
        position: relative;
    }

    @media #{$bp-s} {
        padding: $spacing-3 toRem(74px);
        height: $navigation-height;
    }
    
    @media #{$bp-m} {
        top: $navigation-height; // Stack under the main navigation in a sticky fashion
        padding: $spacing-3 responsive-spacing($screen-m, $screen-xxl, 74px, 165px);
    }
    @media #{$bp-xxl} {
        padding: $spacing-3 toRem(165px);
    }
    &__container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        background-color: $white;
        scroll-behavior: smooth;
        max-width: toRem($section-content-max-width);
    }

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 100%;
        align-items: center;
        padding: toRem(2px) 0;

        @media #{$bp-s} {
            flex-direction: row;
            overflow: hidden;
        }
    }

    &__desktop-content {
        display: none;
        position: relative;

        @media #{$bp-s} {
            display: flex;
            flex-direction: row;
            align-items: center;
        }
    }

    &__title {
        @include Display-6();
        padding-right: $spacing-3;
        border-right: 1px solid $darker-grey;
        white-space: nowrap;
    }

    nav {
        padding: $spacing-3 $spacing-3;
        @media #{$bp-s} {
            padding: 0;
            display: block !important;
        }
    }

    &__sections {
        position: absolute;
        top: 100%;
        width: 100%;
        left: 0;
        background-color: $bg-blue;
        z-index: $initial-z-index + 1;
        
        @media #{$bp-s} {
            position: relative;
            background-color: transparent;
            width: auto;
        }
    }

    &__list {
        display: flex;
        max-width: 100%;
        list-style-type: none;
        flex-direction: column;
        align-items: flex-start;
        margin: 0;
        padding: 0;
        scroll-behavior: smooth;

        @media #{$bp-s} {
            flex-direction: row;
            overflow-x: auto;
            white-space: nowrap;
            max-width: none;
            align-items: center;
        }

        &--sc-editing {
            width: 100%;
            overflow-x: hidden;
            padding-right: 150px;
            white-space: normal;
        }
    }

    &__list::-webkit-scrollbar {
        display: none;
    }
      
    &__list {
        -ms-overflow-style: none;  
        scrollbar-width: none; 
    }

    &__icon {
        z-index: $initial-z-index + 3;
        background-color: $white;
        border: none;
        position: absolute;
        font-size: 0;
        
        &--right {
            right: 100%;
        }

        &--left {
            left: 100%;
            position: absolute;
        }
    }

    &__button-icon {
        background-color: transparent;
        border: none;
        box-shadow: none;
        
        &--right {
            padding: 0 toRem(11px) 0 $spacing-3;
        }

        &--left {
            padding: 0 $spacing-3;
        }
    }

    &__button-icon:hover {
        cursor: pointer;
    }

    &__share-content {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        position: relative;
        flex-grow: 1;
    }

    &__info {
        display: flex;
        flex-direction: row;
        align-items: center;
        border: none;
        outline: none;
        width: 100%;
        cursor: pointer;
        background-color: $white;

        @media #{$bp-s} {
            display: none;
        }
    }

    &__mobile-title {
        @include Display-6();
        color: $black;
        padding-right: $spacing-2;
    }

    &__mobile-icon {
        color: $torys-blue;
        display: inline-block;
    }

    &__button-desktop {
        display: none;
        @media #{$bp-s} {
            display: block;
            margin-left: $spacing-4;
        }
    }

    &__button-mobile {
        display: block;
        margin-top: toRem(10px);

        @media #{$bp-s} {
            display: none;
        }

        .zn-button {
            width: auto;
        }
    } 

    .zn-icon-dropdown {
        &--sc-editing {
            margin-left: 0;
        }

        &__popup {
            z-index: $initial-z-index + 4;

            &--hidden {
                z-index: -1;
            }
        }
    }

    &__dropdown {
        &--no-cta {
            @media #{$bp-s} {
                margin-left: toRem(30px);
            }
        }
    }
}

// Used so that the scroll margin its bigger due to both navs stacking 
.zn-section.zn-section--in-page-nav-current {
    scroll-margin-top: calc(#{$navigation-height-mobile} + #{$navigation-height});
    @media #{$bp-m} {
        scroll-margin-top: calc(#{toRem(82px)} + #{$navigation-height});
    }
}
