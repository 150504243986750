@import '../../styles/main.scss';


.zn-quarterly-introduction-list-item {
    margin-top: $spacing-3;

    &__link {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        text-decoration: none;
        margin-bottom: $spacing-3;
        color: $white;
        border-left: solid 4px;

        &--teal {
            border-color: $teal;
        }
    
        &--orange {
            border-color: $orange;
        }
    
        &--navy {
            border-color: $dark-blue;
        }
    
        &--blue {
            border-color: $torys-blue;
        }

        &--grey {
            border-color: $light-grey;
        }
    }

    &__link:hover{
        text-decoration: none;
        color: $bg-blue;
        cursor: pointer;
                
        @media #{$bp-s} {
            .zn-quarterly-introduction-list-item {
                &__link-icon {
                    transform: translateX(0.5rem);
                    transition: color 0.3s ease, transform 0.3s ease;
                }
            }
        }
    }

    &__link-text {
        padding-left: $spacing-3;
        transition: color 0.3s;
    }

    &__link-icon {
        transition: all 0.3s;
    }

    &__list-accent {
        width: 100%;
        background-color: $white;
        opacity: 0.20;
        height: 1px;
    }
}