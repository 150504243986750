@import '../../styles/main.scss';

.zn-article-heading {
    @include Display-3();
    margin-bottom: $spacing-4;

    &--medium {
        @include Display-5();
        margin-bottom: $spacing-3;
    }

    &--small {
        @include Display-6();
        margin-bottom: $spacing-1;
    }

    &--orange {
        color: $orange;
    }

    &--blue {
        color: $torys-blue;
    }

    &--teal {
        color: $teal;
    }

    &--black {
        color: $black;
    }

    &--light,
    &--navy {
        color: $dark-blue;
    }

    &--dark {
        color: $white;
    }

    &--padding-bottom-small {
        margin-bottom: $spacing-1;
    }

    &--padding-bottom-medium {
        margin-bottom: $spacing-3;
    }

    &--padding-bottom-large {
        margin-bottom: $spacing-5;
    }

    &--padding-top-small {
        margin-top: $spacing-1;
    }

    &--padding-top-medium {
        margin-top: $spacing-3;
    }

    &--padding-top-large {
        margin-top: $spacing-5;
    }
}
