@import '../../styles/main.scss';

.zn-colored-list {
    margin-bottom: $spacing-5;
}

.zn-colored-list__content {
    border-left: 4px solid $orange;
    padding: 0 $spacing-5;
    margin-bottom: 0;

    &--teal {
        border-left-color: $teal;
    }

    &--orange {
        border-left-color: $orange;
    }

    &--blue {
        border-left-color: $torys-blue;
    }

    &--navy {
        border-left-color: $dark-blue;
    }

    &--grey {
        border-color: $light-grey;
    }
}

.zn-colored-list__title {
    color: $primary-black;
}

.zn-colored-list__item {
    list-style: none;
    text-decoration: none;
    margin-bottom: $spacing-3;

    * {
        color: $dark-grey;
        margin: 0;
        line-height: 1.7;
    }

    &:last-child {
        margin-bottom: 0;
    }
}
