@import '../../styles/main.scss';


.zn-in-page-navigation-item {
    
    @include Body-Medium();
    color: $darker-grey;
    display: inline-block;
    margin-bottom: toRem(12px);

    @media #{$bp-s} {
        margin-bottom: 0;
        
        &.current {
            color: $torys-blue;
        }
    }

    &:last-child {
        margin-bottom: 0;
    }

    &:hover {
        cursor: pointer;
        color: $torys-blue;
        border-color:  $torys-blue;
        text-decoration: none;
    }

    &__link {
        color: inherit;
        display: block;
        text-decoration: none;

        @media #{$bp-s} {
            margin-left: $spacing-3;
        }
    }

    &__link:focus {
        color: $torys-dark-blue;
    }

    &__link:hover {
        text-decoration: none;
    }
}