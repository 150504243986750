@import '../../styles/main.scss';

.zn-office-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: $spacing-5 $spacing-3;
    width: 100%;
    &--white {
        background-color: $white;
    }
    &--lightblue {
        background-color: $bg-blue;
    }
    @media #{$bp-m}{
        flex-direction: row;
        padding: $spacing-5 0;
    }

    &__content {
        display: flex;
        flex-direction: column;
        @media #{$bp-m}{
            width: 50%;
            padding-left: toRem(165px);
            margin-right: $spacing-9;
            .zn-heading-accent {
                margin-top: $spacing-3;
            }
        }
    }
    &__title {
        @include Display-3();
        color: $black;
    }
    &__accent {
        width: toRem(80px);
        height: toRem(4px);
        margin: $spacing-4 0;

        @media #{$bp-m}{
            margin-bottom: $spacing-5;
            margin-top: $spacing-3;
        }

        &--orange {
            background-color: $orange;
        }
        &--teal {
            background-color: $teal;
        }
        &--blue {
            background-color: $torys-blue;
        }
        &--navy {
            background-color: $dark-blue;
        }
    }
    &__description {
        @include Body-Regular();
        color: $darker-grey;
        margin-bottom: $spacing-3; 
        @media #{$bp-m}{
            margin-bottom: $spacing-2;
        }
    }
    .zn-button {
        @include Button-Medium();
        padding: 0;
        margin-bottom: $spacing-3;
        @media #{$bp-m}{
            margin-bottom: $spacing-5;
        }
    }
    &__location-info {
        @include Body-Regular();
        color: $darker-grey;
        margin-bottom: $spacing-4;
        @media #{$bp-m}{
            margin-bottom: 0;
        }
    }
    &__image {
        width: 100%;
        height: auto;
        
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }

        @media #{$bp-m}{
            width: 100%;
            max-width: toRem(613px);
            max-height: auto;
            height: auto;
        }
    }
}