@import '../../styles/main.scss';

$section-mobile-y-padding: toRem(64px);
$section-mobile-x-padding: $spacing-3;
$section-y-padding: toRem(54px); 
$section-x-padding: toRem(72px); 
$section-large-y-top-padding: toRem(193px); 
$section-large-y-bottom-padding: toRem(201px); 
$section-large-x-padding: toRem(128px);

.zn-quarterly-introduction {
    display: flex;
    flex-direction: row;
    position: relative;
    
    &__background-image {
        @include desktop-only();
        background-position: center;
        position: absolute;
        z-index: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-size: cover;
    }

    &__content {
        z-index: 1;
        background-color: $torys-blue;
        height: 100%;
        width: 100%;
        margin-left: auto;
        padding: $section-mobile-y-padding $section-mobile-x-padding;
        
        @media #{$bp-s} {
            width: 55%;
            padding: $section-y-padding $section-x-padding;
        }

        @media #{$bp-m} {
            padding: $section-large-y-top-padding $section-large-x-padding $section-large-y-bottom-padding;
        }
    }

    &__paragraph {
        @include heading-base;
        font-family: $heading-font-family;
        color: $white;
        line-height: 1.5;
        font-size: toRem(24px);
        margin-bottom: $spacing-6;
        
        @media #{$bp-s} {
            line-height: 1.375;
            margin-bottom: toRem(72px);
        }
    
        @media #{$bp-m} {
            font-size: toRem(32px);
        }
    }

    &__sections-title {
        @include Display-4();
        color: $white;
        margin-bottom: $spacing-3;
    }

    &__accent {
        width: 100%;
        background-color: $white;
        opacity: 0.20;
        height: 1px;
    }

    &__list {
        list-style-type: none;
        padding: 0;
        margin: 0;
    }
}
