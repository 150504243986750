@import '../../styles/main.scss';

.zn-footnotes-item {
    @include Body-Regular();
    margin-bottom: toRem(12px);
    color: $darker-grey;
    display: flex;
    flex-direction: row;
    counter-increment: li-count;

    span {
        padding-left: $spacing-2;
    }

    &__counter {
        &--link {
            color: $torys-blue;
            text-decoration: underline;
            font-size: 1rem;
            line-height: 1.5;
            @media screen and (min-width: 768px) {
                font-size: 1.125rem;
                line-height: 1.77;
            }
        }
    }

    &__counter:before {
        content: counter(li-count) '.';
    }
}
