@import '../../styles/main.scss';

$content-max-width: toRem(870px);
$two-column-content-max-width: toRem(1101px);
$image-height: toRem(700px);
$background-with-image-max-width: toRem(800px);

.zn-article-hero {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    &__image {
        display: block;
        width: 100%;
        max-width: 100%;
        height: auto;

        @media #{$bp-m} {
            display: none;
        }
    }

    &__description {
        @include Body-Deck();
        margin-top: $spacing-4;
        font-family: $heading-secondary-font-family;
    }

    @include pdf-hero();
}

.zn-article-hero__background {
    background-color: $bg-blue;
    padding: toRem(56px) $spacing-3;
    width: 100%;

    @media #{$bp-m} {
        padding: toRem(64px) toRem(50px);
    }

    &--blue {
        background-color: $torys-blue;
        color: $white;
        .zn-article-hero-content__eyebrow {
            color: $white;
        }
    }

    &--navy {
        background-color: $dark-blue;
        color: $white;
        .zn-article-hero-content__eyebrow {
            color: $white;
        }
    }
}

.zn-article-hero--image {
    @media #{$bp-m} {
        height: $image-height;
        display: flex;
        align-items: flex-end;
    }

    .zn-article-hero__background {
        padding: $spacing-5 $spacing-3;
        @media #{$bp-m} {
            padding: toRem(64px) toRem(50px) toRem(64px) toRem(165px);
            max-width: $background-with-image-max-width;
        }
    }
}

.zn-article-hero-content {
    max-width: $content-max-width;
    margin: 0 auto;

    &--two-col-width {
        max-width: $two-column-content-max-width;
    }

    &__eyebrow {
        @include eyebrow;
        color: $darker-grey;
        display: inline-block;
        margin-bottom: $spacing-3;
    }

    &__eyebrow-divider {
        margin: 0 $spacing-3;
    }

    .zn-article-hero-content__title {
        @include Display-2();
    }

    .zn-button {
        margin-top: $spacing-5;
    }
}

.zn-article-hero-content__accent {
    width: toRem(80px);
    height: toRem(5px);
    margin-top: $spacing-3;
    background-color: $teal;
    order: 3;

    &--orange {
        background-color: $orange;
    }

    &--blue {
        background-color: $torys-blue;
    }

    &--navy {
        background-color: $dark-blue;
    }
    &--white {
        background-color: $white;
    }
}
