@import '../../styles/main.scss';

.zn-section-heading {
    color: $black;
    display: flex;
    flex-direction: column;
    align-items: inherit;
    width: 100%;

    &--pd-small {
        padding-bottom: $spacing-4;

        @media print {
            margin-bottom: 0.22in;
        }
    }

    &--pd-medium {
        padding-bottom: $spacing-5;
    }

    &--pd-large {
        padding-bottom: $spacing-6;
    }

    &--pt-small {
        padding-top: $spacing-3;
    }

    &--pt-medium {
        padding-top: $spacing-5;
    }

    &--pt-large {
        padding-top: $spacing-6;
    }

    &--dark {
        color: $white;
    }

    &--center {
        align-items: center;
        text-align: center;
    }

    &--left {
        align-items: flex-start;
        text-align: left;
    }

    &--right {
        align-items: flex-end;
        text-align: right;
    }

    &__text {
        @include Display-2();
        margin: 0;
        padding: 0;
        overflow: hidden;

        &--medium {
            @include Display-3();
        }

        &--eyebrow {
            @include All-Caps();

            &--light {
                opacity: 0.25;
            }
        }

        @media print {
            font-size: 0.276in;
            margin-bottom: 0;
            padding-bottom: 0;
        }
    }

    &__text-animation {
        $anim_offset: 100%;
        position: relative;
        transform: translate(0%, $anim_offset);
        top: $anim_offset;
        animation-name: section-heading-fade-up;
        animation-play-state: paused;
        animation-fill-mode: forwards;
        animation-duration: 850ms;
        animation-delay: 0ms;

        @keyframes section-heading-fade-up {
            from {
                transform: translate(0%, $anim_offset);
                top: $anim_offset;
            }
            to {
                transform: translate(0%, 0%);
                top: 0%;
            }
        }
    }

    &__animation-start {
        .zn-section-heading__text-animation,
        .zn-heading-accent__animation {
            animation-play-state: running;
        }
    }

    @media print {
        padding-bottom: 0;
        padding-top: 0;
        page-break-after: avoid;
        margin-bottom: 0.28in !important;
        page-break-inside: avoid;
    }
}
