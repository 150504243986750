@import '../../styles/main.scss';

$column-total: 12;
$column-full-pading: 160px;
$column-x-padding-2560: 240px;
$column-x-padding-2560-border: 320px;
$column-x-padding-3840: 420px;
$column-x-padding-3840-border: 680px;

.zn-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: $spacing-3;
    padding-left: $spacing-3;
    padding-right: $spacing-3;
    padding-bottom: 0;

    @media #{$bp-m} {
        padding-top: 0;
    }

    &:not(:first-child) {
        @media screen and (max-width: #{$screen-m}) {
            padding-top: $spacing-4;
        }
    }

    .zn-button {
        align-self: flex-start;
    }

    &--padding-left--full {
        @media #{$bp-m} {
            padding-left: responsive-spacing($screen-m, $screen-xxl, 40px, $column-full-pading);
        }

        @media #{$bp-xxl} {
            // 1920px
            padding-left: responsive-spacing(
                $screen-xxl,
                $screen-2560,
                $column-full-pading,
                $column-x-padding-2560
            );
        }

        @media #{$bp-2560} {
            padding-left: responsive-spacing(
                $screen-2560,
                $screen-3840,
                $column-x-padding-2560,
                $column-x-padding-3840
            );
        }

        &:first-child {
            @media #{$bp-xxl} {
                // 1920px
                padding-left: responsive-spacing(
                    $screen-xxl,
                    $screen-2560,
                    $column-full-pading,
                    $column-x-padding-2560-border
                );
            }

            @media #{$bp-2560} {
                padding-left: responsive-spacing(
                    $screen-2560,
                    $screen-3840,
                    $column-x-padding-2560-border,
                    $column-x-padding-3840-border
                );
            }
        }
    }

    &--padding-right--full {
        @media #{$bp-m} {
            padding-right: responsive-spacing($screen-m, $screen-xxl, 40px, $column-full-pading);
        }

        @media #{$bp-xxl} {
            // 1920px
            padding-right: responsive-spacing(
                $screen-xxl,
                $screen-2560,
                $column-full-pading,
                $column-x-padding-2560
            );
        }

        @media #{$bp-2560} {
            padding-right: responsive-spacing(
                $screen-2560,
                $screen-3840,
                $column-x-padding-2560,
                $column-x-padding-3840
            );
        }

        &:last-child {
            @media #{$bp-xxl} {
                // 1920px
                padding-right: responsive-spacing(
                    $screen-xxl,
                    $screen-2560,
                    $column-full-pading,
                    $column-x-padding-2560-border
                );
            }

            @media #{$bp-2560} {
                padding-right: responsive-spacing(
                    $screen-2560,
                    $screen-3840,
                    $column-x-padding-2560-border,
                    $column-x-padding-3840-border
                );
            }
        }
    }

    &--padding-left--half {
        @media #{$bp-m} {
            padding-left: toRem($column-full-pading/2);
        }
    }

    &--padding-right--half {
        @media #{$bp-m} {
            padding-right: toRem($column-full-pading/2);
        }
    }

    &--padding-left--none {
        @media #{$bp-m} {
            padding-left: 0;
        }
    }

    &--padding-right--none {
        @media #{$bp-m} {
            padding-right: 0;
        }
    }

    &--has-y-padding {
        $y-padding: 80px;

        @media #{$bp-m} {
            padding-bottom: responsive-spacing($screen-m, $screen-xl, 64px, $y-padding);
            padding-top: responsive-spacing($screen-m, $screen-xl, 64px, $y-padding);
        }

        @media #{$bp-xl} {
            // 1400px
            padding-bottom: toRem($y-padding);
            padding-top: toRem($y-padding);
        }
    }

    &--hide-mobile {
        display: none;
        @media #{$bp-m} {
            display: flex;
        }
    }

    &--vertical-top {
        justify-content: flex-start;
    }

    &--vertical-center {
        justify-content: center;
    }

    &--vertical-bottom {
        justify-content: flex-end;
    }

    &--vertical-space-between {
        justify-content: space-between;
    }

    .zn-rich-text {
        table {
            display: table;
            table-layout: fixed;
            width: 100%;
        }
        thead {
            display: table;
            table-layout: fixed;
        }
        tbody {
            display: table;
            table-layout: fixed;
        }
        td {
            min-width: 0 !important;
        }
    }

    @media print {
        padding-left: 0;
        padding-right: 0;
    }
}

@for $i from 1 through $column-total {
    .zn-column--#{$i} {
        max-width: 100%;
        @media #{$bp-m} {
            width: #{($i * 100 / $column-total) + '%'};
            max-width: #{($i * 100 / $column-total) + '%'};
        }
    }
}
