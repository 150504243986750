@import '../../styles/main.scss';

.zn-knowledge-hub-date {
    background-color: $dark-blue;
    color: $white;
    font-weight: 400;
    width: 100%;
    max-width: 100%;
    min-height: $spacing-9;
    display: flex;
    border-bottom: 1px solid rgba($color: $light-grey, $alpha: 0.25);

    @media #{$bp-s} {
        padding-left: $spacing-2;
    }

    &__date {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        min-width: toRem(64px);
        margin-left: $spacing-2;
        margin-right: $spacing-4;
    }

    &__month {
        @include eyebrow();
        letter-spacing: 1.5px;
    }

    &__day {
        @include Display-2();
    }

    &__info {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-top: $spacing-4;
        padding-bottom: $spacing-4;
    }

    &__title {
        @include eyebrow();
        letter-spacing: 1.5px;
        margin-bottom: $spacing-1;
    }

    &__link {
        @include Display-6();
        font-weight: normal;

        a {
            color: white;
            text-decoration: none;
        }

        a:hover {
            text-decoration: underline;
        }
    }
}
