@import '../../styles/main.scss';

.zn-tag-bubble {
    background-color: $torys-blue-transparent;
    border: 1px solid $torys-blue;
    border-radius: 100px;
    display: inline-flex;
    align-items: center;
    padding: $spacing-2 $spacing-3;
    word-break: break-all;

    .zn-button-simple {
        padding-left: toRem(12px);
    }
}
