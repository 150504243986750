@import '../../styles/main.scss';

.zn-footer {
    display: flex;
    justify-content: center;
    background-color: $dark-blue;
    color: $white;

    &__container {
        @include container();

        @media #{$bp-m} {
            padding-top: $spacing-7;
            padding-bottom: $spacing-4;
        }

        @media #{$bp-l-scaling} {
            max-width: 1250px;
            padding-left: 0;
            padding-right: 0;
        }
    }

    &__column-wrapper {
        display: flex;
        flex-direction: column;

        @media #{$bp-m} {
            flex-direction: row;
            margin-bottom: 4.375rem;
        }
    }

    &__logo {
        margin-bottom: $spacing-5;
        width: toRem(117px);
        min-width: toRem(117px);

        @media #{$bp-m} {
            margin-bottom: 0;
            margin-right: 4.5rem;
        }

        img {
            max-height: 100%;
            max-width: 100%;
            height: auto;
            width: 100%;
        }
    }

    &__nav-list {
        @include list-with-title();

        @media #{$bp-m} {
            margin: 0 $spacing-3 0 0;
            width: 20%;
        }
    }

    &__column-title {
        @include Display-4();
        display: inline-block;
        text-decoration: none;
        margin-bottom: $spacing-3;

        > a {
            color: inherit;
            cursor: pointer;

            &:hover {
                color: $white;
                text-decoration: underline;
            }
        }
    }

    &__column-list-item {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    &__column-list-link {
        @include standard-link();
        display: inline-block;
        margin-bottom: $spacing-1;

        @media #{$bp-m} {
            margin-bottom: $spacing-2;
        }

        &:hover {
            color: $bg-blue;
        }
    }

    &__subscribe-teaser {
        @include list-with-title();
    }

    &__subscribe-title {
        @include Display-4();
        color: inherit;
    }

    &__subscribe-button {
        @include Display-6();
        margin-top: $spacing-2;
    }

    &__subscribe-subtext {
        width: 100%;
        margin-bottom: 0;
        margin-top: $spacing-4;
        color: inherit;

        @media #{$bp-m} {
            max-width: 350px;
        }
    }

    &__divider {
        width: 100%;
        height: 1px;
        background-color: $bg-blue;
        border: none;
        margin: 0;
        padding: 0;
    }

    &__info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-top: $spacing-3;
        margin-top: $spacing-2;

        @media #{$bp-m} {
            flex-direction: row;
            align-items: center;
            margin-top: 0;
        }
    }

    &__legal-list {
        display: flex;
        flex-direction: column;

        @media #{$bp-m} {
            flex-direction: row;
        }

        > ul {
            list-style-type: none;
            margin: 0;
            padding: 0;
        }
    }

    &__legal-title {
        @include Body-Regular();
        margin-right: $spacing-4;
        margin-bottom: $spacing-1;
        color: inherit;

        @media #{$bp-m} {
            margin-bottom: 0;
        }
    }

    &__legal-list-item {
        margin-right: $spacing-4;
        margin-bottom: $spacing-1;
        color: inherit;

        @media #{$bp-m} {
            display: inline;
            margin-bottom: 0;
        }
    }

    &__legal-link {
        @include standard-link();
        display: inline-block;
        margin-bottom: 0;

        &:hover {
            color: $bg-blue;
        }
    }

    &__social-list {
        margin: 0;
        padding: 0;
        margin-top: $spacing-4;

        @media #{$bp-m} {
            margin-top: $spacing-1;
        }
    }
}
