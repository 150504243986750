@import '../../styles/main.scss';

.zn-quarterly-article-navigation-banner {
    width: 100%;
    background-color: $torys-dark-blue-transparent;
    padding: toRem(32px) toRem(16px);

    @media #{$bp-m} {
        padding: toRem(68px) toRem(165px);
    }

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        position: relative;

        @media #{$bp-m} {
           flex-direction: row;
        }
        
    }

    &__title-block {
        @include Display-3(); 
        display: none;
        
        @media #{$bp-m} {
            display: none;
            
            @media #{$bp-m} {
                display: block;
            }
        }
        
        &--mobile {
            display: block;
            margin-bottom: toRem(26px);

            @media #{$bp-m} {
                display: none;
            }
        }
    }

    &__title {
        text-align: center;
        
        @media #{$bp-m} {
            max-width: toRem(300px);
         }
    }

    &__links {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    &__link {
        @include Display-6();
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        text-decoration: none;
        color: $black;
    }

    &__link:hover {
        color: $torys-blue;
        text-decoration: none;
        
        .zn-quarterly-article-navigation-banner {

            &__text {
                &--left {
                    transform: translateX(0.5rem);
                    transition: color 0.3s ease, transform 0.3s ease;
                }
        
                &--right {
                    transform: translateX(-0.5rem);
                    transition: color 0.3s ease, transform 0.3s ease;
                }

                &--disabled {
                    color: $black;
                    opacity: 0.25;
                    transform: none;
                    transition: none;
                }
            }
        }
    }

    &__icon {
        display: flex;
        align-items: center;
        color: $torys-blue;

        &--left {
            margin-right: toRem(6px);
        }

        &--right {
            margin-left: toRem(6px);
        }

        &--disabled {
            color: $black;
            opacity: 0.25;
        }
    }

    &__text {
        color: inherit;
        transition: all 0.3s;

        &--disabled {
            opacity: 0.25;
        }

        &--right {
            text-align: right;
        }
    }
}