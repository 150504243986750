@import '../../styles/main.scss';

$search-max-width: 980px;

.zn-main-nav-bar {
    transition: $transition-ease;
    align-items: center;
    display: flex;
    justify-content: space-between;
    min-height: $navigation-height-mobile;
    padding: toRem(12px) $spacing-3;
    position: relative;
    z-index: $navigation-z-index + 1;

    @media #{$bp-m} {
        padding: toRem(12px) $spacing-4;
        line-height: 1.3;
        height: $navigation-height;
    }

    &__logo {
        display: inline-flex;
        max-height: toRem(28px);
        position: relative;
        width: toRem(89px);

        @media #{$bp-m} {
            width: toRem(164px);
        }
        .zn-button-simple {
            padding: 0;
        }

        img {
            max-height: 100%;
            max-width: 100%;
            height: auto;
            width: toRem(164px);
        }

        &.hide-mobile {
            display: none;
            @media #{$bp-m} {
                display: inline-flex;
            }
        }

        &.hide-all {
            visibility: hidden;
        }
    }

    a {
        cursor: pointer;
        display: flex;
    }

    &--dark {
        background-color: $dark-blue;
        color: $white;

        .zn-hide-light {
            display: none;
        }
    }

    .zn-main-nav-bar__menu-icons {
        display: flex;
        align-items: center;
    }

    .zn-main-nav-bar__search-icon {
        padding: 0;
    }

    .zn-search-form {
        width: 100%;
        max-width: $search-max-width;
    }

    .zn-main-nav-bar--hide-desktop {
        @media #{$bp-m} {
            display: none;
        }
    }

    .zn-main-nav-bar__search-mobile {
        margin-right: toRem(20px);
        @media #{$bp-m} {
            margin-right: toRem(30px);
        }
    }

    .zn-main-nav-bar--hide-mobile {
        display: none;
        @media #{$bp-m} {
            display: block;
        }
    }

    @media #{$bp-l-scaling} {
        margin: auto;
        max-width: 1250px;
        padding-left: 0;
        padding-right: 0;
    }
}

.zn-main-nav-bar__menu {
    display: none;
    @media #{$bp-l} {
        align-items: center;
        display: flex;
        justify-content: center;
    }

    .zn-main-nav-bar__menu__item {
        display: flex;
        align-items: center;
        list-style: none;
    }

    .zn-main-nav-bar__menu__item--search-icon {
        margin-left: 1rem;
    }
}

.zn-main-nav-bar--light {
    background-color: $bg-blue;
    color: $black;

    .zn-hide-dark {
        display: none;
    }

    .zn-main-nav-bar__hamburger {
        background-color: inherit;
        color: inherit;
        border: inherit;
        padding: 0;
        font: inherit;

        .zn-hamburger__line {
            border-color: $black;
        }
    }

    .zn-main-nav-bar__list {
        &__divider {
            border-color: $light-grey;
        }
    }
}

.zn-main-nav-bar__hamburger {
    background-color: inherit;
    cursor: pointer;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: toRem(44px);
    border: none;

    .zn-hamburger__line {
        display: block;
        border-top: 1px solid $white;
        border-radius: 1px;
        margin-bottom: 6px;
        width: 22px;

        &:last-child {
            margin: 0;
        }
    }

    .zn-main-nav-bar__hamburger_text {
        display: none;
        height: 16px;
        margin: 0;
        line-height: 1;
        margin-top: 6px;

        @media #{$bp-m} {
            display: block;
        }
    }

    .zn-button-simple--border {
        display: inline-flex;
        padding: toRem(8px);
        height: auto;
        width: auto;
        margin: 0;
        @media #{$bp-m} {
            border: 1px solid $light-grey;
        }
    }
}

.zn-main-nav-bar__list {
    padding: 0 24px;

    @media #{$bp-m} {
        display: flex;
        align-items: center;
        margin: 0;
    }

    &__divider {
        @media #{$bp-m} {
            border-right: 1px solid $white;
        }
    }
}
