@import '../../styles/main.scss';

.zn-project-map-article-table {
    border-collapse: separate;
    border-spacing: 0 0.5rem;
}

.zn-project-map-work-article-table {
    width: 100%;
    background-color: $bg-blue;
    padding: 0.5rem 1rem;
    display: table;

    @media #{$bp-m} {
        padding: 0.5rem 1rem;
    }

    table {
        width: 100%;
    }

    tbody {
        width: 100%;
    }

    tr {
        margin-bottom: $spacing-2;
    }


    &__rows {
        margin: 0;
        padding: 0;
        list-style-type: none;
    }
}

.zn-project-map-work-article-table-row {

    &__label {
        @include Display-7();
        padding-right: $spacing-2;
        min-width: 110px;
    }

    &__value {
        @include Body-Regular();
    }

    &__container {
        &--editing {
            display: flex;
            flex-direction: row;
            width: 100%;
            justify-content: space-between;
        }
    }

    &__divider-border {
       border-bottom: 1px solid $light-grey;
    }

    &__divider-padding-top {
        margin-top: $spacing-2;
    }

    &__divider-padding-bottom {
        margin-bottom: $spacing-2;
    }
}
