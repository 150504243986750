@import '../../styles/main.scss';

.zn-two-column-profile-list {
    display: flex;
    flex-direction: column;
    padding: $spacing-3 0 $spacing-2; 
    width: 100%;

    &--paragraph {
        padding: $spacing-4 0 toRem(52px); 

        @media #{$bp-s} {
            padding: toRem(40px) 0 toRem(80px); 
        }
    }

    ul {
        margin: 0;
        padding: 0;
    }
}

.zn-two-column-profile-list-item {
    @include Body-Medium();
    display: flex;
    flex-direction: column;
    color: $darker-grey;

    &__text {
        display: block;
        margin-bottom: $spacing-2;
        
        @media #{$bp-m} {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
        }
        &--paragraph {
            justify-content: flex-start;
            margin-bottom: 0;
        }
    }

    &__label {
        width: 100%;

        @media #{$bp-m} {
            min-width: toRem(128px);
            width: toRem(128px);
        }
        &--paragraph {
            @include All-Caps-Small();
            font-weight: 400;
            width: toRem(98px);
            min-width: toRem(98px);
            margin-right: $spacing-3;
        }
    }

    &__description {
      
        &--paragraph {
            color: $black;
        }
    }

    &__accent {
        width: 100%;
        background-color: $light-grey-45;
        height: toRem(1px);
        margin: $spacing-3 0;
    }
}

.zn-two-column-profile-list-item:last-child {
    
    .zn-two-column-profile-list-item__accent { 
        display: none;
    }
}
