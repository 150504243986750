
@import '../../styles/main.scss';

//animation variables 
$headline-text-delay: 540ms;
$headline-mobile-text-delay: 180ms;
$body-content-delay: 960ms;
$body-content-mobile-delay: 600ms;
$scroll-text-delay: 1860ms;
$overlay-fade-out-animation-duration: 750ms;
$vector-animation-duration: 900ms;
$vector-animation-mobile-duration: 780ms;
$vector-animation-delay: 150ms;
$vector-animation-mobile-delay: 0ms;
$heading-transition-duration: 1020ms;
$content-transition-duration: 600ms;
$scroll-triggered-fade-duration: 480ms;
$scroll-triggered-fade-in-delay: 540ms;
$scroll-triggered-fade-out-delay: 120ms;

.zn-quarterly-landing-hero-animation {
    position: absolute;
    width: 100%;
    height: 100%;

    &--editing {
        position: relative;
    }
    
    @media #{$bp-s} {
       width: 100%;
       height: 100%;
    }

    &.scroll-down-animation {
        opacity: 1;
        top: 0;
        animation-name: fade-out-hero-mobile;
        animation-play-state: running;
        animation-fill-mode: forwards;
        animation-duration: $scroll-triggered-fade-duration;
        animation-delay: $scroll-triggered-fade-out-delay;

        @media #{$bp-s} {
            animation-name: fade-out-hero;
            top: 0
        }
    }

    &.scroll-up-animation {
        opacity: 0;
        top: -100%;
        animation-name: fade-in-hero-mobile;
        animation-play-state: running;
        animation-fill-mode: forwards;
        animation-duration: $scroll-triggered-fade-duration;
        animation-delay: $scroll-triggered-fade-in-delay;

        @media #{$bp-s} {
            animation-name: fade-in-hero;
            top: 0;
        }
    }

    @keyframes fade-out-hero {
        0% {
           opacity: 1;
        }
        100% { 
            opacity: 0;
        }
    }

    @keyframes fade-in-hero {
        0% {
           opacity: 0;
        }
        100% { 
            opacity: 1;
        }
    }

    @keyframes fade-out-hero-mobile {
        0% {
           opacity: 1;
           top: 0;
        }
        100% { 
            opacity: 0;
            top: -100%;
        }
    }

    @keyframes fade-in-hero-mobile {
        0% {
           opacity: 0;
           top: -100%;
        }
        100% { 
            opacity: 1;
            top: 0;
        }
    }

    &__overlay {
        background-color: $white;
        width: 100%;
        height: 100%;
        position: absolute;
        $anim_offset: 100%;
        animation-name: fade-out-animation;
        animation-play-state: paused;
        animation-fill-mode: forwards;
        animation-duration: $overlay-fade-out-animation-duration;
        animation-delay: 0ms;
        display: none;

        &--editing {
            opacity: 0;
            z-index: -1;
        }

        &--white {
           background-color: $white;
        }

        &--grey {
            background-color: $bg-blue;
        }

        &--navy {
            background-color: $dark-blue;
        }

        @media #{$bp-s} {
            display: block;
         }

        @keyframes fade-out-animation {
            from {
               opacity: 1;
            }
            to { 
                opacity: 0;
                z-index: -1;
            }
        }
    }

    &__vector {
        background-color: $white;
        width: 100%;
        height: 100%;
        position: absolute;
        $anim_offset: 100%;
        animation-name: polygon-animation-mobile;
        animation-play-state: paused;
        animation-fill-mode: forwards;
        animation-duration: $vector-animation-mobile-duration;
        animation-delay: $vector-animation-mobile-delay;
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);

        &--white {
            background-color: $white;
        }
 
         &--grey {
             background-color: $bg-blue;
        }
 
         &--navy {
             background-color: $dark-blue;
        }

        @media #{$bp-s} {
            animation-name: polygon-animation;
            animation-duration: $vector-animation-duration;
            animation-delay: $vector-animation-delay;
            clip-path: polygon(0 0, 0 0, -28.16% 100%, 0% 100%);
        }

        @keyframes polygon-animation {
            0% { clip-path: polygon(0 0, 0 0, -28.16% 100%, 0% 100%);}
            100% { clip-path: polygon(0 0, 91.77% 0, 63.61% 100%, 0 100%);}

        }

        @keyframes polygon-animation-mobile { 
            0% {clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);}         
            100% {clip-path: polygon(0 0, 100% 0, 100% 75.1%, 0 75.1%);}
        }
    }

    &__animation-start {
        .zn-quarterly-landing-hero-animation__vector, 
        .zn-quarterly-landing-hero-animation__overlay, 
        .zn-quarterly-landing-hero__title {
            animation-play-state: running;
        }
    }

    &__accent {
        @include expandRight();

        &.expandRight {
            transition-delay: $body-content-mobile-delay;
            transition-duration: $content-transition-duration;
            
            @media #{$bp-s} {
                transition-delay: $body-content-delay;
            }
        }

        width: toRem(95px);
        height: toRem(5px);
        margin: $spacing-4 0;

        @media #{$bp-s} {
            width: toRem(80px);
            height: toRem(4px);
        }

        &--grey {
            background-color: $dark-grey;
        }
        &--white {
            background-color: $white;
        }
        &--black {
            background-color: $black;
        }
        &--blue {
            background-color: $torys-blue;
        }
        &--teal {
            background-color: $teal;
        }
        &--orange {
            background-color: $orange;
        }
    }

    &__scroll-container {
        display: none;

        @media #{$bp-m} {
            display: block;
        }

        position: absolute;
        left: toRem(120px);
        bottom: 0;
        z-index: 2;
        height: toRem(121px);
        box-sizing: border-box;

        &-text {
            @include All-Caps();
            font-weight: normal;
            position: absolute;
            top: 0;
            left: toRem(28px);
            transform-origin: 0 0;
            transform: rotateZ(90deg);
            color: $black;

            &--navy {
                color: $white;
            }
        }

        &-line {
            border-left: 1px solid $black;
            height: 100%;

            &--navy {
                border-color: $white;
            }

        @include expandDown(){
            &.expandDown {
                transition-delay: $scroll-text-delay;
            }
        }

        };
        &-text {

            &--navy {
                color: $white;
            } 
            &.fadeIn {
                opacity: 0;
                top: -20px;
                transition: opacity 500ms ease, top 500ms ease;
                transition-delay: $scroll-text-delay;
            }
            &.fadeInActive {
                top: 0;
                opacity: 1;
            }
        }
    }
}

.zn-quarterly-landing-hero {
    &__eyebrow {

        &--editing {
            position: relative;
        }

        &--navy {
            color: $white;
        }

        @include fadeIn() {
            &.fadeIn {
                transition-delay: $headline-mobile-text-delay;
                transition-duration: $content-transition-duration;

                @media #{$bp-s} {
                    transition-delay: $headline-text-delay;
                }
            }
        };

    }

    &__title {

        &--editing {
            position: relative;
        }

        &--navy {
            color: $white;
        }

        @include headingFadeIn('.zn-quarterly-landing-hero__title-content'){
            &.headingFadeIn {
                .zn-quarterly-landing-hero__title-content {
                    transition-delay: $headline-mobile-text-delay;
                    transition-duration: $heading-transition-duration;

                    @media #{$bp-s} {
                        transition-delay: $headline-text-delay;
                    }
                }
            }
        };
    }

    &__description {

        &--editing {
            position: relative;
        }

        &--navy {
            color: $white;
        }

        @include fadeIn() {
            &.fadeIn {
                transition-delay: $body-content-mobile-delay;
                transition-duration: $content-transition-duration;

                @media #{$bp-s} {
                    transition-delay: $body-content-delay;
                }
            }
        };
    }
}