@import '../../styles/main.scss';

$image-size: toRem(256px);
$image-size-xs: toRem(106px);

.zn-item-with-image {
    padding: $spacing-3 0;
    display: flex;
    align-items: center;

    &--people-only {
        flex-direction: row-reverse;

        .zn-item-with-image__content {

            &--with-image {

                @media #{$bp-s} {
                    padding: 0 $spacing-3 0 $spacing-5;
                }
            }
        }
    }

    &__image {
        align-items: flex-start;
        display: flex;
        justify-content: center;
        min-width: $image-size;

        img {
            max-height: $image-size;
            max-width: $image-size;
            height: $image-size;
            width: $image-size;
            object-fit: cover;
        }

        &--xs {
            height: $image-size-xs;
            width: $image-size-xs;
            min-height: $image-size-xs;
            min-width: $image-size-xs;
            padding: $spacing-1;
        }
    }

    &__content {
        flex-grow: 1;
        padding: 0 $spacing-3 0 0;

        .zn-button--tertiary {
            padding: $spacing-3 0 0;
        }
    }

    &__content-xs {
        flex-grow: 1;
        padding: $spacing-1 0;

        &--with-image {
            padding: $spacing-1 $spacing-2;
        }

        .zn-button--tertiary {
            padding: $spacing-3 0 0;
        }
    }

    &__content-title {
        @include Display-4-Secondary();
    }

    &__date {
        @include All-Caps-Small();
        color: $darker-grey;
        font-weight: 400;
        padding-top: $spacing-2;

        @media #{$bp-s} {
            padding-top: 0;
        }
    }

    &__content-description {
        color: $darker-grey;
        padding-top: $spacing-3;
    }

    &__content-eyebrow {
        color: $darker-grey;
        font-weight: 400;
    }

    &__text-icon {
        align-items: center;
        color: initial;
        text-decoration: none;
        display: flex;
        margin-bottom: $spacing-3;
        margin-right: $spacing-4;
        vertical-align: middle;

        @media #{$bp-s} {
            display: inline-flex;
        }

        svg {
            color: $torys-blue;
            margin-right: $spacing-2;
        }
    }

    &__content-link {
        color: initial;
        text-decoration: none;
        &:hover {
            color: initial;
            text-decoration: none;
        }
    }

    &__people-heading {
        display: flex;
        margin-bottom: $spacing-3;
    }

    &--hide-desktop {
        @media #{$bp-s} {
            display: none;
        }
    }

    &--hide-mobile {
        display: none;
        @media #{$bp-s} {
            display: flex;
        }
    }
}

.zn-item-with-image__image--xs {
    img {
        max-height: $image-size-xs;
        max-width: $image-size-xs;
    }
}
