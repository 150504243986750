@import '../../styles/main.scss';

.zn-button-simple {
    @include Button-Medium();
    border-radius: 0.125rem;
    border-style: none;
    cursor: pointer;
    display: inline-block;
    line-height: 1.55;
    padding: $spacing-2 $spacing-5;
    text-align: center;
    text-decoration: none;
    width: 100%;
    cursor: pointer;

    @media #{$bp-s} {
        width: initial;
    }

    &:disabled {
        cursor: default;
    }

    &--primary {
        border-color: $torys-blue;
        background-color: $torys-blue;
        color: $white;
        border-style: solid;
    }

    &--primary:hover{
        border-color: $torys-blue;
        background-color: transparent;
        color: $torys-blue;
        text-decoration: none;
    }

    &--secondary {
        border-style: solid;
        border-color: $torys-blue;
        background-color: transparent;
        color: $torys-blue;

        &:hover {
            border-color: $torys-blue;
            background-color: $torys-blue;
            color: $white;
            transition: $transition-ease;
        }
    }

    &--icon {
        background-color: transparent;
        border: none;
        box-shadow: none;
        cursor: pointer;
        display: flex;
        align-items: center;
        padding: 5px;
        width: initial;
    }
}