@import '../../styles/main.scss';

.zn-advanced-filter {
    border-bottom: 1px solid $light-grey;
    margin-bottom: $spacing-5;

    &__title {
        @include Display-4();
        color: $black;
        margin-bottom: $spacing-4;
    }

    &__checkbox-list {
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin-bottom: $spacing-2;
        align-items: center;
    }

    &__low-level-options {
        margin-left: $spacing-3;
    }

    &__view-more-button {
        @include Body-Regular();
        font-size: toRem(18px);
        line-height: 1.5;
        outline: none;
        border: none;
        margin: 0;
        padding: 0;
        color: $torys-blue;
        text-decoration: underline;
        background-color: transparent;
        margin-bottom: $spacing-5;

        &:hover {
            color: $torys-dark-blue;
        }
    }
}