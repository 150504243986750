@import '../../styles/main.scss';
.zn-mega-menu-home {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: start;
    -webkit-animation: fadeIn $transition-ease;
    animation: fadeIn $transition-ease;

    @media #{$bp-m} {
        justify-content: center;
    }

    .zn-mega-menu-home__body {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: 0;

        @media #{$bp-m} {
            flex-direction: row;
            margin-bottom: toRem(215.5px);
        }
    }

    .zn-divider {
        margin: $spacing-3 0 $spacing-5 0;
        background-color: $light-grey;
        color: $light-grey;
        height: 1px;
        border: none;
    }

    .zn-mega-menu-home__footer {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        @media #{$bp-m} {
            flex-direction: row;
            justify-content: flex-start;
        }
    }
}

.zn-mega-menu-home__list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0;
    margin: 0;
}

.zn-mega-menu-home__list__item {
    align-items: center;
    display: flex;
    list-style: none;
}

.zn-mega-menu-home__list--small {
    .zn-mega-menu-home__list__item {
        margin-bottom: $spacing-3;
        @media #{$bp-m} {
            margin-bottom: toRem(20px);
        }
    }
    .zn-link,
    .zn-button {
        padding: 0 5px 0 0;
    }
}

.zn-mega-menu-home__list__item--large {
    margin-bottom: $spacing-3;
    @media #{$bp-m} {
        margin-bottom: toRem(38px);
    }

    .zn-nav-link--mega {
        @media #{$bp-m} {
            line-height: toRem(56px);
        }
    }
}

.zn-mega-menu-home__footer__text {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $black;
    margin-bottom: $spacing-3;
    @media #{$bp-m} {
        margin-bottom: 0;
        margin-right: toRem(44px);
    }
}

.zn-mega-menu-home__footer__button {
    margin-bottom: $spacing-5;
    width: 100%;
    text-align: center;
    @media #{$bp-m} {
        margin-bottom: 0;
        width: auto;
        margin-right: toRem(44px);
    }
}

.zn-mega-menu-home__footer__social {
    @media #{$bp-m} {
        margin-left: auto;
        display: flex;
    }
}

.zn-mega-menu-home__show-mobile {
    @media #{$bp-l} {
        display: none;
    }
}
