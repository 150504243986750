@import '../../styles/main.scss';

.zn-homepage-hero {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;

    @media #{$bp-s} {
        flex-direction: row-reverse;
        min-height: toRem(640px);
    }

    @media #{$bp-l} {
        min-height: 90vh;
    }

    &--dark {
        background-color: $dark-blue;
        color: $white;
    }

    &--blue {
        background-color: $torys-blue;
        color: $white;
    }

    &__image-container {
        width: 100%;
        max-height: toRem(480px);
        overflow: hidden;

        @media #{$bp-s} {
            width: 50%;
            max-height: 100%;
        }
    }

    &__image {
        min-height: toRem(265px);
        height: 100%;
        width: 100%;
        object-fit: cover;

        &--desktop {
            display: none;

            @media #{$bp-s} {
                display: block;
            }
        }

        &--mobile {
            @media #{$bp-s} {
                display: none;
            }
        }

        &--editing {
            height: 16rem;
            width: 16rem;
        }
    }

    &__content {
        padding: $spacing-6 $spacing-3;

        @media #{$bp-s} {
            width: 50%;
            padding: 0 $spacing-3 0 toRem(80px);
            overflow: hidden;
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-top: 0.5rem;
        }

        @media #{$bp-m} {
            padding: 0 $spacing-6 0 toRem(165px);
            margin-top: 3rem;
            margin-bottom: 10rem;
        }
    }

    &__lottie-container {
        min-height: toRem(265px);

        svg {
            height: 100%;
            width: 100%;
            max-height: 100%;
            object-fit: cover;
        }
        &--desktop {
            display: none;

            @media #{$bp-s} {
                display: block;
                height: 100%;
            }
        }

        &--mobile {
            max-height: 100%;
            height: toRem(400px);
            svg {
                display: block;
            }
            @media #{$bp-s} {
                display: none;
            }
        }

        &--editing {
            height: 16rem;
            width: 16rem;
        }
    }

    &__eyebrow {
        @include eyebrow();
        display: flex;
        margin-bottom: $spacing-1;

        @media #{$bp-s} {
            font-size: toRem(14px);
            margin-bottom: $spacing-3;
        }

        &-divider {
            padding: 0 $spacing-2;
        }
    }

    &__title {
        @include Display-1();
        font-size: toRem(36px);
        line-height: toRem(48px);

        @media #{$bp-l} {
            font-size: toRem(72px);
            line-height: toRem(85px);
        }
    }

    &__accent {
        width: toRem(95px);
        height: toRem(5px);
        margin: $spacing-4 0;

        @media #{$bp-s} {
            width: toRem(80px);
            height: toRem(4px);
        }

        &--grey {
            background-color: $dark-grey;
        }
        &--white {
            background-color: $white;
        }
        &--black {
            background-color: $black;
        }
        &--blue {
            background-color: $torys-blue;
        }
        &--teal {
            background-color: $teal;
        }
        &--orange {
            background-color: $orange;
        }
    }

    &__description {
        @include Body-Medium();
    }

    &__CTA {
        margin-top: $spacing-4;

        @media #{$bp-s} {
            margin-top: toRem(52px);
        }
    }

    .zn-button {
        padding: 0;
    }

    &__scroll {
        display: none;

        @media #{$bp-m} {
            display: block;
        }

        position: absolute;
        left: toRem(120px);
        bottom: 0;
        z-index: 2;
        height: toRem(121px);
        border-left: 1px solid $black;
        box-sizing: border-box;

        &--dark {
            border-left: 1px solid $white;
        }

        &-text {
            @include All-Caps();
            font-weight: normal;
            position: absolute;
            top: 0;
            left: toRem(28px);
            transform-origin: 0 0;
            transform: rotateZ(90deg);
            color: $black;
            width: max-content;

            &--dark {
                color: $white;
            }
        }
    }

    &__animate {
        .zn-homepage-hero {
            &__eyebrow,
            &__image {
                @include fadeIn();
            }

            &__description,
            &__CTA {
                @include fadeIn() {
                    &.fadeIn {
                        transition-delay: 1.25s;
                    }
                }
            }

            &__accent {
                @include expandRight();
            }

            &__title {
                @include headingFadeIn('.zn-homepage-hero__title-content') {
                    &.headingFadeIn {
                        transition-delay: 1s;
                    }
                }
            }

            &__scroll {
                @include expandDown() {
                    &.expandDown {
                        transition-delay: 1.7s;
                    }
                }
                &-text {
                    @include fadeInTop() {
                        // Overrides
                        &.fadeIn {
                            transition-delay: 2.1s;
                            transform: translate3d(0, -24px, 0) rotateZ(90deg);
                        }
                        &.fadeInActive {
                            transform: translate3d(0, 0, 0) rotateZ(90deg);
                        }
                    }
                }
            }
        }
    }
}
