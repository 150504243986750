@import '../../styles/main.scss';

$image-size: toRem(256px);
$image-size-xs: toRem(106px);

.zn-student-profile {
    .zn-item-with-image {
        &__image {
            align-items: center;
            padding: 0 $spacing-3 0 0;
            width: $image-size;

            img {
                object-fit: cover;
                height: 100%;
                width: 100%;
                @media #{$bp-s} {
                    max-height: 100%;
                    max-width: 100%;
                }
            }

            &--xs {
                height: $image-size-xs;
                width: $image-size-xs;
                min-height: $image-size-xs;
                min-width: $image-size-xs;
                padding: 0 $spacing-2 0 0;
            }
        }

        &__content {
            padding: 0 $spacing-3;
        }

        &__content-xs {
            padding: $spacing-1;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        &__content,
        &__content-xs {
            & > *:last-child {
                margin-bottom: 0;

                &.zn-item-with-image {
                    &__text-icons-container {
                        margin-bottom: -1rem;
                    }
                }
            }
        }

        &__content-description {
            @media #{$bp-s} {
                margin-bottom: 2rem;
            }
        }

        &__name {
            @include Display-4-Secondary();
        }

        &__text-icon {
            .type-bodyMd {
                @include Body-Regular();
                font-size: toRem(16px);
                line-height: 1.625;
            }
        }
    }
}
