@import '../../styles/main.scss';

a {
    &:hover {
        text-decoration: none !important;
        cursor: pointer;

        .zn-project-map-list-item__title {
            color: $torys-blue;
            text-decoration: none;
        }

        .zn-project-map-list-item__tag {
            text-decoration: none;
        }

        .zn-project-map-list-item__chevron {
            margin-right: $spacing-3;
        }
    }
}
.zn-project-map-list-item {
    display: block;
    position: relative;

    &__chevron {
        display: none;
        color: $dark-blue;
        height: $spacing-3;
        width: toRem(9px);
        position: absolute;
        right: 0;
        top:50%;
        transform: translateY(-50%);
        margin-right: $spacing-5;
        transition: margin-right 0.5s ease;
        
        @media #{$bp-m} {
            display: block;
        }
    }

    &__divider-container {
        width: 100%;
        padding: 0 $spacing-3;

        @media #{$bp-m} {
            padding: 0 $spacing-5;
        }
        
    }

    &__divider {
        height: 1px;
        width: 100%;
        background-color: $light-grey;
    }

    &__content {
        display: flex;
        flex-direction: row;
        padding: $spacing-3;

        @media #{$bp-m} {
            padding: $spacing-3 $spacing-5;
        }
    }

    &__icon-container {
        margin-right: $spacing-3;
    }

    &__icon {
        height: $spacing-5;
        width: $spacing-5;

        &--loading {
            background-color: $light-grey;
        }
    }

    &__text {
        margin-right: $spacing-3;
    }

    &__title-container {
        overflow: hidden;
        max-height: toRem(75px);
        color: $black;

        @media #{$bp-m} {
            max-height: toRem(96px);
        }

        &--not-loading {
            display: -webkit-box;
            max-height: none;
            -webkit-line-clamp: 3;
            line-clamp: 3;
            -webkit-box-orient: vertical;
        }
    }

    &__title {
        @include Body-Medium();
        padding-bottom: $spacing-1;
        color: $black;
        
        &--loading {
            color: $light-grey;
            background-color: $light-grey;
        }
    }

    &__tags {
        list-style-type: none;
        padding: 0;
        margin: 0;
    }

    &__tag {
        @include Body-Regular();
        color: $dark-grey;
        margin-bottom: toRem(2px);

        &--loading {
            color: $light-grey;
            background-color: $light-grey;
        }

    }

    &__tag:last-child {
        margin-bottom: 0;
    }

    .zn-button {
        &--loading {
            background-color: $light-grey;
            color: $light-grey
        }
        @media #{$bp-m} {
            display: none;
        }
    }

    .zn-button__icon {
        &--loading {
            color: $light-grey
        }
    }
}

.zn-project-map-list-item:first-child {

    .zn-project-map-list-item__divider-container {
        width: 100%;
        padding: 0;

        @media #{$bp-m} {
            padding: 0 $spacing-5;
        }
    }
}
