@import '../../styles/main.scss';


.zn-project-map-list {
    max-height: 100%;
    width: 100%;

    &__divider-container {
        width: 100%;
        padding: 0 $spacing-5;
    }

    &__divider {
        width: 100%;
        height: 1px;
        background-color: $light-grey;
    }

    &__list-container {
        margin: 0;
        padding: 0;
        width: 100%;
    }

    &__no-results-container {
        padding: $spacing-3;

        @media #{$bp-s} {
            padding: $spacing-5;
        }
    }

    &__no-results-title {
        @include Body-Large();
        color: $black;
        padding-bottom: $spacing-2;
    }

    &__no-results-description {
        @include Body-Medium();
        color: $dark-grey;
        padding-bottom: $spacing-5;
    }
}
