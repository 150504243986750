@import '../../styles/main.scss';


.zn-side-navigation-item {
    
    @include Body-Medium();
    margin-bottom: toRem(12px);
    color: $darker-grey;
    border-color: transparent;

    @media #{$bp-s} {
        @include Display-6();
        margin-bottom: $spacing-4;
        border-left: toRem(4px) solid;
        border-color: transparent;
        
        &.current {
            color: $torys-blue;
            border-color:  $torys-blue;
        }
    }

    &:last-child {
        margin-bottom: 0;
    }

    &:hover {
        cursor: pointer;
        color: $torys-blue;
        border-color:  $torys-blue;
        text-decoration: none;
    }

    &--current-page {
        @media #{$bp-s} {
            color: $torys-blue;
            border-color:  $torys-blue;
            text-decoration: none;
        }
    }

    &__link {
        color: inherit;
        display: block;
        text-decoration: none;

        @media #{$bp-s} {
            margin-left: $spacing-2;
        }
    }

    &__link:focus {
        font-weight: 500;
    }

    &__link:hover {
        text-decoration: none;
    }
}