@import '../../styles/main.scss';

.zn-tag-list {
    &__title {
        margin-bottom: $spacing-2;
        color: $dark-grey;
        @media #{$bp-s} {
            margin-bottom: toRem(24px);
        }
    }

    @media print {
        display: none;
    }
}

.zn-tag-list__list {
    padding: 0;
    margin: 0;

    .scEmptyPlaceholder {
        display: inline-block;
        height: 3rem;
        margin-bottom: -1rem;
    }

    // Used when rendered in the edit mode, without list-item li
    & > .zn-tag-bubble {
        margin-right: $spacing-2;
    }
}

.zn-tag-list__list-item {
    display: inline-block;
    list-style: none;
    margin-bottom: $spacing-2;
    margin-right: $spacing-2;
}
