@import "../../styles/main.scss";

$link-spacing: $spacing-2;

.zn-social-media-links {
    display: flex;
    flex-direction: row;
    color: $dark-blue;
    margin: -$link-spacing;

    &:hover {
        color: $torys-blue;
    }

    &--vertical {
        flex-direction: column;
    }

    &--dark {
        color: $white;

        &:hover {
            color: $bg-blue;
        }
    }
}

.zn-social-media-link {
    color: $dark-blue;
    text-decoration: none;
    display: flex;
    align-items: center;
    padding: $link-spacing;
    cursor: pointer;

    &:hover {
        color: $torys-blue;
    }

    &--dark {
        color: $white;

        &:hover {
            color: $white;
        }
    }

    &__label {
        font-family: $body-font-family;
        font-weight: 400;
        margin-left: toRem(16px);
        color: $black;
        &--dark {
            color: $white;

            &:hover {
                color: $light-grey;
            }
        }
        &--sm-margin {
            margin-left: toRem(8px);
        }
        &--lg-margin {
            margin-left: toRem(24px);
        }
    }
}
