@import '../../styles/main.scss';

.zn-quarterly-section-header {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
    width: 100%;
    height: 100%;

    &__padding {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        width: 100%;
        padding: 0;
        position: relative;
        left: 0;

        @media #{$bp-s} {
            position: absolute;
            padding-left: toRem(90px);
            padding-bottom: 0;
        }

        @media #{$bp-m} {
            padding-left: toRem(110px);
            padding-bottom: 0;
        }
    }

    &__content {
        width: 100%;
        z-index: 1;
        background-color: $white;
        padding-left: toRem(20px);
        padding-top: toRem(32px);
        padding-right: toRem(16px);
        padding-bottom: toRem(32px);

        @media #{$bp-s} {
            padding-left: toRem(72px);
            padding-top: toRem(72px);
            padding-right: toRem(72px);
            padding-bottom: toRem(32px);
            max-width: toRem(640px);
        }
    }

    &__title {
        @include heading-base;
        font-size: toRem(36px);
        line-height: 1.26;
        font-weight: normal;
        color: $black;
        mix-blend-mode: normal;

        @media #{$bp-s} {
            font-size: toRem(46px);
            line-height: 1.18;
            font-style: normal;
        }
    }

    &__description {
        @include body-base();
        color: $darker-grey;
        margin-top: toRem(16px);
        line-height: toRem(27px);
        font-size: toRem(16px);
        
        @media #{$bp-s} {
            font-size: toRem(18px);
        }
    }

    &__background-image {
        @include desktop-only();
        z-index: 0;
        width: 100%;
        max-width: toRem(2560px);
        object-fit: cover;

        @media #{$bp-s} {
            max-height: toRem(560px);
        }

        @media #{$bp-m} {
            max-height: toRem(640px);
        }
    }

    &__mobile-image {
        @include mobile-only();
        width: 100%;
        height: auto;
    }

    &__accent-strip {
        position: absolute;
        left: 0;
        width: toRem(4px);
        z-index: 2;
        height: 100%;

        &--blue {
            background-color: $torys-dark-blue;
        }
    
        &--teal {
            background-color: $teal;
        }
    
        &--orange {
            background-color: $orange;
        }
    
        &--navy {
            background-color: $dark-blue;
        }

        &--grey {
            background-color: $light-grey;
        }

        @media #{$bp-s} {
            width: toRem(16px);
        }
    }
}
